import { ReactNode } from "react";
import styled from "styled-components";

type StackStyleProps = {
  gap: number;
  [prop: string]: any;
};

const StackStyle = styled.div<StackStyleProps>`
  display: grid;
  gap: ${({ gap }) => gap}px;

  ${({ alignItems }) => alignItems && `align-items:${alignItems}`};
  ${({ alignContent }) => alignContent && `align-content:${alignContent}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content:${justifyContent}`};
  ${({ justifyItems }) => justifyItems && `justify-items:${justifyItems}`};
`;

type Props = {
  children?: ReactNode;
  styles?: any;
  gap?: number;
  [prop: string]: any;
};

export default function Stack({
  children,
  styles = {},
  gap = 4,
  ...props
}: Props) {
  return (
    <StackStyle gap={gap} {...props}>
      {children}
    </StackStyle>
  );
}
