import Input from "../../components/ui/input";
import { useCallback, useState } from "react";
import { addTraineeBulk } from "../../api/trainee";
import { ErrorBoundary } from "react-error-boundary";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import { FormattedMessage, useIntl } from "react-intl";
import { Buttons, Container, Description, Form, LoaderContainer, Message, Warning, SubmitButton } from "./add-trainee.styles";
import Text from "../../components/ui/text";
import Loader from "../../components/ui/loader";

const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ActionMessage = ({ warning, status }) =>
    <Message status={status}>
        <FormattedMessage id={`add-trainee:invitation-with-${status}`} />
        {warning && <Warning>{warning}</Warning>}
    </Message>

const localeFn = (target) => import(`./locale/${target.toLowerCase()}.json`);

const Content = ({ closeButton, onAddNew, ...props }) => {
    const intl = useIntl();
    const [emails, setEmails] = useState("");
    const [valid, setIsValid] = useState(false);
    const [notValidMessage, setNotValidMessage] = useState("");
    const [status, setStatus] = useState('WAITING');
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleOnClick = useCallback(async (event, index) => {
        if (status !== 'WAITING') return;
        setStatus('PENDING');

        try {
            const { notCreatedUsers } = await addTraineeBulk(emails.split(',').map(v => v.trim()));

            if (notCreatedUsers.length > 0) {
                setErrorMessage(intl.formatMessage({
                    id: "add-trainee:trainees-not-added"
                }, {
                    emails: notCreatedUsers
                        .filter(({ errorCode }) => errorCode === 'EmailAlreadyExists')
                        .map(({ emailAddress }) => emailAddress)
                        .join(',').toString()
                }));
            }
        } catch (err) {
            setError(err);
        }

        setStatus('RESOLVED');

        const t = setTimeout(() => {
            setStatus('WAITING');
            setEmails('');
            setError(null);
            setErrorMessage(null);
            setIsValid(false);
            setNotValidMessage('');
            clearTimeout(t);
        }, 6000);

    }, [emails, status]);

    const handleOnChange = useCallback((event) => {
        const value = event.target.value;
        let valid = true;
        let message = '';
        const emails = value.split(',');
        setEmails(value);

        valid = emails.map(v => v.trim()).every((v) => emailRegEx.test(String(v).toLowerCase()));

        if (!valid) {
            message = intl.formatMessage({
                id: "add-trainee:invalid-emails",
                defaultMessage: "Format not valid. Please insert multiple emails separated by commas."
            });
        }

        if (emails.length > 15) {
            valid = false;
            message = intl.formatMessage({
                id: "add-trainee:exceed-max-emails",
                defaultMessage: "You can only invite 15 users."
            });
        }

        setIsValid(valid);
        setNotValidMessage(message);
    }, [emails]);

    const isWaiting = status === 'WAITING';
    const isPending = status === 'PENDING';
    const isResolved = status === 'RESOLVED';

    return <Container>
        <ErrorBoundary fallback={<div className="text-lg">Error. Please try again later...</div>}>
            <Text variant="extraLarge3">
                <FormattedMessage id={"add-trainee:title"} />
            </Text>
            <Description>
                <Text variant="medium">
                    <FormattedMessage id={"add-trainee:description"} />
                </Text>
            </Description>
            <Form>
                <Input
                    className="pr-8"
                    key={`add-new-trainee-email`}
                    disabled={!isWaiting}
                    value={emails}
                    onChange={handleOnChange}
                    placeholder={intl.formatMessage({ id: 'add-trainee:email' })}
                    error={notValidMessage} />
                <Buttons>
                    <SubmitButton
                        variant="yellow"
                        onClick={handleOnClick}
                        disabled={!valid || !isWaiting}
                    >
                        <FormattedMessage id={"add-trainee:send-invite"} />
                        {isPending && <LoaderContainer>
                            <Loader size="small" />
                        </LoaderContainer>}
                    </SubmitButton>
                </Buttons>
                {isResolved && error && <ActionMessage status={'error'} />}
                {isResolved && !error && <ActionMessage status={'success'} warning={errorMessage} />}
            </Form>
        </ErrorBoundary>
    </Container>;
}

export const AddTraineeModal = (props) =>
    <VRIntlProviderComponent localeFn={localeFn} id="add-trainee">
        <Content {...props} />
    </VRIntlProviderComponent>

export default AddTraineeModal;