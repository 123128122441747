import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import useMediaQuery from "../../../utils/hooks/use-media-query";

const VRBarChart = ({ data = [], ticks, domain }) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} layout="vertical" barSize={3}>
        <XAxis
          type="number"
          dataKey="value"
          fontSize="0.8rem"
          range={{}}
          tick={{ fill: "#F2F2F2" }}
          domain={domain}
          ticks={ticks}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          type="category"
          dataKey="name"
          fontSize={isMobile ? "0.8rem" : "1rem"}
          tick={{ fill: "#F2F2F2" }}
          width={200}
          axisLine={false}
          tickLine={false}
          mirror={isMobile}
        />
        <defs>
          <linearGradient id="bar" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#F49E37" stopOpacity={0.1} />
            <stop offset="100%" stopColor="#F49E37" stopOpacity={1} />
          </linearGradient>
        </defs>
        <CartesianGrid color="white" strokeDasharray="5 5" opacity="0.1" />
        <Bar
          dataKey="value"
          fill="url(#bar)"
          background={{ fill: "#333333" }}
        >
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default VRBarChart;
