import { IconProps } from "../../types/icon";

const Eye = ({ width = 16, height = 4, fill = "#FFFFFF" }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9954 0.033111L9.30225 2.51386L7.62018 4.26987L7.50927 5.00924L9.11741 4.57486L8.66454 5.7024L8.66454 7.21811L3.1285 14.3715V13.7708H2.68488L2.68488 16.7098H3.1285V16.2662L8.23016 16.5434C8.15314 16.8053 8.10817 17.3567 8.54439 17.4677L8.59985 18.7893H9.46861C9.58876 18.8324 9.81981 19.1349 9.78284 20H9.9954H10.0047H10.2172C10.1802 19.1349 10.4113 18.8324 10.5314 18.7893L11.4002 18.7893L11.4557 17.4677C11.8919 17.3567 11.8469 16.8053 11.7699 16.5434L16.8716 16.2662V16.7098H17.3152V13.7708H16.8716V14.3715L11.3355 7.21811V5.7024L10.8826 4.57486L12.4908 5.00924L12.3799 4.26987L10.6978 2.51386L10.0047 0.033111V0L10 0.0165555L9.9954 0V0.033111Z"
      fill={fill}
    />
  </svg>
);

export default Eye;
