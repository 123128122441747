import styled from "styled-components";

export const Main = styled.main`
  box-sizing: border-box;
  padding: 1.6rem 1rem 0 calc(16.5rem + 1rem);
  min-height: 100%;
  position: relative;

  @media (max-width: 1024px) {
    padding: calc(9.5rem) 1rem 0 1rem;
  }
`;

export const Header = styled.header``;

export const Back = styled.div`
  color: ${({ theme }) => theme.colors.gray[100]};
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  path {
    fill: ${({ theme }) => theme.colors.gray[100]};
    stroke: ${({ theme }) => theme.colors.gray[100]};
  }
`;

export const Title = styled.div`
  display: block;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftCol = styled.div`
  flex: 0.6;
  margin-bottom: 1rem;

  @media (min-width: 768px) and (max-width: 1279px) {
    flex: 0.4;
  }

  @media (max-width: 768px) {
    flex: 1;
  }
`;

export const RightCol = styled.div`
  flex: 0.4;

  @media (min-width: 768px) and (max-width: 1279px) {
    flex: 0.6;
  }

  @media (max-width: 767px) {
    flex: 1;
  }
`;

export const Content = styled.div`
  padding-top: 3rem;
`;

export const BreadcrumbContainer = styled.p`
  color: ${({ theme }) => theme.colors.gray[100]};
  font-size: 1.125rem;
  font-weight: 500;
`;

type BreadcrumbLinkProps = {
  path?: string;
};
export const BreadcrumbLink = styled.span<BreadcrumbLinkProps>`
  cursor: ${({ path }) => (path ? "pointer" : "default")};
  ${({ theme, path }) => !path && `color: ${theme.colors.gray[50]}`};

  &::after {
    content: " / ";
  }

  &:last-child {
    &::after {
      content: "";
    }
  }
`;

export const PageLoaderContainer = styled.div`
  padding-left: 16.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
