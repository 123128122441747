import styled from "styled-components";

export const List = styled.ul`
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
`;

export const Item = styled.li`  
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.2rem;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Bullet = styled.span<{ color: string }>`
    background-color: ${({ color }) => color};
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin-right: 1rem;
    margin-top: 0.4rem;
`;