import moment from "moment";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useMeasure } from "react-use";
import { useTheme } from "styled-components";
import { ActionsPerformedEntry, ActionsPerformedResponse, getActionsPerformed } from "../../../../api/sessions";
import { ClockIcon, TargetIcon } from "../../../../components/icons";
import Loader from "../../../../components/ui/loader";
import Message from "../../../../components/ui/message";
import Styled from "../../../../components/ui/styled";
import { useFeatureToggles } from "../../../../context/feature-toggles";
import { useUser } from "../../../../context/user";
import { Features } from "../../../../enums/features";
import { Roles } from "../../../../enums/user";
import useQueryParams from "../../../../utils/use-query-params";
import { Section } from "../../session-detail.styles";
import { Container, LoaderContainer } from "./actions-performed.styles";

type SessionDetailChartProps = {
    data: DetailEntry[],
    settings?: SessionDetailSettings,
    width?: number,
    startTime: number,
    endTime: number,
    xLabel?: string
};

export type DetailEntry = {
    category: string,
    events: number[]
}

type SessionDetailSettings = {
    name?: string,
    padding?: number,
    rowGap?: number,
    xLabelGap?: number,
};

const dotColors: string[] = ['#F49E37', '#F59337', '#F58637', '#F57A37', '#F56D37', '#F56037', '#F55337'];
const filterByKeysFirst = ['START_COMMUNICATION', 'END_COMMUNICATION', 'DETONATE'];

const Chart = ({ data, settings, width = 0, startTime, endTime, xLabel }: SessionDetailChartProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const [mouseOver, setMouseOver] = useState<{ x: number, y: number, timestamp: number } | null>(null);

    const { name, padding, rowGap, xLabelGap }: SessionDetailSettings = {
        name: `session-detail-${new Date().getTime()}`,
        padding: 0,
        rowGap: 40,
        xLabelGap: 120,
        ...settings
    };

    const sanitizedData = [
        ...(filterByKeysFirst.reduce((acc: any, key: string, index: number) => {
            const dataEntry = data.find(({ category }: DetailEntry) => category === key);
            if (!dataEntry) return acc;
            acc.push({ category: key, events: dataEntry.events.map((timestamp: number) => timestamp - startTime) });

            return acc;
        }, [])),
        ...(data
            .filter(({ category }: DetailEntry) => !filterByKeysFirst.includes(category))
            .map((entry: DetailEntry) => ({
                ...entry,
                events: entry.events.map((timestamp: number) => timestamp - startTime)
            })))
    ];

    const height = padding * 2 + (sanitizedData.length * rowGap) + 30;
    const gridWidth = width - (padding * 2) - xLabelGap;

    const colors: string[] = Array.from({ length: sanitizedData.length }, (_, i) => dotColors[i % dotColors.length]);

    return <svg
        style={{ overflow: 'visible' }}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg">
        <style>{`
                        .y-label,
                        .x-label {
                            font-size: 0.75rem;
                            font-family: Roboto, monospace;
                            fill: ${theme.colors.gray[50]};
                        }
                        .background {
                            fill: rgba(56, 56, 56, 0.25);
                        }
                    `}
        </style>
        <rect className="background" x={padding + xLabelGap} height={rowGap * sanitizedData.length} width={gridWidth} />
        {sanitizedData
            .map(({ category, events }: DetailEntry, index: number) => <>
                <text textAnchor="end" key={`${name}-label-${index}`} className={`x-label`} x={xLabelGap - 20} y={padding + (index * rowGap) + (rowGap / 2) + 5}>{intl.formatMessage({ id: `session-detail:actions-performed:${category.toLowerCase()}`, defaultMessage: category })}</text>
                <line stroke={theme.colors.gray[200]} strokeDasharray="4" strokeWidth="0.8" key={`${name}-xAxis-${index}`} x1={padding + xLabelGap} y1={padding + (index * rowGap) + (rowGap / 2)} x2={width - padding} y2={padding + (index * rowGap) + (rowGap / 2)}></line>
                {events.map((timestamp: number, i: number) => {
                    const x = padding + xLabelGap + ((gridWidth * timestamp) / (endTime - startTime));
                    const y = padding + (index * rowGap) + (rowGap / 2);
                    return <>
                        <circle
                            key={`${name}-dot-${i}-${index}`}
                            onMouseOver={() => setMouseOver({ x, y, timestamp })}
                            onMouseOut={() => setMouseOver(null)}
                            r={5}
                            fill={colors[index]}
                            cx={x}
                            cy={y}
                        />
                        <circle
                            style={{ cursor: "pointer" }}
                            key={`${name}-dot-${i}-${index}-hover`}
                            onMouseOver={() => setMouseOver({ x, y, timestamp })}
                            onMouseOut={() => setMouseOver(null)}
                            r={20}
                            fill={"transparent"}
                            cx={x}
                            cy={y}
                        />
                    </>
                })}
            </>)}
        {xLabel &&
            <text
                fill={theme.colors.gray[50]}
                x={xLabelGap + padding + ((width - xLabelGap - padding) / 2)}
                y={height + 5}
                textAnchor="middle">
                {xLabel}
            </text>}
        {
            mouseOver &&
            <g
                style={{ pointerEvents: 'none' }}
                transform={`translate(${mouseOver.x + 100 > width ? mouseOver.x - 100 : mouseOver.x
                    }, ${mouseOver.y + 60 > height ? mouseOver.y - 50 : mouseOver.y})`}>
                <rect width="100" height="50" fill="white" rx="5" />
                <g transform={`translate(5,7)`}>
                    <ClockIcon fill="black" />
                </g>

                <text
                    fontSize={"0.8rem"}
                    y="19"
                    x="25"
                    fill={theme.colors.gray[500]}>
                    {moment().startOf('day').add(mouseOver.timestamp / 1000 / 60, 'minutes').format('mm:ss')}
                </text>
            </g>
        }
    </svg >
}

const ActionsPerformed = ({
    offset
}: {
    offset: number;
}) => {
    const intl = useIntl();
    const { state: user } = useUser();
    const { isFeatureActive } = useFeatureToggles();
    const queryParams: any = useQueryParams();

    let from: string = queryParams.get("from");
    let to: string = queryParams.get("to");
    let simulationInstanceId: string = queryParams.get("simulationInstanceId");
    let scenarioInstanceId: string = queryParams.get("scenarioInstanceId");
    const [containerRef, { width }] = useMeasure<HTMLDivElement>()

    const userId = user.role === Roles.Trainee ? user.id : queryParams.get('userId');
    const day = moment(from).format("YYYY-MM-DD");

    const { data, isLoading }: {
        data: ActionsPerformedResponse | undefined,
        isLoading: boolean
    } = useQuery<ActionsPerformedResponse>(
        ["getActionsPerformed", userId, day, simulationInstanceId, scenarioInstanceId],
        () => getActionsPerformed({ day, userId, simulationInstanceId, scenarioInstanceId }),
        {
            suspense: true,
            refetchOnMount: false,
            useErrorBoundary: true,
        }
    );

    if (isLoading) {
        return <LoaderContainer>
            <Loader />
        </LoaderContainer>
    }

    const hasData = data && data.datasets.length > 0;

    if (!hasData) {
        if (!isFeatureActive(Features.HideEmptyCharts)) {
            return null;
        }
        return <Container ref={containerRef}>
            <Message>
                <FormattedMessage id="no-data" />
            </Message>
        </Container>
    }

    const actions = data.datasets[0].actions;

    return <Section.Container>
        <Section.Header>
            <Styled marginRight={"1rem"} display="flex" alignItems="center">
                <TargetIcon width={22} height={22} />
            </Styled>
            <FormattedMessage id="session-detail:actions-performed" defaultMessage={"Actions Performed"} />
        </Section.Header>
        <Section.Body hasBackground={true}>
            <Container ref={containerRef}>
                {hasData &&
                    <Chart
                        xLabel={intl.formatMessage({ id: 'time-in-minutes' })}
                        width={width}
                        settings={{ xLabelGap: offset, name: 'ActionsPerformed' }}
                        startTime={new Date(from).getTime()}
                        endTime={new Date(to).getTime()}
                        data={Object.keys(actions)
                            .reduce((acc: any, key: string) => {
                                acc.push({
                                    category: key,
                                    events: actions[key].reduce((acc: number[], { capturedAt }: ActionsPerformedEntry) => {
                                        acc.push(new Date(capturedAt).getTime())
                                        return acc;
                                    }, [])
                                });
                                return acc;
                            }, [])} />}
            </Container>
        </Section.Body>
    </Section.Container>


}

export default ActionsPerformed;
