import { useCallback, useMemo, useState } from "react";
import Text from "../text";
import { Container, Error, Label, Option, Options, Wrapper } from "./radio.styles";


type Props = {
    label?: string,
    name:string,
    value?: string | number,
    error?: string | null | undefined,
    height?: string | number,
    options: {
        value: string | number,
        label: string
    }[],
    onChange?: (name: string, value: string | number) => void,
    onFocus?: (e: any) => void,
    onBlur?: (e: any) => void,
    [prop:string]: any, 
}

type OptionProps = {
    label: string,
    value: string | number,
}
const Radio = ({ label, name, value, options, onChange, error, ...props }: Props) => {
    return <Container>
        <Wrapper error={error}>
            <Label error={error}>{label}</Label>
            <Options>
                {options.map(({ value:v, label }:OptionProps) => 
                <Option 
                    key={`${name}-${v}`}
                    onClick={() => {
                        onChange && onChange(name, v);
                    }}
                    selected={v === value}>
                        {label}
                </Option>)}
            </Options>
        </Wrapper>
        {error && <Error><Text variant="xsRegular">{error}</Text></Error>}
    </Container>
}

export default Radio;