import { IconProps } from "../../types/icon";

const Settings = ({ width = 20, height = 20, fill = "#F2F2F2" }: IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 20 20`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.787 1.80512C11.3321 -0.0683822 8.66767 -0.0683832 8.21284 1.80512C7.91903 3.01539 6.53244 3.58973 5.4689 2.9417C3.82252 1.93854 1.93845 3.82261 2.94161 5.46899C3.58964 6.53253 3.01529 7.91912 1.80503 8.21293C-0.0684748 8.66776 -0.0684738 11.3322 1.80503 11.7871C3.01529 12.0809 3.58964 13.4675 2.94161 14.531C1.93845 16.1774 3.82252 18.0614 5.4689 17.0583C6.53244 16.4103 7.91903 16.9846 8.21284 18.1949C8.66767 20.0684 11.3321 20.0684 11.787 18.1949C12.0808 16.9846 13.4674 16.4103 14.5309 17.0583C16.1773 18.0614 18.0614 16.1774 17.0582 14.531C16.4102 13.4675 16.9845 12.0809 18.1948 11.7871C20.0683 11.3322 20.0683 8.66776 18.1948 8.21293C16.9845 7.91912 16.4102 6.53253 17.0582 5.46899C18.0614 3.82261 16.1773 1.93854 14.5309 2.9417C13.4674 3.58973 12.0808 3.01539 11.787 1.80512ZM9.9999 13.2C11.7672 13.2 13.1999 11.7673 13.1999 9.99998C13.1999 8.23266 11.7672 6.79998 9.9999 6.79998C8.23259 6.79998 6.7999 8.23266 6.7999 9.99998C6.7999 11.7673 8.23259 13.2 9.9999 13.2Z"
            fill={fill} />
    </svg>

export default Settings;
