import { FormattedMessage, useIntl } from "react-intl";

import Button from "../../../components/ui/button";
import { memo, useCallback, useMemo } from "react";

import { useWelcomeSurveyData } from "../state";
import { Container, Body, Footer, Header, Item, Nav, Title } from "./controller.styles";
import { completeRegistration } from "../../../api/user";
import { useUser } from "../../../context/user";
import { Roles } from "../../../enums/user";
import { SET_NAME } from "../../../context/user/reducer";

const Dots = ({ current, total }) => {
    const dots = Array
        .apply(null, Array(total))
        .map((v, i) => i)
        .map((v, i) => i - 1 < current);

    return <>{dots.map((selected, index) => <Item key={`dot-${index}`} selected={selected}>{index + 1}</Item>)}</>
}

const sendSurvey = async ({ displayName, fields, values }) => {
    return await completeRegistration({
        displayName: displayName,
        surveyAnswers: fields.sections.reduce((acc, { questions, code, title }) => {
            questions.forEach((question) => {
                if (values[question.code]) {
                    acc.push({
                        surveyCode: fields.code,
                        surveyName: fields.name,
                        surveyType: fields.type,
                        sectionCode: code,
                        sectionTitle: title,
                        questionCode: question.code,
                        questionTitle: question.title,
                        answerCode: question.code,
                        answerRawValue: (values[question.code]).toString(),
                    });
                }
            });
            return acc;
        }, [])
    });
}

const Controller = ({ children, title }) => {
    const intl = useIntl();
    const { dispatch: userDispatch } = useUser();
    const {
        onSubmit,
        state: {
            values,
            totalSteps,
            currentStep,
            hasPrevious,
            hasNext,
            fields,
            firstStepInfo,
            error,
            skipSurvey
        },
        dispatch,
        form
    } = useWelcomeSurveyData();
    const { state: user } = useUser();

    const handleOnStepFroward = useCallback(async () => {
        if (!fields) return;

        if (currentStep === 0) {
            if (firstStepInfo.displayName === '' || firstStepInfo.displayName.length < 3) {
                dispatch({
                    type: "SET_FIRST_STEP_ERROR", payload: intl.formatMessage({
                        id: `welcome-screen:step1-error`,
                        defaultMessage: "Field is required and should have at least 3 characters."
                    })
                });
                return;
            }
            if (skipSurvey) {
                dispatch({ type: "PENDING" });
                try {
                    await sendSurvey({
                        displayName: firstStepInfo.displayName,
                        fields,
                        values
                    });
                    userDispatch({ type: SET_NAME, payload: firstStepInfo.displayName });
                } catch (e) {
                    dispatch({ type: "SET_SUBMIT_ERROR", payload: "An error occurred. Try again later" });
                }
                dispatch({ type: "COMPLETED" });
            } else {
                dispatch({ type: "SET_FIRST_STEP_ERROR", payload: null });
                dispatch({ type: "NEXT" });
            }
            return;
        }

        const errors = fields.sections[currentStep - 1].questions.reduce((acc, { code, type, isRequired }) => {
            const value = values[code];
            if (isRequired === true && !value) {
                acc[code] = intl.formatMessage({ id: `welcome-screen:field-required` });
            }
            return acc;
        }, {});

        dispatch({ type: "SET_ERRORS", payload: errors });

        if (Object.keys(errors).length > 0) {
            return false;
        }

        if (currentStep === totalSteps - 1) {
            dispatch({ type: "PENDING" });
            try {
                await sendSurvey({
                    displayName: firstStepInfo.displayName,
                    fields,
                    values
                });
                userDispatch({ type: SET_NAME, payload: firstStepInfo.displayName });
            } catch (e) {
                dispatch({ type: "SET_SUBMIT_ERROR", payload: "An error occurred. Try again later" });
            }
            dispatch({ type: "COMPLETED" });

        } else {
            dispatch({ type: "NEXT" });
        }

    }, [currentStep, firstStepInfo, totalSteps, values, fields, skipSurvey]);

    const isInstructor = user.role === Roles.Instructor;
    const isLastStep = currentStep === totalSteps - 1 || currentStep === 0 && isInstructor;

    if (!fields) {
        return null;
    }

    return <Container>
        <Header>
            {!skipSurvey && <>
                <Nav>
                    <Dots
                        current={currentStep}
                        total={totalSteps} />
                </Nav>
                <Title>{title}</Title>
            </>}
        </Header>
        <Body>
            {children}
        </Body>
        <Footer hasPrevious={hasPrevious}>
            {hasPrevious &&
                <Button type="button" onClick={() => dispatch({ type: "PREVIOUS" })}>
                    <FormattedMessage id="welcome-screen:previous" defaultMessage="previous" />
                </Button>}
            <Button type="submit" variant="yellow" onClick={handleOnStepFroward}>
                <FormattedMessage id={isLastStep ? "welcome-screen:finish" : "welcome-screen:next"} defaultMessage={isLastStep ? "finish" : "next"} />
            </Button>
        </Footer>
    </Container>;
};

export default Controller;