import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`;

export const Body = styled.div`
    width: 80%;
    margin: 0 auto;
    height: 100%;
    flex: 1;
    overflow-y: auto;

    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
        width: 100%;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 0 1rem;
        box-sizing: border-box;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 0 2rem 0;
`;

export const Nav = styled.ul`
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    padding: 0;
`;

export const Title = styled.div`
    margin-top: 1rem;
    font-size: 1.125rem;
    color: ${({ theme }) => theme.colors.gray[100]};
`;

export const Item = styled.li<{ selected: boolean }>`
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ selected, theme }) => selected ? theme.colors.primary.yellow[400] : 'transparent'};
    color: ${({ selected, theme }) => theme.colors.gray[selected ? 500 : 50]};
    border: 1px solid ${({ selected, theme }) => selected ? theme.colors.primary.yellow[400] : theme.colors.gray[50]};
    font-size: 0.875rem;
    margin: 0 1rem;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        background-color: ${({ selected, theme }) => selected ? theme.colors.primary.yellow[400] : theme.colors.gray[50]};
        right: 100%;
        width: calc(2rem + 1px);
        height: 1px;   
    }

    &:first-of-type:before {
        display: none;
    }
`;

export const Footer = styled.div<{ hasPrevious: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${({ hasPrevious }) => hasPrevious ? `space-between` : `flex-end`};
    padding: 2rem 6rem;
    

    @media only screen and (max-width: 767px) {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 0 0.5rem;
        box-sizing: border-box;
        justify-content: center;
        height: 7rem;
        border-top: 1px solid ${({ theme }) => theme.colors.gray[100]};

        & > button {
            width: 50%;
            margin: 0 0.5rem;
        }
    }
`;