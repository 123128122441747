import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  user-select: none;
  ::-webkit-scrollbar {
    height: 0.7rem;
  }
`;

export const ChartTooltip = {
  Container: styled.div`
    padding: 1rem;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.gray[400]};
    border-radius: 0.6rem;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    z-index: 1000;
    position: relative;
  `,
  Title: styled.span`
    width: 100%;
    text-overflow: ellipsis;
    display: block;
  `,
};
