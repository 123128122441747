import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const Header = styled.header`
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.span`
  flex: 1;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

export const IconWrapper = styled.span`
  margin-right: 0.8rem;
  margin-top: 0.2rem;
`;

type ContentProps = {
  transparent: boolean;
  radius: number | string;
};
export const Content = styled.div<ContentProps>`
  background-color: ${({ theme, transparent }) =>
    (transparent && "transparent") || theme.colors.gray[300]};
  padding: 0;
  box-sizing: border-box;
  margin-top: 0.5rem;
  border-radius: ${({ radius }) => radius};
`;
