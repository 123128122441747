export const SET_FILTER = 'SET_FILTER';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_DATE_PRESET = 'SET_DATE_PRESET';

type Payload = {
    type: string,
    payload: any,
}

export const reducer = (state: any, { type, payload }: Payload) => {
    switch (type) {
        case SET_FILTER:
            return {
                ...state,
                [payload.name]: payload.value,
            }
        case SET_FILTERS:
            return {
                ...state,
                ...payload
            }
        case SET_DATE_PRESET:
            return {
                ...state,
                datePreset: payload,
            }
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }
    }
}