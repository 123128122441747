import { IconProps } from "../../types/icon";

const ArrowRight = ({ width = 18, height = 14, fill = "#F2F2F2" }: IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 18 14`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.6672 1.16669L16.5005 7.00002M16.5005 7.00002L10.6672 12.8334M16.5005 7.00002L1.50049 7.00002"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>

export default ArrowRight;