import styled from "styled-components";

export const Container = styled.div`
    text-align: left;
    width: 100%;
    display: inline-block;
`;

type WrapperProps = {
    height: string | number,
    type: string,
    focus: boolean,
    error: string | null | undefined,
}

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: column;
    justify-content: ${({ type }) => type === "textarea" ? "flex-start" : "center"};
    height: ${({ type, height }) => type === 'textarea' ? height : 'auto'};
    width: 100%;
    background-color: ${({ theme }) => theme.colors.gray[200]};
    border-radius: 0.3rem;
    padding: 0.5rem 0.7rem 0 0.5rem;
    box-sizing: border-box;

    ${({ focus, error, theme }) => focus && !error && `box-shadow: 0 0 0px 2px ${theme.colors.gray[400]}, 0 0 0px 3px ${theme.colors.primary.yellow[400]}`}
    ${({ error, theme }) => error && `box-shadow: 0 0 0px 2px ${theme.colors.gray[400]}, 0 0 0px 3px ${theme.colors.primary.pink[400]}`}
`;

export const Label = styled.span<{
    focus: boolean,
    error: string | null | undefined,
}>`
    font-size: 0.75rem;
    ${({ focus, error, theme }) => focus && !error && `color:${theme.colors.primary.yellow[400]}`}
`;

export const Field = styled.input`
    font-family: ${({ theme }) => theme.fonts.main};
    font-weight: ${({ theme }) => theme.typography.smallMedium.fontWeight};
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: transparent;
    border: none;
    outline: none;
    box-sizing: border-box;
    line-height: 2rem;
    width: 100%;
    resize: none;
    margin-top: -0.4rem;

    ::placeholder {
        color: ${({ theme }) => theme.colors.gray[100]};
    }
`;

export const Area = styled.textarea`
    font-family: ${({ theme }) => theme.fonts.main};
    font-weight: ${({ theme }) => theme.typography.smallMedium.fontWeight};
    font-size: ${({ theme }) => theme.typography.smallMedium.fontSize};
    color: ${({ theme }) => theme.colors.white};
    background-color: transparent;
    border: none;
    outline: none;
    box-sizing: border-box;
    margin-top: 0.1rem;
    height: 100%;
    width: 100%;
    resize: none;

    ::placeholder {
        color: ${({ theme }) => theme.colors.gray[100]};
    }
`;


export const Error = styled.span`
    display: inline-block;
    color: ${({ theme }) => theme.colors.primary.pink[400]};
    margin-top: 0.3rem;
`;