import { FeatureTogglesProps } from "./index";

export const SET_FEATURES = 'SET_FEATURES';
export const SET_FEATURE = 'SET_FEATURE';
export const UNSET_FEATURE = 'UNSET_FEATURE';

type Payload = {
    type: string,
    payload: any,
}

export const reducer = (state: any, { type, payload }: Payload): FeatureTogglesProps => {
    switch (type) {
        case SET_FEATURES:
            return {
                ...state,
                ...payload,
            }
        case SET_FEATURE:
            return {
                ...state,
                [payload.name]: true
            }
        case UNSET_FEATURE:
            return {
                ...state,
                [payload.name]: false
            }
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }
    }
}