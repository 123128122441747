import { ReactNode } from "react";
import Styled from "../styled";

type Props = {
  children: ReactNode;
  color?: "white" | "success" | "error" | "danger" | "warning" | string,
  [key: string]: any,
}

const Message = ({ children, color = "gray[100]", ...others }: Props) => <Styled color={color} {...others}>{children}</Styled>

export default Message;
