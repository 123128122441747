import { IconProps } from "../../types/icon";

const Logout = ({ width = 20, height = 20, fill = "#F2F2F2" }: IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 20 20`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 2.39999C11.3374 2.39999 10.8001 2.93725 10.8001 3.59999V10.8C10.8001 11.4627 11.3374 12 12.0001 12C12.6628 12 13.2001 11.4627 13.2001 10.8V3.59999C13.2001 2.93725 12.6628 2.39999 12.0001 2.39999ZM6.79148 5.40942C7.48272 4.86238 8.4001 5.42543 8.4001 6.30695V6.60012C8.4001 6.97752 8.21722 7.32734 7.93945 7.58283C6.74721 8.67941 6.0001 10.2525 6.0001 12C6.0001 15.3137 8.68639 18 12.0001 18C15.3138 18 18.0001 15.3137 18.0001 12C18.0001 10.2525 17.253 8.67941 16.0607 7.58283C15.783 7.32734 15.6001 6.97752 15.6001 6.60012V6.30695C15.6001 5.42543 16.5175 4.86238 17.2087 5.40942C19.1528 6.94792 20.4001 9.32831 20.4001 12C20.4001 16.6392 16.6393 20.4 12.0001 20.4C7.36091 20.4 3.6001 16.6392 3.6001 12C3.6001 9.32831 4.84741 6.94792 6.79148 5.40942Z"
            fill={fill} />
    </svg>

export default Logout;
