import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import ArrowLeft from "../../icons/arrow-left";
import Loader from "../loader";
import Styled from "../styled";
import Text from "../text";
import { Back, BreadcrumbContainer, BreadcrumbLink, Content, Description, Header, LeftCol, Main, PageLoaderContainer, RightCol, Title } from "./index.styles";

type BreadcrumbType = {
  label: string;
  path?: string;
}

type Props = {
  title: string,
  description?: string,
  rightContent?: ReactNode,
  children: ReactNode,
  footer?: ReactNode,
  hasBackLink?: boolean,
  backLabel?: string,
  onBackClick?: () => void | undefined,
  breadcrumbs?: BreadcrumbType[]
}

const Breadcrumbs = ({ breadcrumb }: { breadcrumb: BreadcrumbType }) => {
  const history = useHistory();
  return <BreadcrumbLink
    path={breadcrumb.path}
    onClick={() => { breadcrumb.path && history.push(breadcrumb.path) }}>
    {breadcrumb.label}
  </BreadcrumbLink>
}

const Page = ({
  title,
  description,
  rightContent = null,
  footer,
  children,
  hasBackLink = false,
  backLabel,
  onBackClick,
  breadcrumbs
}: Props) =>
  <Main>
    <Header>
      {hasBackLink && <Back onClick={onBackClick}>
        <Styled marginRight="1rem">
          <ArrowLeft />
        </Styled>
        {backLabel}
      </Back>}

      <BreadcrumbContainer>
        {breadcrumbs?.map((breadcrumb: BreadcrumbType, index: number) =>
          <Breadcrumbs
            key={`breadcrumbs-item-${index}`}
            breadcrumb={breadcrumb} />)}
      </BreadcrumbContainer>

      <Title><Text variant="extraLarge3">{title}</Text></Title>
      {
        description && <Description>
          <LeftCol>
            <Text variant="medium">{description}</Text>
          </LeftCol>
          {rightContent && <RightCol>{rightContent}</RightCol>}
        </Description>
      }
    </Header >
    <Content>{children}</Content>
    {footer}
  </Main >

export const PageLoader = () =>
  <PageLoaderContainer>
    <Loader />
  </PageLoaderContainer>


export default Page;
