import styled from "styled-components";

export const CustomLegend = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  `,
  List: styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  `,
  Item: styled.li`
    display: inline-block;
    margin: 0 0.4rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
  `,
  Color: styled.span<{ color: string }>`
    background-color: ${({ color }) => color};
    width: 1rem;
    height: 1rem;
    border-radius: 0.1rem;
    display: flex;
    margin-right: 0.2rem;
  `,
};
export const CustomTooltip = {
  Container: styled.div`
    background-color: ${({ theme }) => theme.colors.gray[400]};
    padding: 0.6rem;
  `,
};
