import { IconProps } from "../../types/icon";

const Flag = ({ width = 14, height = 15, fill = "#F2F2F2" }:IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 14 15`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H13C13.3788 0 13.725 0.214002 13.8944 0.552786C14.0638 0.89157 14.0273 1.29698 13.8 1.6L11.25 5L13.8 8.4C14.0273 8.70302 14.0638 9.10843 13.8944 9.44721C13.725 9.786 13.3788 10 13 10H3C2.44772 10 2 10.4477 2 11V14C2 14.5523 1.55228 15 1 15C0.447715 15 0 14.5523 0 14V3Z"
            fill={fill} />

    </svg>

export default Flag;
