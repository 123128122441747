
import { IconProps } from "../../types/icon";

const Sort = ({ width = 10, height = 14, fill = "#F2F2F2" }:IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 10 14`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.00008 0.166992C5.2653 0.166992 5.51965 0.272349 5.70719 0.459886L9.04052 3.79322C9.43105 4.18374 9.43105 4.81691 9.04052 5.20743C8.65 5.59796 8.01683 5.59796 7.62631 5.20743L5.00008 2.58121L2.37385 5.20743C1.98333 5.59796 1.35017 5.59796 0.959641 5.20743C0.569117 4.81691 0.569117 4.18374 0.959641 3.79322L4.29298 0.459885C4.48051 0.272349 4.73487 0.166992 5.00008 0.166992ZM0.959641 8.79322C1.35017 8.4027 1.98333 8.4027 2.37385 8.79322L5.00008 11.4194L7.62631 8.79322C8.01683 8.4027 8.65 8.4027 9.04052 8.79322C9.43105 9.18374 9.43105 9.81691 9.04052 10.2074L5.70719 13.5408C5.51965 13.7283 5.2653 13.8337 5.00008 13.8337C4.73487 13.8337 4.48051 13.7283 4.29297 13.5408L0.959641 10.2074C0.569117 9.81691 0.569117 9.18374 0.959641 8.79322Z"
            fill={fill} />
    </svg>

export default Sort;