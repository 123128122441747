export enum DashboardFilter {
  UserId = "userId",
  From = "from",
  To = "to",
}

export enum DateFilterPresets {
  Today = "today",
  Yesterday = "yesterday",
  Last7Days = "last7days",
  Last14Days = "last14days",
  Last30Days = "last30days",
}

export const DateFilterPresetValues: { [key: string]: number } = {
  [DateFilterPresets.Today]: 0,
  [DateFilterPresets.Yesterday]: -1,
  [DateFilterPresets.Last7Days]: -6,
  [DateFilterPresets.Last14Days]: -13,
  [DateFilterPresets.Last30Days]: -30,
};

export enum FiltersList {
  TraineesList = "TRAINEES_LIST",
  DatePicker = "DATE_PICKER",
}
