const defaultLocale = "en-GB";
const supportedLocales = new Set(defaultLocale);

const getSupportedLocale = () => {
  const target =
    window.localStorage.getItem("locale") || navigator.language || "en-GB";
  return supportedLocales.has(supportedLocales) ? target : defaultLocale;
};

export default getSupportedLocale;
