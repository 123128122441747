import { User } from "../../types/user";
export const SET_NAME = 'SET_NAME';
export const SET_ROLE = 'SET_ROLE';
export const SET_ID = 'SET_ID';
export const SET_PROP = 'SET_PROP';
export const SET_PROPS = 'SET_PROPS';
export const SET_STATUS = 'SET_STATUS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_PHOTO = 'SET_PHOTO';


type Payload = {
    type: string,
    payload: any,
}

export const reducer = (state: User, { type, payload }: Payload) => {
    switch (type) {
        case SET_NAME:
            return {
                ...state,
                name: payload,
            }
        case SET_ROLE:
            return {
                ...state,
                role: payload,
            }
        case SET_ID:
            return {
                ...state,
                id: payload,
            }
        case SET_PROP:
            return {
                ...state,
                [payload.property]: payload.value,
            }
        case SET_PROPS:
            return {
                ...state,
                ...payload
            }
        case SET_STATUS:
            return {
                ...state,
                status: payload,
            }
        case SET_TOKEN:
            return {
                ...state,
                token: payload,
            }
        case SET_PHOTO:
            return {
                ...state,
                photo: payload,
            }
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }
    }
}