import { useUser } from "../../context/user";

import { Roles } from "../../enums/user";
import { Pages } from "../../enums/pages";
import { Wrapper, Container, Nav, Menu, MenuItem, MenuLink, IconWrapper, Logo, MenuContainer } from "./navigation.styles";
import Text from "../../components/ui/text";
import UserBadge from "../user-badge";
import { DashboardIcon, SupportIcon, UsersIcon } from "../../components/icons";
import { useFeatureToggles } from "../../context/feature-toggles";

const getMenuItems = ({ pendingInvitesEnabled }: { pendingInvitesEnabled: boolean}) => ([
  {
    icon: DashboardIcon,
    label: "Dashboard",
    path: Pages.Dashboard,
    notAllowedTo: [Roles.Instructor],
  },
  {
    icon: DashboardIcon,
    label: "Dashboard",
    path: Pages.DashboardAllTrainees,
    notAllowedTo: [Roles.Trainee],
  },
  {
    icon: UsersIcon,
    label: "Trainees",
    path: pendingInvitesEnabled ? Pages.TraineesActive : Pages.Trainees,
    notAllowedTo: [Roles.Trainee],
  },
  {
    icon: SupportIcon,
    label: "Support",
    path: Pages.Support,
    notAllowedTo: [],
  },
]);

const Navigation = () => {
  const { state: user } = useUser();
  const { isFeatureActive } = useFeatureToggles();

  const pendingInvitesEnabled = isFeatureActive('PENDING_INVITES');
  const items = getMenuItems({ pendingInvitesEnabled });

  return (
    <Wrapper>
      <Container>
        <Nav>
          <Logo />
          <MenuContainer>
            <Menu>
              {items
                .filter(({ notAllowedTo }) => !notAllowedTo.includes(user.role))
                .map(({ path, icon: Icon, label }) => {
                  return <MenuItem key={path}>
                    <MenuLink to={path} isActive={(match, location) => {
                      if (path.split('/').filter((v) => v)?.[0] === location.pathname.split('/').filter((v) => v)?.[0]) {
                        return true;
                      }
                      return !!match;
                    }}>
                      <IconWrapper><Icon /></IconWrapper>
                      <Text>{label}</Text>
                    </MenuLink>
                  </MenuItem>
                })}
            </Menu>
          </MenuContainer>
        </Nav>
        <UserBadge />
      </Container>
    </Wrapper>
  );
};

export default Navigation;
