import { IconProps } from "../../types/icon";

const Support = ({ width = 20, height = 20, fill = "#F2F2F2" }:IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 20 20`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2 5C2 3.89543 2.89543 3 4 3H11C12.1046 3 13 3.89543 13 5V9C13 10.1046 12.1046 11 11 11H9L6.85355 13.1464C6.53857 13.4614 6 13.2383 6 12.7929V11H4C2.89543 11 2 10.1046 2 9V5Z"
            fill={fill} />
        <path
            d="M15 7V9C15 11.2091 13.2091 13 11 13H9.82843L8.06173 14.7667C8.34154 14.9156 8.66091 15 9 15H11L13.1464 17.1464C13.4614 17.4614 14 17.2383 14 16.7929V15H16C17.1046 15 18 14.1046 18 13V9C18 7.89543 17.1046 7 16 7H15Z"
            fill={fill} />
    </svg>

export default Support;