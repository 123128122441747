import { useCallback } from "react";
import { Wrapper, Label, Container, Value, Percentage, LabelBefore, LabelAfter, Slider, Divisions, Division } from "./percentage-slider.styles";

type Props = {
    key: string,
    required: boolean,
    name: string,
    divisions: number,
    before: string,
    after: string,
    label: string,
    step?: number,
    value: number,
    onChange: (name: string, value: number) => void,
    [prop: string]: any,
};

const PercentageSlider = ({ key, required, name, divisions, before, after, label, step, value, onChange }: Props) => {

    const handleOnChange = useCallback((event: any) => {
        onChange(name, event.target.value);
    }, []);

    return <Wrapper>
        <Label>{label}</Label>
        <Container>
            <Divisions>
                {Array.from(
                    { length: divisions + 1 },
                    (value, index) => index
                ).map((value, i) => (
                    <Division key={`${name}-slider-div-${i}`}>
                        {Math.round((value / divisions) * 100)}
                    </Division>
                ))}
            </Divisions>
            <Slider step={step || 100 / divisions} value={value || 0} onChange={handleOnChange} />
            {before && <LabelBefore>{before}</LabelBefore>}
            {after && <LabelAfter>{after}</LabelAfter>}
        </Container>
        <Value>
            <Percentage>{value || 0}</Percentage>
            %
        </Value>
    </Wrapper>
};

export default PercentageSlider;