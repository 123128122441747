import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMeasure } from 'react-use';
import { getLatestActivity } from "../../../../api/sessions";
import { VRIntlProviderComponent } from "../../../../components/providers/intl-provider";
import { MsToHours } from "../../../../utils/time";
import { Container } from "./lastest-activity.styles";

type Props = {
    data?: Item[] | null,
    settings?: { [prop: string]: any },
    width?: number
}

type Item = {
    date: string,
    duration: number,
}

const defaultSettings: {
    [prop: string]: any,
    onClick: null | ((date: Date) => void)
} = {
    margins: {
        top: 40,
        right: 20,
        bottom: 50,
        left: 120
    },
    wide: true,
    hourHeight: 10,
    hourInterval: 4,
    dayInterval: 7,
    showYLines: false,
    showXLines: true,
    maxBarsPerDay: 3,
    barWidth: 4,
    barGap: 3,
    barColor: '#F49E37',
    textColor: '#F2F2F2',
    lineColor: '#383838',
    onClick: null,
};
const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const Chart = ({ data, settings = {}, width: parentWidth = 0 }: Props) => {
    const [dayOver, setDayOver] = useState<number | null>(null);
    const svg = useRef<any>(null);

    const {
        margins,
        wide,
        hourHeight,
        hourInterval,
        dayInterval,
        showXLines,
        showYLines,
        maxBarsPerDay,
        barWidth,
        barGap,
        barColor,
        textColor,
        lineColor,
        onClick
    }: any = {
        ...defaultSettings,
        ...settings
    }

    if (!data) return null;

    const allDates: number[] = data.reduce((acc: number[], { date }: Item) => { acc.push(new Date(date).getTime()); return acc; }, []).sort((a: number, b: number) => a > b ? 1 : -1);
    const allDurations = data.reduce((acc: number[], { duration }: any) => { acc.push(MsToHours(duration)); return acc; }, []).sort((a: number, b: number) => a > b ? -1 : 1);
    const totalDays = Math.ceil(Math.abs(allDates[allDates.length - 1] - allDates[0]) / (1000 * 60 * 60 * 24));
    const maxHours = hourInterval * Math.ceil((allDurations[0] / hourInterval));
    const columnWidth: number = wide ? (parentWidth - margins.left - margins.right) / (totalDays + 1) : 120;
    const width = wide ? parentWidth : (columnWidth * (totalDays + 1)) + margins.right + margins.left;
    const height = (maxHours * hourHeight) + margins.top + margins.bottom;

    const activitiesByDay = data.reduce((acc: any, item: Item) => {
        const diffTime = Math.abs(new Date(item.date).getTime() - allDates[0]);
        const diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        if (!acc.hasOwnProperty(diff)) {
            acc[diff] = [];
        }
        acc[diff].push(item)
        return acc;
    }, {});

    if (!data) return null;

    return <svg
        width={width}
        height={height}
        ref={svg}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg">
        <style>{`
                text { user-select: none; }
                .label-x,
                .label-y,
                .hover-date,
                .hover-repetitions {
                    font-size: 12px;
                }
                .label-x,
                .label-y,
                .remaining-counter {
                    fill: ${textColor};
                }
                .hover-repetitions { fill: #999999; }
                .hover-date { fill: #f2f2f2; }
                .remaining-counter {
                    font-size: 10px;
                }
                ${onClick && `
                    .day-block {
                        cursor: pointer;
                    }
                `}
            `}</style>
        {Array.from({ length: totalDays + 1 }, (v, i: number) => i).map((day: number) => {
            return <>
                {day === 0 && showYLines && <line x1={margins.left + (day * columnWidth)} y1={margins.top} x2={margins.left + (day * columnWidth)} y2={height - margins.bottom} stroke={lineColor} />}
                {showYLines && <line x1={margins.left + ((day + 1) * columnWidth)} y1={margins.top} x2={margins.left + ((day + 1) * columnWidth)} y2={height - margins.bottom} stroke={lineColor} strokeDasharray="3" />}
                {day % dayInterval === 0 && <text className="label-x" x={margins.left + (day * columnWidth + (columnWidth / 2)) - 15} y={height - (margins.bottom / 2) + 8}>{moment(allDates[0]).add(day, 'd').format('D/MM')}</text>}
            </>
        })}
        {Array
            .from({ length: Math.ceil(maxHours / hourInterval) + 1 }, (v, i) => i * hourInterval)
            .map((hour: number) => {
                const y = height - (hour * hourHeight) - margins.bottom;
                return <>
                    {showXLines && <line x1={margins.left} y1={y} x2={width - margins.right} y2={y} stroke={lineColor} strokeDasharray="3" strokeWidth="0.8" />}
                    <text className="label-y" x={26} y={y + 5}>{`${hour}:00`}</text>
                </>
            }
            )}

        {Object.keys(activitiesByDay).sort((a: string, b: string) => parseInt(b) - parseInt(a)).map((key: string) => {
            var dayOffset = parseInt(key) - 1;
            const date = new Date(allDates[0]);
            date.setDate(date.getDate() + dayOffset);

            const activities: Item[] = activitiesByDay[key];
            const totalBars = activities.length;
            const isHover = dayOver && dayOver === parseInt(key);
            const shownBars = isHover ? totalBars : (totalBars < maxBarsPerDay ? totalBars : maxBarsPerDay);
            const highestBar = activities.reduce((acc: number, { duration }: any) => { const v = MsToHours(duration) * hourHeight; if (acc < v) acc = v; return acc; }, 0);

            const events = {
                onMouseOver: () => setDayOver(parseInt(key)),
                onMouseOut: () => setDayOver(null),
                ...(onClick ? {
                    onClick: () => {
                        var now = new Date(allDates[0]);
                        now.setDate(now.getDate() + dayOffset);
                        onClick(now);
                    }
                } : {})
            };

            return <g className="day-block" transform={`translate(${(dayOffset * columnWidth) + margins.left},${margins.top})`}>
                {isHover && <>
                    <rect x={0} y={(maxHours * hourHeight) - (highestBar) - 17} width={(totalBars * barWidth) + (totalBars * barGap) + 20 - barGap + 80} height={highestBar + 30} fill={"#111111"} stroke={lineColor} rx="4" />
                    <text className="hover-date" x={10 + (totalBars * barWidth) + (totalBars * barGap) + 5} y={(maxHours * hourHeight) - (highestBar) + ((highestBar + 30) / 2) - 16}>{moment(date).format('DD/MM')}</text>
                    <text className="hover-repetitions" x={10 + (totalBars * barWidth) + (totalBars * barGap) + 5} y={(maxHours * hourHeight) - (highestBar) + ((highestBar + 30) / 2)}>
                        {totalBars} <FormattedMessage id="latest-activity:repetitions" />
                    </text>
                </>}
                {totalBars > maxBarsPerDay && <><rect x={columnWidth - 26 - 5} y={-9} fill="#383838" width="26" height="18" rx="9"></rect><text className="remaining-counter" x={columnWidth - 24} y={3} fontSize={12}>+{totalBars - maxBarsPerDay}</text></>}
                <g>
                    {activities
                        .filter(({ }, index) => index + 1 <= shownBars)
                        .map(({ duration }: any, index: number) => {
                            const fullHeight = (maxHours * hourHeight);
                            const height = MsToHours(duration) * hourHeight;
                            return <rect
                                key={`bar-${dayOffset}-${index + 1}`}
                                x={10 + (index * barWidth) + (index * barGap)}
                                y={fullHeight - height - 7}
                                width={barWidth}
                                height={height + 12}
                                fill={barColor}
                                rx={barWidth / 2} />
                        }
                        )}
                </g>
                <rect className="hover-area" {...events} x={0} y={-10} width={columnWidth} height={(maxHours * hourHeight) + 20} fill={`transparent`} />
            </g>
        })}
    </svg>;
}

const LatestActivity = ({
    settings
}: {
    settings?: { [prop: string]: any }
}) => {
    const [data, setData] = useState<Item[] | null>(null);
    const [containerRef, { width }] = useMeasure<HTMLDivElement>();

    useEffect(() => {
        setData(getLatestActivity());
    }, []);

    return <VRIntlProviderComponent
        localeFn={localeFn}
        id="latest-activity"
        fallback={null}
    >
        <Container ref={containerRef}>
            <Chart
                data={data}
                width={width}
                settings={settings} />
        </Container>
    </VRIntlProviderComponent>
}

export default LatestActivity;