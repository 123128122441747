export const data = [
  {
    title: "What is VR Training?",
    content: [
      "Virtual reality (VR) allows you to recreate real world training scenarios that can be accessed by putting on a VR headset. VR training allows you to recreate training scenarios but without the inherent risk associated with real world training, without the need to travel to remote training environments and without the need for rare real world instructors or equipment.",
      "These virtual training scenarios can provide authentic simulations of real world scenarios using 3D models. Trainees can interact with these 3D models like they would with real world objects, they can move around the world with 6 degrees of freedom (6DoF) and receive instruction from virtual training assistants or onscreen instruction.",
      "VR also allows you to record training performance and use the data generated to iteratively help you improve your training outcomes. These data driven insights can help improve safety and performance in the workplace.",
    ],
  },
  {
    title: "What are the benefits of VR Training?",
    content: [
      `Check out our IAG case study that shows how VR training can improve learning, improve knowledge retention and reduce training times: https://www.heatvr.io/iagcargo/`,
      `Pilots, astronauts and military personnel have been using simulation to improve their training capacity, reduce costs and improve outcomes for decades. These simulators were often as expensive as the real world equipment and were housed in "simulation centres". Virtual reality (VR) can now bring this simulation capability to a much wider set of people providing faster, more effective and more efficient training: https://www.pwc.co.uk/issues/intelligent-digital/virtual-reality-vr-augmented-reality-ar/study-into-vr-training-effectiveness.html#results`,
      `There are many benefits of virtual reality (VR) training, it allows you to provide effective training, remotely and at scale. Elearning might be OK to replace a lecture or classroom based training, but it won’t prepare your staff to face dangerous real-world scenarios such as those in aviation, defence, manufacturing, energy and utilities. VR training can also be standardised & scalable and integrated with your company's learning management system (LMS)`,
      `VR training is also a far more engaging way to train your employees with a recent PwC report showing that people learning in VR completed training 4 times faster than classroom training as well as a 40% improvement in confidence compared to classroom based learners: https://www.pwc.co.uk/issues/intelligent-digital/virtual-reality-vr-augmented-reality-ar/study-into-vr-training-effectiveness.html#results`,
      `VR allows your employees to train remotely without the need to travel to training centres. Using VR training in large organisations can reduce the number of flight travels by 25 million globally and over carbon footprint by 2.5 Mega tonnes(Mt) per year.`,
    ],
  },
  {
    title: "How is Virtual Reality used in training and development?",
    content: [
      `Virtual training (VR) can provide part of a blended learning solution, enhancing existing training methods as well as supporting better real world training. Learning by doing is significantly more effective than classroom-based learning alone, however, there is a risk and travel cost associated with learning by doing. VR training allows you to reduce the risk and the travel costs associated with learning by doing by providing an authentic, virtual training environment. That can be accessed through a VR headset from anywhere in the world.`,
      `The data generated in VR training can also help to iteratively improve training and development outcomes. Our HEAT VR training systems can capture approximately 100,000 data points per minute of VR training. This data can then be brought to a server or to the cloud where it can be analysed to provide insights on performance. Overtime, when you have generated enough specific data, artificial intelligence (AI) can be applied to the datasets to help predict and iteratively improve the training outcomes.`,
      `VR training can also facilitate adaptive learning, where the AI adapts the training experience to the specific users needs, based on their performance and activities. This means that each trainee can receive a bespoke training experience tailored to their levels of experience, rather than through generic training.`,
    ],
  },

  {
    title: "How is VR used in training in the UK and Ireland?",
    content: [
      `As PwC assessed in their Seeing is Believing report on the economic impact of VR/AR, training, learning and development - they estimate VR training will contribute $294 billion to the global economy by 2030.`,
      `In the UK & Ireland, virtual reality (VR) training is used across many industries. For example we have worked with utilities, aviation, transport, pharmaceutical companies, construction and defence.`,
      `For people who work in risky or remote operational environments, such as front line military personnel, offshore wind technicians, or even workers in manufacturing settings, there is a need to train, prepare for and mitigate risk.`,
      `Traditional elearning tools can not prepare someone to climb a high voltage electricity pylon in a storm, or fix a blade on a 100m high wind turbine 100km offshore in the North Sea.`,
    ],
  },
  {
    title: "What does a VR training course entail?",
    content: [
      `VR training is a form of "practice by doing" to allow trainees to get hands on experience on a task but without the associated risk, it can be done remotely and without the need for rare equipment or instructor resources.`,
      `Some VR training courses will be made bespoke to your company’s learning requirements and will often be part of a blended learning solution i.e. to augment traditional training on a specific piece of equipment, machine or scenario.`,
      `Some VR training can be generic if there are not specific requirements i.e. working at heights, lock out tag out procedures etc.`,
      `We find that VR training has the most impact when the activity is something that is inherently risky, is in a remote location to the trainees or where there is a rarity of equipment or instructors to facilitate the training.`,
      `Organisations will sometimes want to integrate the HEAT VR training platform into their learning management systems (LMS) so that the learning & development (L&D) lead can assign specific training to the trainee, and that training is then recorded and tracked through the LMS.`,

    ],
  },
  {
    title: "How much does VR training cost?",
    content: [
      `Last year, the Fortune 500 spent $100 Billion on training and safety and yet the majority of those companies suffered a death or serious injury in the previous 18 months.`,
      `The cost of VR training is bespoke to each project, however, the value would be determined on the long-term investment i.e the money saved by implementing remote training, not using machinery to train employees on, not traveling to a far or high-risk destination to carry out training etc.`,
      `The biggest cost associated with VR training is to create bespoke virtual worlds. We are always looking for ways to reduce this cost for our customers and to find partners that can reduce the cost of content creation. This can be done by using existing VR content, using content creation partners or to integrate with existing simulation solutions.`,
      `VRAI will work with you to develop options depending on what problem you are trying to solve. You might be able to recreate scenarios using 360 video, which greatly reduces the time and cost for creating virtual worlds.`,
      `The objective for HEAT is to provide a service where the customers pay per hour of use, or simulation as a service. This means that you do not have to think about the costs of creating the worlds or scenarios, you just pay a set cost each time one of your employees uses HEAT.`
    ]
  },
  {
    title: "Where can I avail of VR training?",
    content: [
      `If you would like a free consultation on how VR simulation training might benefit your company please email hello@vraisimulation.com and we would be happy to assist you.`,
    ],
  },
  {
    title: "What is simulation training?",
    content: [
      `Virtual reality (VR) simulation training allows you to recreate real world training scenarios that can be accessed by putting on a VR headset. VR training allows you to recreate training scenarios but without the inherent risk associated with real world training, without the need to travel to remote training environments and without the need for rare real world instructors or equipment.`,
      `These virtual training scenarios can provide authentic simulations of real world scenarios using 3D models. Trainees can interact with these 3D models like they would with real world objects, they can move around the world with 6 degrees of freedom (6DoF) and receive instruction from virtual training assistants or onscreen instruction.`,
      `VR also allows you to record training performance and use the data generated to iteratively help you improve your training outcomes. These data driven insights can help improve safety and performance in the workplace.`,
      `We find that VR training has the most impact when the activity is something that is inherently risky, is in a remote location to the trainees or where there is a rarity of equipment or instructors to facilitate the training.`
    ]
  },
  {
    title: "What are the advantages of simulation training?",
    content: [
      `Check out our IAG case study that shows how VR simulation training can improve learning, improve knowledge retention and reduce training times: https://www.heatvr.io/iagcargo/`,
      `Pilots, astronauts and military personnel have been using simulation to improve their training capacity, reduce costs and improve outcomes for decades. These simulators were often as expensive as the real world equipment and were housed in "simulation centres". Virtual reality (VR) can now bring this simulation capability to a much wider set of people providing faster, more effective and more efficient training: https://www.pwc.co.uk/issues/intelligent-digital/virtual-reality-vr-augmented-reality-ar/study-into-vr-training-effectiveness.html#results.`,
      `There are many benefits of virtual reality (VR) simulation training, it allows you to provide effective training, remotely and at scale. Elearning might be OK to replace a lecture or classroom based training, but it won’t prepare your staff to face dangerous real-world scenarios such as those in aviation, defence, manufacturing, energy and utilities. VR training can also be standardised & scalable and integrated with your company's learning management system (LMS).`,
      `VR training is also a far more engaging way to train your employees with a recent PwC report showing that people learning in VR completed training 4 times faster than classroom training as well as a 40% improvement in confidence compared to classroom based learners: https://www.pwc.co.uk/issues/intelligent-digital/virtual-reality-vr-augmented-reality-ar/study-into-vr-training-effectiveness.html#results.`,
    ],
  },
  {
    title: "What industries are using virtual reality training?",
    content: [
      `The early adopters of VR training in the UK & Ireland were offshore energy, aviation and defence. The cost of VR hardware has reduced to about €500 per headset, and the business cases are stacking up showing the return on investment (RoI) and impact it is having on large organisations. This is driving the more widespread use of VR training, particularly in large organisations whose activities are risky, remote and rare.`,
      `The impact of the COVID-19 pandemic has meant that large organisations need to find ways to train their employees remotely and safely. This shift towards remote simulation training has been gaining traction over the past number of years, and COVID-19 will likely will increase the speed of civil and enterprise level adoption.`,
      `Virtual reality (VR) training is used across many industries. For example we have worked with utilities, aviation, transport, pharmaceutical companies, construction and defence.`
    ],
  },
  {
    title: "What skills can you train using VRAI Simulation?",
    content: [
      `VR simulation training is a form of "practice by doing" to allow trainees to get hands-on experience with a task but without the associated risk, it can be done remotely and without the need for rare equipment or instructor resources.`,
      `Some VR simulation training can be made bespoke to your company’s learning requirements and will often be part of a blended learning solution i.e. to augment traditional training on a specific piece of equipment, machine or scenario.`,
      `Some VR training can be generic if there are not specific requirements i.e. working at heights, lock out tag out procedures etc.`,
      `We find that VR training has the most impact when the activity is something that is inherently risky, is in a remote location to the trainees or where there is a rarity of equipment or instructors to facilitate the training.`,
      `Organisations will sometimes want to integrate the HEAT VR training platform into their learning management systems (LMS) so that the learning & development (L&D) lead can assign specific training to the trainee, and that training is then recorded and tracked through the LMS.`
    ]
  },
  {
    title: "What should I consider when comparing VR training options?",
    content: [
      `Fundamentally, we recommend that you have a clear reason for moving to VR simulation training i.e. you need to decrease risk for employees, or you need to train people remotely or a lack of equipment or instructors is creating a training bottleneck.`,
      `The next consideration is whether VR training, a form of "practice by doing", is what you need i.e. we find that VR training has the most impact when the activity is something that is inherently risky, is in a remote location to the trainees or where there is a rarity of equipment or instructors to facilitate the training.`,
      `Is this recurring training where a large group of people will need to repeatedly complete this training? If the answer is yes then VR simulation will have a big impact on how you train.`,
      `You must consider the quality of the VR content i.e. how authentic is the training environment and how well does it work. A good way to assess this is to demo it yourself and look at the types of clients your provider has previously worked with.`,
      `Is the VR training a stand alone solution or is it supported and integrated with your Learning Management Systems (LMS)? By having a fully supported solution, you will ensure that your VR training solution is always available and is secured with ongoing software updates. By having a solution that is integrated with your LMS you will ensure that training is appropriately assigned to employees, the training performance is recorded and this is associated with individual employees files.`,
      `How is your VR simulation training provider leveraging data to add value to your training? The insights that you can unlock from data driven VR simulation training will add significant value to your organization. If your VR training provider is not using this data to help unlock insights, improved safety and performance, then they are not providing you with the right solution.`,
    ]
  }
];
