import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
    position: fixed;
    height: 100vh;
    width: 16.5rem;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    box-shadow: 1px 0 0 0 ${({ theme }) => theme.colors.gray[100]};
    padding-bottom: 10rem;
    z-index: 999;

    @media (max-width:1024px) {
        height: auto;
        width: 100%;
        padding-bottom: 0;
    }
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 1.5rem;
    box-sizing: border-box;
    display: flex;

    @media (max-width:1024px) {
        padding: 0;
    }
`;

export const Logo = styled.div`
    background-image:url("/assets/nav-logo.png");
    background-repeat: no-repeat;
    height: 2rem;
    margin: 2rem 0;
    position: relative;
    z-index: 9999;

    @media (max-width:1024px) {
        margin: 1rem;
        background-position: -3rem center;
        width: 8rem;
        display: inline-block;
    }
`;

export const Nav = styled.nav`
    width: 100%;
`

export const MenuContainer = styled.div`
    width: 100%;

    &::-webkit-scrollbar {
        height: 0.2rem;
        display: none;
    }

    &:hover::-webkit-scrollbar {
        display: block;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #1A1A1A;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #666;
        border-radius: 0.35rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media screen and (max-width:767px) {
        overflow: auto;
        padding: 0 0.5rem;
        box-sizing: border-box;
        /* width */
       
    }
`;

export const Menu = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[100]};
    padding-top: 2rem;

    @media (max-width:1024px) {
        flex-direction: row;
        justify-content: center;
        border-top: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray[100]};
        padding-top: 1rem;
    }
    @media screen and (max-width:767px) {
        justify-content: flex-start;
        border-bottom: none;
    }
`;

export const MenuItem = styled.li`
    color: ${({ theme }) => theme.colors.white};
    margin: 0 0 0.8rem 0;
    padding: 0;
    list-style: none;

    @media screen and (max-width:767px) {
        display: inline-block;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }
    
`;

export const MenuLink = styled(NavLink)`
    color: ${({ theme }) => theme.colors.gray[100]};
    background-color: ${({ theme }) => theme.colors.gray[300]};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.9rem 1.6rem;
    text-decoration: none;
    border-radius: 2rem;
    transition: all 0.1s ease-in-out;
    outline: none;

    & svg path {
        fill: ${({ theme }) => theme.colors.gray[100]};
        transition: all 0.1s ease-in-out;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.gray[50]};
        svg path {
            fill: ${({ theme }) => theme.colors.gray[50]};
        }
    }

    &:focus {
        box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.gray[400]}, 0 0 0 3px ${({ theme }) => theme.colors.primary.yellow[400]};
    }

    &[aria-current="page"] {
        color: ${({ theme }) => theme.colors.gray[50]};
        background-color: ${({ theme }) => theme.colors.gray[200]};

        & svg path {
            fill: ${({ theme }) => theme.colors.gray[50]} !important;
        }
    }

    @media (max-width:1024px) {
        padding: 0.65rem 1.6rem;
    }
`;

export const IconWrapper = styled.span`
    width: 1rem;
    height: 1rem;
    margin-right: 0.8rem;
`;