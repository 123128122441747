import { useCallback } from "react"
import { useIntl } from "react-intl"
import FormField, { FormFieldProps } from "../../form/form-field"
import { useWelcomeSurveyData } from "../state"
import { FormStepContainer, FormRow } from "./form-step.styles"

const FormStep = ({ fields }: any) => {

    const intl = useIntl();

    const {
        dispatch,
        state: { errors, values }
    } = useWelcomeSurveyData();
    if (!fields) return null;

    const handleOnChange = useCallback((name: string, value: string | number) => {
        dispatch({
            type: 'SET_FIELD_VALUE', payload: {
                name,
                value
            }
        });

        const { isRequired } = fields.questions.find(({ code }: FormFieldProps) => code === name);

        const newErrors = {
            ...errors
        };

        if (isRequired && !value) {
            newErrors[name] = intl.formatMessage({ id: `welcome-screen:field-required` });
        } else {
            if (name in newErrors) {
                delete newErrors[name];
            }
        }

        dispatch({ type: "SET_ERRORS", payload: newErrors });
    }, [errors]);


    return <FormStepContainer>
        {fields.questions.map(({ code, ...props }: FormFieldProps) =>
            <FormRow key={props?.code}>
                <FormField
                    {...props}
                    code={code}
                    onChange={handleOnChange}
                    value={values[code]}
                    error={errors[code]} />
            </FormRow>
        )}
    </FormStepContainer>
}

export default FormStep;