import styled from "styled-components";

export const ToolTip = styled.div`
    background-color: ${({ theme }) => theme.colors.gray[400]};
    padding: 0.6rem 1rem;
`;

export const ValueKey = styled.span`
    text-transform: capitalize;
`;

export const Name = styled.p`
    font-size: 0.8rem;
`;