import { IconProps } from "../../types/icon";

const Trash = ({ width = 16, height = 17, fill = "#F2F2F2" }: IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 16 17`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 0C4.98748 0 4.16667 0.820811 4.16667 1.83333V3.33333H1.84365H1.82508H1C0.447715 3.33333 0 3.78105 0 4.33333C0 4.85251 0.395644 5.27928 0.901875 5.32858L1.55864 14.5233C1.65832 15.9188 2.81949 17 4.21853 17H11.1148C12.5138 17 13.675 15.9188 13.7747 14.5233L14.4315 5.32858C14.9377 5.27928 15.3333 4.85251 15.3333 4.33333C15.3333 3.78105 14.8856 3.33333 14.3333 3.33333H13.5083H13.4897H11.1667V1.83333C11.1667 0.82081 10.3459 0 9.33333 0H6ZM7 7.66663C7 7.11434 6.55228 6.66663 6 6.66663C5.44772 6.66663 5 7.11434 5 7.66663V12.6666C5 13.2189 5.44772 13.6666 6 13.6666C6.55228 13.6666 7 13.2189 7 12.6666V7.66663ZM10.3333 7.66663C10.3333 7.11434 9.88562 6.66663 9.33333 6.66663C8.78105 6.66663 8.33333 7.11434 8.33333 7.66663V12.6666C8.33333 13.2189 8.78105 13.6666 9.33333 13.6666C9.88562 13.6666 10.3333 13.2189 10.3333 12.6666V7.66663Z"
            fill={fill} />
    </svg>

export default Trash;


