import { ReactNode, useReducer, useContext, createContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getPersonalDetails } from '../../api/user';
import { reducer, SET_SURVEY } from './reducer';

type SettingsStateProps = {
    values: {
        [prop: string]: any,
    },
    survey: any,
    dirty: boolean,
    editMode: boolean,
}

export const initialState: SettingsStateProps = {
    values: {},
    survey: {},
    dirty: false,
    editMode: false,
};

const SettingsContext = createContext<any>({});

type Props = {
    children: ReactNode,
}

const SettingsProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { data, isFetched }: { data: any, isFetched: boolean } = useQuery("get-personal-details", getPersonalDetails, {
        refetchOnWindowFocus: false,
        suspense: false,
        useErrorBoundary: true,
    });

    useEffect(() => {
        if (isFetched && data) {
            dispatch({ type: SET_SURVEY, payload: data });
        }
    }, [isFetched, data]);

    return <SettingsContext.Provider value={{ state, dispatch }}>
        {children}
    </SettingsContext.Provider>
}

function useSettings() {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
}

export { SettingsProvider, useSettings };