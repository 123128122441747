import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  padding-bottom: 1rem;

  ::-webkit-scrollbar {
    height: 0.7rem;
  }

  @media only screen and (max-width: 1023px) {
    overflow: initial;
    white-space: initial;
    padding-right: 0rem;
  }
`;

const pulse = keyframes`
  0% {
        opacity: 0.65;
    }
    50% {
        opacity:1 ;
    }
    100% {
        opacity: 0.65;
    }
`;

type ContainerProps = {
  loading?: boolean | string;
  fluid?: boolean;
};

type TitleProps = {
  color?: string;
};

export const Card = {
  Container: styled.div<ContainerProps>`
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.7rem 1rem;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    width: 23rem;
    min-height: 11rem;
    margin-right: 1rem;
    animation: ${({ loading }) =>
        loading || loading === "true" ? pulse : "none"}
      1s infinite linear;
    overflow: hidden;
    border-radius: 0.6rem;
    pointer-events: none;

    &:last-of-type {
      margin-right: 0;
    }

    @media only screen and (max-width: 1023px) {
      display: inline-block;
      margin: 0 1rem 1rem 0;
      overflow: hidden;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      width: calc(33.3% - 0.6rem);

      &:nth-child(3n) {
        margin-right: -0.5rem;
      }
    }

    @media only screen and (max-width: 768px) {
      width: calc(50% - 0.5rem);
      ${({ fluid }) => fluid && "width: calc(100%) !important"};

      &:nth-child(even) {
        margin-right: -0.5rem;
      }
    }

    ${({ loading }) =>
      loading &&
      `
            justify-content: center;
            align-items: center;
            display: flex !important;
            width: 100% !important;
        `}
  `,
  Title: styled.h2<TitleProps>`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${({ color, theme }) => color || theme.colors.gray[100]};
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  `,
  Value: styled.div`
    margin: 0;
    font-weight: 200;
    font-size: 3.5rem;
  `,
  Small: styled.small`
    font-size: 1rem;
    display: block;
    width: 100%;
  `,
  Unit: styled.span`
    font-size: 2rem;
  `,
};

export const GraphContainer = styled.div`
  width: 100%;
  height: 5rem;
  font-size: 1rem;
  margintop: 1rem;
`;
