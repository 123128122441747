import { IconProps } from "../../types/icon";

const Check = ({ width = 13, height = 11, fill = "#B0D8A3" }: IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 13 11`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M4.3756 8.45315L1.42185 5.4994L0.416016 6.49815L4.3756 10.4577L12.8756 1.95773L11.8768 0.958984L4.3756 8.45315Z" fill={fill} />
    </svg>

export default Check;
