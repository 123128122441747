import { IconProps } from "../../types/icon";

const Info = ({ width = 16, height = 16, fill = "#F2F2F2" }:IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 16 16`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.88291 5.46958C6.63435 5.93784 6.0591 6.13298 5.57424 5.90484C5.07451 5.6697 4.86002 5.07397 5.09515 4.57424C5.21115 4.32773 5.3931 4.12162 5.55586 3.9686C5.73179 3.80319 5.94469 3.64442 6.1829 3.50565C6.65487 3.23068 7.2862 3 7.99998 3C8.8216 3 9.56302 3.39645 10.0807 3.91208C10.5989 4.42824 11 5.17055 11 5.99992C11 6.65235 10.8407 7.4099 10.3295 8.02097C9.98731 8.43001 9.53673 8.71495 8.99998 8.86808V8.99994C8.99998 9.55223 8.55227 9.99994 7.99998 9.99994C7.4477 9.99994 6.99998 9.55223 6.99998 8.99994V8C6.99998 7.44772 7.4477 7 7.99998 7C8.51337 7 8.70811 6.84214 8.79547 6.7377C8.90928 6.60165 8.99998 6.35915 8.99998 5.99992C8.99998 5.81762 8.90107 5.55997 8.66927 5.32909C8.43695 5.09768 8.17836 5 7.99998 5C7.71377 5 7.42684 5.09559 7.18969 5.23376C7.07334 5.30154 6.98396 5.37106 6.92582 5.42572C6.90399 5.44625 6.89035 5.46103 6.88291 5.46958ZM9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12Z"
            fill={fill} />

    </svg>

export default Info;
