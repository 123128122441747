import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { VRIntlProviderComponent } from "../../providers/intl-provider";
import Button from "../button";
import Styled from "../styled";
import Text from "../text";
import { Actions, Container, Wrapper } from "./generic-error";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const GenericError = () => {

    const { instance } = useMsal();

    const handleLogOut = useCallback(() => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }, [instance]);

    return <>
    <VRIntlProviderComponent 
        localeFn={localeFn}
        id="generic-error" fallback={null}>
            <Container>
                <Wrapper>
                    <Styled color="error">
                        <Text variant="large">
                            <FormattedMessage id="generic-error:message"/>
                        </Text>
                    </Styled>
                    <Actions>
                        <Button variant="yellow" onClick={() => window.location.reload()}>
                            <FormattedMessage id="generic-error:refresh"/>
                        </Button>
                        <Styled marginLeft="1rem" marginRight="1rem" color="gray[100]" textTransform="uppercase">
                            <FormattedMessage id="generic-error:or"/>
                        </Styled>
                        <Button variant="yellow" onClick={handleLogOut}>
                            <FormattedMessage id="generic-error:log-out"/>
                        </Button>
                    </Actions>
                </Wrapper>
            </Container>
        </VRIntlProviderComponent>
    </>
};

export default GenericError;