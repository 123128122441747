import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Card = {
  Wrapper: styled.div`
    box-sizing: border-box;
    width: 23rem;
    min-height: 14rem;
    margin-right: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 0.6rem;
    background: ${({ theme }) => theme.colors.gray[300]};

    & * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  `,

  TitleContainer: styled.div`
    display: flex;
  `,

  Title: styled.h3`
    margin-left: 0.625rem;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.primary.green[400]};
  `,

  Description: styled.p``,
};

export const Chart = {
  Wrapper: styled.div`
    width: 75%;
  `,

  Container: styled.div`
    margin-top: 0.85rem;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 0;
    }
  `,

  Stat: styled.p``,

  Value: styled.span`
    color: ${({ theme }) => theme.colors.white};
    font-size: 1rem;
  `,

  Description: styled.span`
    color: ${({ theme }) => theme.colors.gray[100]};
    font-size: 0.625rem;
  `,
};

interface BarProps {
  progress: number;
  color: string;
}

export const Bar = styled.div<BarProps>`
  width: ${({ progress }) => progress}%;
  background-color: ${({ color }) => color};
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.375rem;
`;
