import { ReactNode } from "react";
import Button from "../button";
import Styled from "../styled";
import { Wrapper, Container, Header, Content, Footer } from "./confirmation-dialog.styles";

type Variants = 'yellow' | 'danger' | 'warning' | 'success';

type Props = {
    children: ReactNode,
    title: string | ReactNode,
    onClickCancel: (event: any) => void,
    onClickConfirm: (event: any) => void,
    cancelLabel: string,
    confirmLabel: string,
    variant?: Variants,
    disabled?: boolean
}

const ConfirmationDialog = ({
    children,
    title,
    onClickCancel,
    onClickConfirm,
    cancelLabel,
    confirmLabel,
    variant = 'yellow',
    disabled = false
}: Props) => {
    return <Wrapper>
        <Container>
            <Header variant={variant}>
                {title}
            </Header>
            <Content>{children}</Content>
            <Footer>
                <Styled marginRight="1rem">
                    <Button disabled={disabled} onClick={onClickCancel} size="small">{cancelLabel}</Button>
                </Styled>
                <Button disabled={disabled} onClick={onClickConfirm} size="small" variant={variant}>{confirmLabel}</Button>
            </Footer>
        </Container>
    </Wrapper>
}
export type { Variants as ConfirmationDialogVariants };
export default ConfirmationDialog;

