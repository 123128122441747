import styled from "styled-components";
import { ConfirmationDialogVariants } from ".";

type VariantProps = {
    variant: ConfirmationDialogVariants
}

export const Wrapper = styled.div`
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);
`;

export const Container = styled.div`
    width: 32rem;
    height: auto;
    background-color: ${({ theme }) => theme.colors.gray[500]};
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;

    @media only screen and (max-width: 767px) {
        width: 100%;
        height: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: calc(100vw - 2rem);
        height: calc(100vh - 2rem);
    }
`;

type HeaderProps = {};

export const Header = styled.div<VariantProps & HeaderProps>`
    display: flex;
    align-items: center;
    height: 3.25rem;
    padding: 0 1rem;
    font-size: 1.125rem;
    background-color: ${({ theme, variant }) => theme.colors.primary[variant][800]};
    border-radius: 0.3rem 0.3rem 0 0;
`;

export const Content = styled.div`
    padding: 1rem;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
`;