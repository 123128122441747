import { IconProps } from "../../types/icon";

const Gamepad = ({ width = 20, height = 20, fill = "#ffffff" }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 20 20`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99988 6.99609C1.99988 5.89152 2.89531 4.99609 3.99988 4.99609H15.9999C17.1044 4.99609 17.9999 5.89152 17.9999 6.99609V12.9961C17.9999 14.1007 17.1044 14.9961 15.9999 14.9961H15.0563C14.3953 14.9961 13.7801 14.6662 13.3504 14.1638C12.5911 13.276 11.2753 11.9961 9.99988 11.9961C8.72446 11.9961 7.40862 13.276 6.64932 14.1638C6.21968 14.6662 5.60445 14.9961 4.94343 14.9961H3.99988C2.89531 14.9961 1.99988 14.1007 1.99988 12.9961V6.99609ZM15.9998 8.9961C15.9998 10.1007 15.1044 10.9961 13.9998 10.9961C12.8952 10.9961 11.9998 10.1007 11.9998 8.9961C11.9998 7.89153 12.8952 6.9961 13.9998 6.9961C15.1044 6.9961 15.9998 7.89153 15.9998 8.9961ZM5.99992 10.9961C7.10449 10.9961 7.99992 10.1007 7.99992 8.9961C7.99992 7.89153 7.10449 6.9961 5.99992 6.9961C4.89535 6.9961 3.99992 7.89153 3.99992 8.9961C3.99992 10.1007 4.89535 10.9961 5.99992 10.9961Z"
      fill={fill}
    />
  </svg>
);

export default Gamepad;
