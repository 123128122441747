import { IconProps } from "../../types/icon";

const Trending = ({ width = 18, height = 12, fill = "#F2F2F2" }:IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 18 12`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.83333 2.83301C9.28105 2.83301 8.83333 2.38529 8.83333 1.83301C8.83333 1.28072 9.28105 0.833008 9.83333 0.833008H16.5C17.0523 0.833008 17.5 1.28072 17.5 1.83301V8.49967C17.5 9.05196 17.0523 9.49967 16.5 9.49967C15.9477 9.49967 15.5 9.05196 15.5 8.49967V4.24722L10.5404 9.20678C10.3529 9.39432 10.0985 9.49967 9.83333 9.49967C9.56812 9.49967 9.31376 9.39432 9.12623 9.20678L6.5 6.58055L2.20711 10.8734C1.81658 11.264 1.18342 11.264 0.792893 10.8734C0.402369 10.4829 0.402369 9.84976 0.792893 9.45923L5.79289 4.45923C5.98043 4.2717 6.23478 4.16634 6.5 4.16634C6.76522 4.16634 7.01957 4.2717 7.20711 4.45923L9.83333 7.08546L14.0858 2.83301H9.83333Z" fill={fill} />
    </svg>

export default Trending;


