import {
  EventMessage,
  EventType, InteractionRequiredAuthError, PublicClientApplication
} from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-common";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import jwt_decode from "jwt-decode";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";

import VRIntlSuspender, { VRIntlProviderComponent } from "./components/providers/intl-provider";
import { UserProvider, useUser } from "./context/user";

import { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { msalConfig } from "./authConfig";
import { SET_ROLE, SET_TOKEN } from "./context/user/reducer";

import { Pages } from "./enums/pages";

import pck from "../package.json";
import base from "./themes/base/base";

import { ErrorBoundary } from "react-error-boundary";
import { LoaderContainer, Wrapper } from "./app.styles";
import GenericError from "./components/ui/generic-error";
import Loader from "./components/ui/loader";
import Modal from "./components/ui/modal";
import { Roles } from "./enums/user";
import Navigation from "./modules/navigation";
import WelcomeScreen from "./modules/welcome-screen";
import DashboardPage from "./pages/dashboard";
import SupportPage from "./pages/support";
import Trainees from "./pages/trainees";
import AddTrainee from "./pages/trainees/add-trainee";

import { ApplicationProvider, useApplication } from "./context/application";
import { DashboardFiltersProvider } from "./context/dashboard-filters";
import {
  FeatureTogglesProvider,
  useFeatureToggles
} from "./context/feature-toggles";
import SessionDetail from "./pages/session-detail";
import SessionsList from "./pages/sessions-list";
import SessionsOverview from "./pages/sessions-overview";
import Settings from "./pages/settings";
import GlobalStyle from "./themes/global";

const loginRequest = {
  scopes: [process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPE],
};

const logInfo = () => {
  const p: PackageType = pck;
  console.log(
    "%cHEAT%cDASHBOARD",
    `background-color: ${base.colors.primary.yellow[400]};
      font-size: 2rem; 
      font-weight:bold; padding: 0.4rem 1rem; 
      border-radius: 0.3rem 0 0 0.3rem`,
    `background-color: ${base.colors.gray[500]};
      font-size: 2rem; 
      font-weight: bold; 
      padding: 0.4rem 1rem; 
      color: ${base.colors.gray[50]}; 
      border-radius: 0 0.3rem 0.3rem 0`
  );

  ["version"].forEach((key) =>
    console.log(
      `%c${key}%c${p[key]}`,
      `background-color: ${base.colors.gray[500]};
        font-weight: bold;
        padding: 0.4rem;`,
      `background-color: ${base.colors.gray[100]};
        color: ${base.colors.gray[500]}; 
        font-weight: bold;
        padding: 0.4rem;
        `
    )
  );
};

const localeFn = (target: string) =>
  import(`./locale/${target.toLowerCase()}.json`);

const Authenticator = () => {
  const application = useApplication();
  const [activeAccount, setActiveAccount] = useState<AccountInfo | null>(null);
  const [currentAccessToken, setCurrentAccessToken] = useState<string | null>(null);
  const { instance, accounts, inProgress } = useMsal();
  const { state: user, dispatch: userDispatch } = useUser();
  const [error, setError] = useState(null);
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false);

  const isAuthenticated = useIsAuthenticated();

  useEffect(logInfo, []);

  useEffect(() => {
    const cId: string | null = instance.addEventCallback(
      (message: EventMessage) => {
        if (message.eventType === EventType.LOGOUT_SUCCESS) {
          window.localStorage.removeItem("accessToken");
          userDispatch({ type: SET_TOKEN, payload: null });
        }
      }
    );

    return () => {
      if (cId) instance.removeEventCallback(cId);
    };
  }, [instance]);

  useEffect(() => {
    if (
      !user.registrationCompleted &&
      user.status === "RESOLVED" &&
      !registrationModalOpen
    ) {
      setRegistrationModalOpen(true);
    }
  }, [user.registrationCompleted, user.status]);

  useEffect(() => {
    if (!isAuthenticated && inProgress === "none") {
      instance.loginRedirect(loginRequest).catch((e: any) => {
        setActiveAccount(null);
        setError(e);
      });
    }
  }, [inProgress, isAuthenticated]);

  const getActiveAccount = useCallback(() => {
    if (accounts.length === 0) return;
    const currentAccount = accounts[0];
    let isExpiredToken = false;

    if (currentAccessToken) {
      const decodedToken: any = jwt_decode(currentAccessToken);
      const expirationTime = decodedToken.exp * 1000;
      isExpiredToken = expirationTime < Date.now();
    }

    if (!activeAccount || isExpiredToken) {
      setActiveAccount(currentAccount);

      instance.setActiveAccount(currentAccount);
      instance
        .acquireTokenSilent(loginRequest)
        .then((response: any) => {
          const { accessToken, uniqueId } = response;
          setCurrentAccessToken(accessToken);

          const jwt: any = jwt_decode(accessToken);

          let role = Object.values(Roles).filter(
            (value) => jwt[`extension_IsHeat${value}`]
          )[0];

          userDispatch({ type: SET_ROLE, payload: role });
          userDispatch({ type: SET_TOKEN, payload: accessToken });

          window.localStorage.setItem("accessToken", accessToken);
        }).catch((e) => {
          setActiveAccount(null);

          if (e instanceof InteractionRequiredAuthError) return msalInstance.acquireTokenRedirect(loginRequest); // fallback to interaction when silent call fails
          else setError(e);
        }).finally(() => application.setLoaderQueueResolved("AUTH"));

    }
  }, [accounts, application, instance, activeAccount, currentAccessToken]);

  useEffect(() => {
    const interval = setInterval(getActiveAccount, 1000);
    return () => clearInterval(interval);
  }, [getActiveAccount]);

  const isUserAuthenticated = activeAccount && isAuthenticated;

  if (error) {
    return <GenericError />;
  }

  if (!user.id && user.status !== "RESOLVED") {
    return null;
  }

  return isUserAuthenticated ? (
    <FeatureTogglesProvider>
      <VRIntlProviderComponent
        localeFn={localeFn}
        id="app"
        fallback={null}>
        <Content
          showRegistrationModal={registrationModalOpen}
          onDismissRegistrationModal={() => setRegistrationModalOpen(false)}
        />
      </VRIntlProviderComponent>
    </FeatureTogglesProvider>
  ) : null;
};

type ContentProps = {
  showRegistrationModal: boolean;
  onDismissRegistrationModal: () => void;
};

const Content = ({
  showRegistrationModal,
  onDismissRegistrationModal,
}: ContentProps) => {
  const { state: user } = useUser();
  const { isFeatureActive } = useFeatureToggles();

  const PENDING_INVITES = isFeatureActive("PENDING_INVITES");
  const SETTINGS_PAGE = isFeatureActive("SETTINGS_PAGE");

  return (
    <Wrapper>
      <GlobalStyle />
      <Navigation />

      <Switch>
        <Route path={Pages.Support} component={SupportPage} />

        <Route
          exact
          path={PENDING_INVITES ? Pages.TraineesWithTab : Pages.Trainees}
          component={Trainees}
        />

        <Route exact path={Pages.AddTrainee} component={AddTrainee} />

        {SETTINGS_PAGE && (
          <Route exact path={Pages.Settings} component={Settings} />
        )}

        <DashboardFiltersProvider>
          <Route exact path={Pages.Dashboard} component={DashboardPage} />

          <Route path={Pages.SessionDetail} component={SessionDetail} />
          <Route path={Pages.SessionsList} component={SessionsList} />
          <Route path={Pages.SessionsOverview} component={SessionsOverview} />

          <Route
            exact
            path={Pages.DashboardAllTrainees}
            component={DashboardPage}
          />

          {user.role === Roles.Instructor && (<Route path={Pages.DashboardTrainee} component={DashboardPage} />)}

          {user.role === Roles.Instructor && (
            <Route
              exact
              path="/"
              render={() => <Redirect to={Pages.DashboardAllTrainees} />}
            />
          )}
          {user.role === Roles.Trainee && (
            <Route
              exact
              path="/"
              render={() => <Redirect to={Pages.Dashboard} />}
            />
          )}
        </DashboardFiltersProvider>


      </Switch>

      {showRegistrationModal && (
        <Modal closeDisabled padding={0}>
          <WelcomeScreen onComplete={onDismissRegistrationModal} />
        </Modal>
      )}
    </Wrapper>
  );
};

const msalInstance = new PublicClientApplication({
  ...msalConfig,
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    redirectUri: window.location.origin,
    knownAuthorities: [process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES],
  },
});

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryDelay: 3000,
      suspense: true,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const application = useApplication();
  return <MsalProvider instance={msalInstance}>
    <ThemeProvider theme={base}>
      <QueryClientProvider client={client}>
        <VRIntlSuspender fallback={null}>
          <BrowserRouter>
            <ErrorBoundary fallback={<GenericError />}>
              <UserProvider id={null}>
                <Authenticator />
              </UserProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </VRIntlSuspender>
      </QueryClientProvider>
      {!application.isLoaderQueueReady() &&
        <LoaderContainer><Loader /></LoaderContainer>}
    </ThemeProvider>
  </MsalProvider>

}

const ApplicationWrapper = () =>
  <ApplicationProvider>
    <App />
  </ApplicationProvider>

export default ApplicationWrapper;
