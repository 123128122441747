import styled from "styled-components";

export const Container = styled.div`
background-color: ${({ theme }) => theme.colors.gray[300]};
`;

type SectionBodyProps = {
    editable?: boolean
}

export const Section = {
    Container: styled.div`
        padding: 0.6rem;
        box-sizing: border-box;
        
        border-radius: 0.3rem;
        margin-bottom: 1rem;
    `,
    Title: styled.div`
        padding-bottom: 0.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};;
        font-size: 1rem;
    `,
    Body: styled.div<SectionBodyProps>`
        position: relative;
    `,
    Row: styled.div`
        padding: 0.5rem 0;
    `,
}

export const Question = {
    Container: styled.div``,
    Title: styled.span`
        display: block;
        font-size: 0.8rem;
        color: ${({ theme }) => theme.colors.gray[100]}
    `,
    Response: styled.span`
        display: block;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.white};
        margin-top: 0.3rem;
        font-size: 0.9rem;
    `,
}; 