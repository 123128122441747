import styled from "styled-components";

export const Container = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
  

    @media only screen and (max-width: 767px) {
        height: calc(100% - 11rem);
        overflow-y: auto;
        align-self: flex-start;
        margin-top: 4rem;
    }
`;

export const Logo = styled.div`
    background-image:url("/assets/nav-logo.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 4rem;
    width: 100%;
    display: none;
    position: fixed;
    top: 0;
    background-color: ${({ theme }) => theme.colors.gray[300]};

    @media only screen and (max-width: 767px) {
        display: block;
    }
`;

export const LeftColumn = styled.div`
    float: left;
    width: 30%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 3rem;
    box-sizing: border-box;
    text-align: left;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 40%;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 3rem;
        text-align: center;
        height: auto;
    }

`;

export const RightColumn = styled.div`
    width: 70%;
    float: left;
    height: 100%;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 60%;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
        height: auto;
        padding-bottom: 2rem;
    }

`;


export const ContainerCenter = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
`;