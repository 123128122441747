import { useCallback } from "react";
import { useSettings } from "../../../../context/settings";
import { SET_VALUE } from "../../../../context/settings/reducer";
import FormField, { FormFieldProps } from "../../../../modules/form/form-field";
import { SurveySectionProps } from "../../../../types/survey";
import { Container, Section, Question } from "./personal-details.styles";

const QuestionEntry = ({ title, value }: FormFieldProps) => {
    return <Question.Container>
        <Question.Title>{title}</Question.Title>
        <Question.Response>{value}</Question.Response>
    </Question.Container>
}

const PersonalDetails = () => {
    const { state: settings, dispatch: settingsDispatch } = useSettings();
    const { sections } = settings.survey;
    const { editMode, values } = settings;

    const handleOnChange = useCallback((name: string, value: string | number | boolean) => {
        settingsDispatch({ type: SET_VALUE, payload: { name, value } });
    }, []);

    if (!sections) {
        return null;
    }

    return <Container>
        {sections.map(({ title, questions, code }: SurveySectionProps, index: number) => {
            return <Section.Container key={`survey-section-${code}`}>
                <Section.Title>{index + 1}. {title}</Section.Title>
                <Section.Body>
                    {questions.map(({ code, value, ...props }: FormFieldProps) => {

                        const Component = editMode ? FormField : QuestionEntry;
                        const properties = {
                            code,
                            ...props,
                            value: code in values && values[code] || value,
                            onChange: handleOnChange
                        };

                        return <Section.Row key={`row-${code}`}>
                            <Component {...properties} />
                        </Section.Row>
                    })}
                </Section.Body>
            </Section.Container>
        })
        }
    </Container>;
};

export default PersonalDetails;