import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    padding: 0;
    height: 100%;
    width: 100%;
`;

export const LeftColumn = styled.div`
    flex: 0.7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    background-image:url(assets/create-account-completed.png);
    background-size: cover;
    background-position: 0 100%;
    background-repeat: no-repeat;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 4rem;
`;

export const RightColumn = styled.div`
    display: flex;
    flex: 0.3;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.gray[300]};
`;