export const ADD_LOADER_QUEUE = "ADD_LOADER_QUEUE";
export const SET_LOADER_RESOLVED = "SET_LOADER_RESOLVED";

type Payload = {
  type: string;
  payload: any;
};

export const reducer = (state: any, { type, payload }: Payload) => {
  switch (type) {
    case ADD_LOADER_QUEUE:
      return {
        ...state,
        loadingQueue: {
          ...state.loadingQueue,
          [payload.name]: false,
        },
      };
    case SET_LOADER_RESOLVED:
      return {
        ...state,
        loadingQueue: {
          ...state.loadingQueue,
          [payload.name]: true,
        },
      };
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};
