import styled from "styled-components";

export const DayContainer = styled.div`
	display: flex;
	margin-top: 1rem;
`;

export const Day = styled.div`
	padding: 0.3rem 2rem 0 0;
	flex: 0.10;
`;

export const ListContainer = styled.div`
	flex:0.9;
`;

export const MessageContainer = styled.div`
	display: flex;
	align-items: center;	
	justify-content: center;
	padding: 1rem;
	background-color: ${({ theme }) => theme.colors.gray[300]};

`