import { IconProps } from "../../types/icon";

const Calendar = ({ width = 14, height = 17, fill = "#F2F2F2" }: IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 14 17`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 1C5 0.447715 4.55228 0 4 0C3.44772 0 3 0.447715 3 1V3H2C0.895431 3 0 3.89543 0 5V7H14V5C14 3.89543 13.1046 3 12 3H11V1C11 0.447715 10.5523 0 10 0C9.44771 0 9 0.447715 9 1V3H5V1Z"
            fill={fill} />
        <path
            d="M14 9H0V15C0 16.1046 0.89543 17 2 17H12C13.1046 17 14 16.1046 14 15V9Z"
            fill={fill} />
    </svg>

export default Calendar;