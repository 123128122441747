import styled, { keyframes } from "styled-components"

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    width: calc(100% - 3rem);
    height: 4.25rem;
    background-color: ${({ theme }) => theme.colors.gray[200]};
    border-radius: 2.125rem;
    cursor: pointer;

    @media (max-width:1024px) {
        width: calc(100% - 11rem);
        top: 0;
        right: 1rem;
        left: initial;
        bottom: initial;
        background-color: transparent;
        z-index: 9999;
    }
`;

export const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    @media (max-width:1024px) {
       
        flex-flow: row-reverse;

        & > :last-child {
            display: none;
        }
    }
`;

export const Name = styled.span`
    width: 5.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width:1024px) {
        margin-right: 1rem;
        flex-grow: 1;
        text-align: right;
    }
`;

const listPop = keyframes`
  from {
    opacity: 0;
    transform: translateY(0.2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0rem);
  }
`;

export const List = styled.ul`
    position: absolute;
    bottom: calc(100% + 0.2rem);
    left: 0;
    list-style: none;
    width: 19.5rem;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    box-shadow: 0 0 8px 2px ${({ theme }) => theme.colors.gray[250]};
    border: 1px solid ${({ theme }) => theme.colors.gray[100]};
    border-radius: 4px;
    margin: 0;
    padding: 0;
    animation: ${listPop} 0.1s ease-in;

    @media (max-width:1024px) {
        top: 100%;
        right: 0;
        left: initial;
        width: 12rem;
        border: none;
        bottom: initial;
    }
`;

export const ListItem = styled.li`
    padding: 0.65rem 0.6rem;
    display: flex;
    align-items: center;
    transition: all 0.1s ease-in-out;

    &:hover {
        background-color: ${({ theme }) => theme.colors.gray[200]};
    }

    &:first-of-type  {
        border-radius: 0.2rem 0.2rem 0 0;
    }

    &:last-of-type {
        border-radius: 0 0 0.2rem 0.2rem;
    }
`;

export const ListItemLabel = styled.span`
    margin-left: 0.5rem;
`;