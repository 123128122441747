import { useQuery } from "react-query";
import { getSessionOverviewTopStats } from "../../../../api/sessions";
import { TopStatsResponse } from "../../../../types/top-stats";
import useQueryParams from "../../../../utils/use-query-params";
import TopStats from "../../../shared/top-stats";

export const SessionOverviewTopStats = () => {
	const query: any = useQueryParams();
	const name = query.get('name');
	const { isLoading, data } =
		useQuery<TopStatsResponse>(
			["getSessinOverviewTopStats", name],
			() => getSessionOverviewTopStats({ name }),
			{
				suspense: false,
				refetchOnMount: false,
				useErrorBoundary: true,
				enabled: true,
			});

	return <TopStats
		data={!isLoading && data || []}
		loading={isLoading}
	/>
};

export default SessionOverviewTopStats;

