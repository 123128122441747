import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { getCustomCharts } from "../../../api/dashboard";
import AreaChart from "../../../components/graphs/area";
import PieChart from "../../../components/graphs/pie";
import Frame from "../../../components/ui/frame";
import Message from "../../../components/ui/message";
import { useDashboardFilters } from "../../../context/dashboard-filters";
import { useFeatureToggles } from "../../../context/feature-toggles";
import { Features } from "../../../enums/features";
import { CustomChartType } from "../../../types/custom-charts";
import useMediaQuery from "../../../utils/hooks/use-media-query";
import ChartTooltip from "../../shared/chart-tooltip/chart-tooltip";
import { ChartContainer, FullContainer } from "../dashboard.styles";
import HorizontalBarSection from "./horizontal-bar-section";
import { PIE_COLORS } from "./shared";

export const NoData = () => <Message>
    <FormattedMessage id="no-data" />
</Message>;

const CustomCharts = () => {
    const intl = useIntl();
    const { isFeatureActive } = useFeatureToggles();
    const { state: filters } = useDashboardFilters();
    const isMobile = useMediaQuery('(max-width: 767px)');

    const { from, to, userId } = filters;

    const { data } = useQuery(["getCustomCharts", userId, from, to], () => getCustomCharts(filters), {
        suspense: true,
        refetchOnMount: false,
        useErrorBoundary: true,
    });

    if (!data) {
        return null;
    }

    const horizontalbarData = data.filter(({ chartType }: CustomChartType) => chartType.toLowerCase() === "horizontalbar");

    return <>
        <HorizontalBarSection data={horizontalbarData} />

        {data.map(({ chartType, code, description, label, value, datasets, legend, ...props }: CustomChartType) => {

            const labelKey = label.code;
            const valueKey = value.code;
            let hasData = false;
            let chartData = [];

            if (datasets.length === 0 && isFeatureActive(Features.HideEmptyCharts)) return null;

            switch (chartType.toLowerCase()) {
                case 'area':
                    chartData = datasets && datasets.map((item: any) => {
                        let value = item[valueKey];
                        let name = item[labelKey];

                        if (label.currentMeasurementType === "DateTime") {
                            name = isMobile ? moment(name).format("D/MM") : moment(name).format("Do MMM YY");
                        }

                        return {
                            name,
                            value,
                            labelKey,
                            valueKey
                        }
                    }) || [];

                    hasData = chartData.length > 0;

                    return <FullContainer key={`chart-${code}`}>
                        <Frame title={intl.formatMessage({ id: `dashboard:${code}`, defaultMessage: description })} icon={null}>
                            <ChartContainer empty={!hasData}>
                                {hasData && <AreaChart
                                    tooltipContent={ChartTooltip}
                                    interval={props?.xAxisInterval || 7}
                                    data={chartData}
                                    xAxisLabel={intl.formatMessage({ id: labelKey, defaultMessage: label.description })} /> ||
                                    <NoData />
                                }
                            </ChartContainer>
                        </Frame>

                    </FullContainer>

                case 'pie':
                    chartData = datasets && datasets.map((item: any) => {
                        let value = item[valueKey];
                        let name = item[labelKey];

                        return {
                            name,
                            value
                        }
                    }) || [];

                    hasData = chartData.length > 0;

                    return <FullContainer key={`chart-${code}`}>
                        <Frame title={intl.formatMessage({ id: `dashboard:${code}`, defaultMessage: description })} icon={null}>
                            <ChartContainer empty={!hasData} autoHeight>
                                {hasData && <PieChart
                                    data={chartData}
                                    colors={PIE_COLORS} /> || <NoData />}
                            </ChartContainer>
                        </Frame>
                    </FullContainer>

                default:
                    return null;
            }
        })}
    </>
}

export default CustomCharts;
