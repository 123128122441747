import { ReactNode, useEffect } from "react";
import { CloseIcon } from "../../icons";
import { CloseButton, Container, Wrapper } from "./modal.styles";

type Props = {
    children: ReactNode,
    padding?: string | number,
    closeDisabled: boolean,
    onCloseClick?: () => void,
    width?: string | number,
    height?: string | number,
    transparent?: boolean,
    overlayOpacity?: number,
};

const Modal = ({
    children,
    padding = '1rem',
    closeDisabled = false,
    onCloseClick = () => { },
    width = '70%',
    height = '80%',
    transparent = false,
    overlayOpacity = 0.8
}: Props) => {

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "initial";
        }
    }, []);

    return <Wrapper opacity={overlayOpacity}>
        <Container width={width}
            height={height}
            padding={padding}
            hasHeader={!closeDisabled}
            transparent={transparent}>
            {children}
            {!closeDisabled && onCloseClick && <CloseButton onClick={onCloseClick}>
                <CloseIcon />
            </CloseButton>}
        </Container>
    </Wrapper>
}

export default Modal;
