import { useCallback } from "react";
import { useIntl } from "react-intl";
import Avatar from "../../../components/ui/avatar";
import Input from "../../../components/ui/input";
import { useWelcomeSurveyData } from "../state";
import { AvatarContainer, Container } from "./display-name.styles";

const DisplayName = () => {
    const intl = useIntl();
    const { state: { firstStepInfo }, dispatch } = useWelcomeSurveyData();

    const handleOnChange = useCallback((e: any) => {
        const val = e.target.value;
        dispatch({ type: 'UPDATE_DISPLAY_NAME', payload: val });
    }, []);

    return <Container>
        <AvatarContainer>
            <Avatar
                editable={true}
                size="extraLarge" />
        </AvatarContainer>
        <Input
            name={`display-name`}
            error={firstStepInfo.error}
            value={firstStepInfo.displayName}
            onChange={handleOnChange}
            placeholder={intl.formatMessage({ id: 'welcome-screen:your-name' })} />
    </Container>
};

export default DisplayName;