import { ReactElement, useCallback, useState } from "react";
import { useDetectClickOutside } from 'react-detect-click-outside';
import { SortIcon } from "../../icons";
import Styled from "../styled";
import Text from "../text";
import { Container, Error, IconContainer, Item, Label, List, Value, Wrapper } from "./select.styles";

type Props = {
    label?: string,
    showLabel?: boolean,
    icon?: ReactElement | null,
    name: string,
    value?: string | number,
    options: { id: string, value: string | number, label: string }[],
    size?: 'small' | 'medium' | 'large',
    error?: string | null | undefined,
    [prop: string]: any,
}

const Select = ({ 
    label, 
    showLabel = true, 
    icon = null,
    name, 
    value, 
    options, 
    size = 'medium',
    error, 
    as = "input", 
    ...props }: Props) => {

    const [open, setOpen] = useState<boolean>(false);
    const [isFocus, setIsFocus] = useState(false);

    const handleOnToggle = useCallback((e: any) => {
        setOpen(!open);
    }, [open]);

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    const labelText = value ? options.filter(({ label }) => label === value)[0].label : label;

    const clickOutsideRef = useDetectClickOutside({ onTriggered: onClose });

    return <Container ref={clickOutsideRef}>
        <Wrapper opened={open} focus={isFocus} error={error} type={as} height={"3.5rem"} onClick={handleOnToggle}>
            <IconContainer>
                <SortIcon fill={"#999"} />
            </IconContainer>

            {showLabel ? value && <Label focus={isFocus} error={error}>
                <Text variant="xsMedium">{label}</Text>
            </Label> : null}
            
            {<Value hasValue={!!value}>
                {icon && <Styled marginRight="0.5rem" marginLeft="0.2rem">{icon}</Styled>}
                {value && labelText || label}
            </Value>}
            {open && <List>
                {options.map(({ id, value: v, label }) => 
                <Item 
                    key={id} 
                    selected={label === value} 
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onChange && props.onChange(name, label);
                        setOpen(false);
                    }}>
                    {label}
                </Item>)}
            </List>}
        </Wrapper>
        {error && <Error><Text variant="xsRegular">{error}</Text></Error>}
    </Container>
}


export default Select;