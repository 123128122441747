import moment from "moment";
import { useTheme } from "styled-components";
import { ClockIcon } from "../../../../components/icons";
import Styled from "../../../../components/ui/styled";
import { CustomChartLabelType, CustomChartValueType } from "../../../../types/custom-charts";
import useQueryParams from "../../../../utils/use-query-params";

export const getMinuteGapByDuration = (duration: number) =>
  [
    [1000 * 60, 0.167],
    [1000 * 60 * 10, 1],
    [1000 * 60 * 90, 5],
  ]
    .filter(([time]) => time >= duration)
    .map(([, divisions]) => divisions)
    .shift();

export const getChartTicks = (from: number, to: number) => {
  const duration = to - from;
  const everyMins = getMinuteGapByDuration(duration) || 10;
  const ticksCount: number = Math.ceil(duration / (everyMins * 60 * 1000));

  return Array.from({ length: ticksCount }, (_, i) => i).map(
    (i) => from + i * (everyMins * 60 * 1000)
  );
};

export const displayLabel = ({ code }: Partial<CustomChartLabelType>, value: string | number) => {
  const theme = useTheme();
  switch (code) {
    case "CapturedDate":
      const fromTime = new Date(String(useQueryParams().get("from"))).getTime();
      return (
        <>
          <Styled marginRight="0.1rem">
            <ClockIcon fill={theme.colors.gray[500]} width={10} height={10} />
          </Styled>
          {moment()
            .startOf("day")
            .add((Number(value) - fromTime) / 1000 / 60, "minutes")
            .format("mm:ss")}
        </>
      );
    default:
      return value;
  }
};

export const displayUnit = ({ unit }: Partial<CustomChartValueType>) => {
  return unit;
}

export const formatXTickText = ({
  code,
  currentMeasurementType,
  displayMeasurementType
}: Partial<CustomChartLabelType>,
  value: string | number) => {

  switch (code) {
    case "CapturedDate":
      const queryParams = useQueryParams();
      const fromTime = new Date(String(queryParams.get('from'))).getTime();
      const toTime = new Date(String(queryParams.get('to'))).getTime();

      const minutes = (Number(value) - fromTime) / 1000 / 60;
      const totalDurationInMin = (toTime - fromTime) / 1000 / 60;;

      const label = totalDurationInMin <= 1 ?
        moment().startOf('day').add(((Number(value) - fromTime) / 1000 / 60), 'minutes').format('mm:ss')
        : Math.floor(minutes)

      return currentMeasurementType === "DateTime"
        && displayMeasurementType === "Minutes"
        && label.toString().padStart(2, '0');
    default:
      return value;
  }
}

export const formatYTickText = (value: any) => {
  const number = parseFloat(value);

  if (isNaN(number)) {
    return value;
  }

  if (number > 1) return number;

  return (number % 1 !== 0) ? number.toFixed(2) : number;
}

const CHAR_WIDTH = 6.5;
export const ellipsisText = (value: string, width: number) => {
  let val = value;

  const w = (val.length * CHAR_WIDTH)

  if (w > width) {
    const howManyChars = width / CHAR_WIDTH;
    val = `${val.split('').splice(0, howManyChars).join('')}...`;
  }

  return val;
}