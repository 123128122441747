import { IconProps } from "../../types/icon";

const Heart = ({ width = 16, height = 15, fill = "#F2F2F2" }:IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 16 15`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" d="M1.17157 1.67157C2.73367 0.109476 5.26633 0.109476 6.82842 1.67157L7.99999 2.84315L9.17157 1.67157C10.7337 0.109476 13.2663 0.109476 14.8284 1.67157C16.3905 3.23367 16.3905 5.76633 14.8284 7.32843L7.99999 14.1569L1.17157 7.32843C-0.390524 5.76633 -0.390524 3.23367 1.17157 1.67157Z" 
            fill={fill}/>

    </svg>

export default Heart;