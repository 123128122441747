import styled from "styled-components";

export const Wrapper = styled.div`

`;

export const Label = styled.div`
    margin-bottom: 0.6rem;
`;

export const Input = styled.input`
    &[type=range] {
        -webkit-appearance: none;
        margin: 18px 0;
        width: 100%;
        outline: none;
    }
    &[type=range]:focus {
        
    }
    &[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        background: ${({ theme }) => theme.colors.primary.yellow[300]};
    }
    &[type=range]::-webkit-slider-thumb {
        height: 24px;
        width: 24px;
        background: ${({ theme }) => theme.colors.primary.yellow[400]};
        cursor: pointer;
        -webkit-appearance: none;
        border-radius: 50%;
        margin-top: -11px;
    }
    &[type=range]:focus::-webkit-slider-runnable-track {
        background: ${({ theme }) => theme.colors.primary.yellow[400]};
    }
    &[type=range]::-moz-range-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        background: ${({ theme }) => theme.colors.primary.yellow[300]};
    }
    &[type=range]::-moz-range-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
    }
    &[type=range]::-ms-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
    }
    &[type=range]::-ms-fill-lower {
        background: #2a6495;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }
    &[type=range]::-ms-fill-upper {
        background: ${({ theme }) => theme.colors.primary.yellow[300]};
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }
    &[type=range]::-ms-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
    }
    &[type=range]:focus::-ms-fill-lower {
        background: ${({ theme }) => theme.colors.primary.yellow[300]};
    }
    &[type=range]:focus::-ms-fill-upper {
        background: ${({ theme }) => theme.colors.primary.yellow[400]};
    }
`;

export const Divisions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
`;

export const Division = styled.div`
    margin-left: 0.4rem;
`;