import { IconProps } from "../../types/icon";

const Dashboard = ({ width = 18, height = 18, fill = "#F2F2F2" }:IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 18 18`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM4 14H6V7H4V14ZM10 14H8V4H10V14ZM12 14H14V10H12V14Z"
            fill={fill} />
    </svg>

export default Dashboard;