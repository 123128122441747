import styled from "styled-components";

export const CalendarContainer = styled.div`
    width: auto;
    border-left: 1px solid ${({ theme }) => theme.colors.gray[100]};
    padding: 1rem;
`;

export const Columns = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const PresetsList = styled.ul`
    width: 100%;
    min-width: 9.3rem;
    margin: 0;
    padding: 0.5rem;
`;

export const Preset = styled.li`
    list-style: none;
    padding: 0 0.2rem;
    margin: 0.3rem 0;
`;

export const PresetsContainer = styled.div`
    width: 9.5rem;
`;

export const Link = styled.a<{ selected: boolean }>`
    padding: 0.2rem 0.4rem;
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
    border-radius: 0.2rem;

    

    background-color: ${({ theme, selected }) => selected ? theme.colors.primary.yellow[400] : 'transparent'};
    color: ${({ theme, selected }) => theme.colors.gray[selected ? 400 : 50]};

    &:hover {
        background-color: ${({ theme, selected }) => theme.colors.primary.yellow[selected ? 300 : 900]};
        color: ${({ theme, selected }) => selected ? theme.colors.gray[400] : theme.colors.gray[50]};
    }
`;

export const DateRangeLabel = styled.div`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    margin: 0 0.2rem;
`;