import { get } from "lodash";
import styled, { DefaultTheme } from "styled-components";

function convertToDash(str: string) {
  return str.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
}

interface IProps {
  textVariant: string;
  color: string;
  [key: string]: any;
  theme: DefaultTheme;
}

type OtherProps = {
  [key: string]: string | number;
};

const keysToExclude: string[] = ["color", "textVariant", "theme", "children"];

const Styled = styled.span<IProps>`
  ${({ theme, textVariant }: { theme: any; textVariant: string }) =>
    textVariant &&
    Object.keys(theme.typography[textVariant])
      .map(
        (k: string) => `${convertToDash(k)}:${theme.typography[textVariant][k]}`
      )
      .join(";")};
  ${({ theme, color }) => color && `color:${get(theme.colors, color)}`};
  ${(props: OtherProps) =>
    Object.keys(props)
      .filter((k) => !keysToExclude.includes(k))
      .map((k) => `${convertToDash(k)}:${props[k]}`)
      .join(";")};
`;

export default Styled;
