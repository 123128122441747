import moment from "moment";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ArrowRight from "../../components/icons/arrow-right";
import Trending from "../../components/icons/trending";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Page, { PageLoader } from "../../components/ui/page";
import Styled from "../../components/ui/styled";
import { useFeatureToggles } from "../../context/feature-toggles";
import { useUser } from "../../context/user";
import { Features } from "../../enums/features";
import { Pages } from "../../enums/pages";
import { Roles } from "../../enums/user";
import useQueryParams from "../../utils/use-query-params";
import ActionsPerformed from "./components/actions-performed";
import CustomCharts from "./components/custom-charts";
import Score from "./components/score";
import SessionDetailTopStats from "./components/top-stats";
import { ButtonScenarioOverview, Main, Section } from "./session-detail.styles";

const localeFn = (target: string) =>
  import(`./locale/${target.toLowerCase()}.json`);

type Props = {};

const SessionDetail = ({ }: Props) => {
  const { state: user } = useUser();
  const { isFeatureActive } = useFeatureToggles();
  const intl = useIntl();
  const history = useHistory();
  const params: any = useQueryParams();
  const name = params.get("name");
  const from = params.get("from");
  const to = params.get("to");

  const handleOnClickOverview = () => {
    history.push(
      `${Pages.SessionsOverview
      }?userId=${params.get("userId")
      }&name=${encodeURIComponent(name)
      }&from=${from
      }&to=${to}`
    );
  };

  const offset: number = 200;

  const title = name || intl.formatMessage({ id: "session-detail:title" });

  const breadcrumbs = [
    {
      label: "Dashboard",
      path: `${user.role === Roles.Instructor
        ? (Pages.DashboardTrainee.replace(":userId", params.get("userId")))
        : Pages.Dashboard}`,
    },
  ];

  params.get("simulationDate") && breadcrumbs.push({
    label: moment(params.get("simulationDate")).format("MMM Do, YYYY"),
    path: `${Pages.SessionsList
      }?userId=${params.get("userId")
      }&date=${params.get("simulationDate")}`,
  });
  breadcrumbs.push({ label: title, path: "" });

  return (
    <Page title={title} breadcrumbs={breadcrumbs}>
      <Main>
        {
          isFeatureActive(Features.SessionOverview) &&
          <ButtonScenarioOverview onClick={handleOnClickOverview}>
            <Styled marginRight={"0.65rem"}>
              <FormattedMessage id="session-detail:scenario-overview" />
            </Styled>
            <ArrowRight />
          </ButtonScenarioOverview>
        }


        {isFeatureActive(Features.SessionDetailTopStats) &&
          <Section.Container>
            <Section.Header justifyContent="space-between">
              <Styled display="flex" alignItems="center">
                <Styled marginRight={"1rem"} display="flex" alignItems="center">
                  <Trending width={22} height={22} />
                </Styled>
                <FormattedMessage id="top-stats" />
              </Styled>
            </Section.Header>
            <Section.Body hasBackground={false}>
              <SessionDetailTopStats />
            </Section.Body>
          </Section.Container>}


        {isFeatureActive(Features.SessionDetailScore) &&
          <Section.Container>
            <Section.Header justifyContent="space-between">
              <Styled display="flex" alignItems="center">
                <Styled marginRight={"1rem"} display="flex" alignItems="center">
                  <Trending width={22} height={22} />
                </Styled>
                <FormattedMessage id="session-detail:score" />
              </Styled>
            </Section.Header>
            <Section.Body hasBackground={false}>
              <Score />
            </Section.Body>
          </Section.Container>}

        {isFeatureActive(Features.SessionDetailActionsPerformed) &&
          <ErrorBoundary fallback={
            <Styled color="gray[100]" textAlign="center">
              <FormattedMessage id="session-detail:actions-performed:error" />
            </Styled>}>
            <Suspense fallback={null}>
              <ActionsPerformed offset={offset} />
            </Suspense>
          </ErrorBoundary>}

        <CustomCharts offset={offset} />
      </Main>
    </Page>
  );
};

const SessionDetailPage = (props: Props) => (
  <VRIntlProviderComponent
    localeFn={localeFn}
    id="session-detail"
    fallback={<PageLoader />}
  >
    <SessionDetail {...props} />
  </VRIntlProviderComponent>
);

export default SessionDetailPage;
