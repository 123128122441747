import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ChartContainer = styled.div`
  width: 100%;
  padding: 0 2rem 0 1rem;
  box-sizing: border-box;
`;

export const TooltipContent = {
  Container: styled.div`
    background-color: ${({ theme }) => theme.colors.gray[50]};
    color: ${({ theme }) => theme.colors.gray[500]};
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
    line-height: 1.2rem;
    outline: none;
  `,
  Label: styled.span`
    display: block;
  `,
  Value: styled.span`
    &:after {
      content: ",";
    }

    &:last-of-type:after {
      content: "";
    }
  `,
};
