import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	Text as ChartText,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from "recharts";
import { useTheme } from "styled-components";
import { CustomChartDataset, CustomChartLegend, CustomChartLegendItem, CustomChartValueType } from "../../../../types/custom-charts";
import { CustomLegend, CustomTooltip } from "./question-chart.styles";

import Text from "../../../../components/ui/text";

export type Props = {
	data: CustomChartDataset[],
	interval: number,
	value: CustomChartValueType,
	legend?: CustomChartLegend
}

const LegendList = ({ legend }: { legend: CustomChartLegend }) =>
	<CustomLegend.Container>
		<CustomLegend.List>
			{legend.items.map(({ text, color, code }: CustomChartLegendItem) =>
				<CustomLegend.Item>
					<CustomLegend.Color color={color} />
					{text}
				</CustomLegend.Item>)}
		</CustomLegend.List>
	</CustomLegend.Container>

const TooltipContent = ({ active, payload }: any) => {
	if (!active) return null;
	const data = payload[0];
	return <CustomTooltip.Container>
		<Text variant="xsRegular">
			{data.name}
		</Text><br />
		<Text variant="smallRegular">{data.value}</Text>
	</CustomTooltip.Container>
}

export default function QuestionChart({ data, interval, value: { maxDisplayValue, code }, legend }: Props) {
	const theme = useTheme();
	const sortedData = data.sort((a: CustomChartDataset, b: CustomChartDataset) => b.answerCount - a.answerCount);

	const max = maxDisplayValue || Math.max(...data.map((d: CustomChartDataset) => d.answerCount));
	const maxInterval = Math.ceil(max / interval) * interval;

	return (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart
				data={sortedData}
				layout="vertical"
				width={500}
				height={300}
				margin={{ right: 16, left: 180 }}
				barSize={8}
			>
				<CartesianGrid
					horizontal={false}
					strokeDasharray="10 10"
					stroke={theme.colors.gray[200]}
				/>
				<XAxis
					type="number"
					axisLine={false}
					allowDataOverflow
					domain={[0, maxInterval]}
					tick={false}
				/>
				<YAxis
					type="category"
					dataKey="chosenAnswer"
					axisLine={false}
					fill={theme.colors.gray[50]}
					mirror
					dx={-20}
					textAnchor="end"
					tick={<CustomTick />}
				/>

				<Tooltip
					isAnimationActive={false}
					separator={": "}
					cursor={false}
					content={<TooltipContent />}
				/>

				{legend && <Legend fill={theme.colors.gray[100]} content={<LegendList legend={legend} />} />}

				<Bar dataKey={code} name="# OF RESPONSES" fill="#808080">
					{sortedData.map((entry: CustomChartDataset, index: number) => {
						const color = legend?.items.find(({ code }: CustomChartLegendItem) => code === entry?.legend)?.color;
						return <Cell key={`cell-${index}`} fill={color || theme.colors.primary.yellow[400]} />
					})}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	)
}

function CustomTick(props: any) {
	const theme = useTheme();
	const { payload: { value } } = props;
	// const isAnswer = answer === value; // TODO: https://github.com/recharts/recharts/discussions/3563

	return (
		<ChartText
			{...props}
			fill={theme.colors.gray[50]}
			width={160}>
			{/* {isAnswer && <StarIcon />} */}
			{value}
		</ChartText>
	);
}
