export enum Features {
  AudioClick = "AUDIO_CLICK",
  CompletedScenarios = "COMPLETED_SCENARIOS",
  PendingInvites = "PENDING_INVITES",
  QuestionsList = "QUESTIONS_LIST",
  Top5Scenarios = "TOP_5_SCENARIOS",
  SettingsPage = "SETTINGS_PAGE",
  SessionChart = "SESSION_CHART",
  SessionList = "SESSION_LIST",
  SessionOverview = "SESSION_OVERVIEW",
  SessionDetailTopStats = "SESSION_DETAIL_TOP_STATS",
  SessionDetailActionsPerformed = "SESSION_DETAIL_ACTIONS_PERFORMED",
  SessionDetailScore = "SESSION_DETAIL_SCORE",
  HideEmptyCharts = "HIDE_EMPTY_CHARTS",
}
