import { LegendItem } from "./score.styles";

const RenderLegend = (props: any) => {
	const { payload } = props;

	return (
		<LegendItem.List>
			{
				payload.map((entry: any, index: Number) => (
					<LegendItem.Item key={`item-${index}`}>
						<LegendItem.Circle color={entry.color}></LegendItem.Circle>
						<LegendItem.Text color={entry.color}>{entry.value}</LegendItem.Text>
					</LegendItem.Item>
				))
			}
		</LegendItem.List>
	);
}

export default RenderLegend;
