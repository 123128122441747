import { ReactNode } from "react";
import { Container } from "./pill.styles";

type Variant = 'danger' | 'warning' | 'success' | 'dark';
type Size = 'small' | 'medium' | 'large';

type Props = {
    children: string | ReactNode,
    variant?: Variant,
    size?: Size
}

const Pill = ({
    children,
    variant = 'dark',
    size = 'medium' }: Props) =>
    <Container variant={variant} size={size}>
        {children}
    </Container>

export type { Variant as PillVariant };
export type { Props as PillProps };
export type { Size as PillSize };
export default Pill;