import { ReactNode, useState } from "react";
import { Session, SessionEntry } from "../../../../../types/session";
import { sortAlpha } from "../../../../../utils/sort";
import { DAY_MS, FlowProps } from "../Chart";

type OwnProps = {};

type Props = FlowProps & OwnProps;

export const HorizontalFlowChart = ({ data, startDate, settings, onItemClick, tooltip, tooltipWidth, tooltipHeight }: Props) => {
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const [mouseCoords, setMouseCoords] = useState<any>({ x: 0, y: 0 });
    const [hoverSession, setHoverSession] = useState<SessionEntry | null>(null);

    const { name, columnWidth, barSize, rowHeight, padding, xLabelGap } = settings;

    const allScenarios = data
        .reduce((acc: Session[], { scenarios }: SessionEntry) => { acc.push(...scenarios); return acc; }, [])
        .reduce((acc: string[], { scenario }: any) => { if (acc.indexOf(scenario) < 0) acc.push(scenario); return acc; }, [])
        .sort(sortAlpha);

    const timezoneOffsetMS = startDate.getTimezoneOffset() * 60000 / DAY_MS;
    const idPrefix = `chart-${name}`;

    const clipPaths: ReactNode[] = [];
    const hoverRects: ReactNode[] = [];

    return <>
        {data.map((entry: SessionEntry, index: number) => {
            const { scenarios, name, id } = entry;
            let p = { x: 0, y: 0 };
            const start = new Date(scenarios[0].from);
            const end = new Date(scenarios[scenarios.length - 1].to);
            const len: number = (end.getTime() + (end.getTimezoneOffset() * 60 * 1000)) - (start.getTime() + (start.getTimezoneOffset() * 60 * 1000))
            const x: number = (((start.getTime() - (startDate?.getTime() || 0)) / DAY_MS) * columnWidth);

            const sessionsScenarios: string[] = scenarios.reduce((acc: string[], { scenario }: Session) => {
                if (acc.indexOf(scenario) < 0) {
                    acc.push(scenario)
                }
                return acc;
            }, []).sort(sortAlpha);

            const firstScenarioIndex = allScenarios.findIndex((s: string) => s === sessionsScenarios[0]);
            const lastScenarioIndex = allScenarios.findIndex((s: string) => s === sessionsScenarios[sessionsScenarios.length - 1]);

            clipPaths.push(
                <clipPath id={`clip-${idPrefix}-${index}`}>
                    <rect
                        x={x + padding + xLabelGap + (timezoneOffsetMS * columnWidth)}
                        y={padding - (barSize * 0.75) + (firstScenarioIndex * rowHeight)}
                        width={(len / DAY_MS) * columnWidth}
                        height={(lastScenarioIndex - firstScenarioIndex) * rowHeight + (barSize * 1.5)}
                    />
                </clipPath>)

            hoverRects.push(
                <rect x={x + padding + xLabelGap + (timezoneOffsetMS * columnWidth)}
                    y={padding - (barSize * 0.75) + (firstScenarioIndex * rowHeight)}
                    width={(len / DAY_MS) * columnWidth}
                    height={(lastScenarioIndex - firstScenarioIndex) * rowHeight + (barSize * 1.5)}
                    onClick={() => onItemClick({ id, name, scenarios })}
                    onMouseOver={() => { setHoverSession(entry); setMouseOver(true); }}
                    onMouseMove={(e: any) => { setMouseCoords({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }); }}
                    onMouseOut={() => { setHoverSession(null); setMouseOver(false) }} opacity={0} style={{ cursor: 'pointer' }} />)

            return <>
                <mask id={`mask-${idPrefix}-${index}`}>
                    <g transform={`translate(${x} -${firstScenarioIndex})`}>
                        {scenarios.map(({ from, to, scenario }: Session, index: number) => {

                            const scenarioIndex = (allScenarios || []).findIndex((s: string) => s === scenario);

                            const f = new Date(from);
                            const t = new Date(to);

                            const w = ((t.getTime() - f.getTime()) / DAY_MS) * columnWidth
                            const h = barSize
                            const x = ((f.getTime() - start.getTime()) / DAY_MS) * columnWidth + (timezoneOffsetMS * columnWidth)
                            const y = (scenarioIndex * rowHeight) - (barSize / 2)

                            const line = <line key={`scenario-line-${index}`} x1={x + padding + xLabelGap + 1} y1={y + padding + (barSize / 2)} x2={p.x - 1} y2={p.y} stroke={"white"} strokeWidth={2} />

                            p = {
                                x: (x + w) + padding + xLabelGap,
                                y: (y + h) + padding - (barSize / 2)
                            }

                            return <>
                                <rect key={`scenario-${index}`} fill={"white"} x={x + padding + xLabelGap} y={y + padding} width={w} height={h} rx={barSize / 2} />
                                {index > 0 && line}
                            </>

                        })}
                    </g>
                </mask>
                <rect
                    className="region"
                    x={x + padding + xLabelGap + (timezoneOffsetMS * columnWidth)}
                    y={padding - (barSize / 2)}
                    width={(len / DAY_MS) * columnWidth}
                    height={(allScenarios.length - 1) * rowHeight + (barSize)}
                    fill={`url(#gradient-${settings.name})`}
                    mask={`url(#mask-${idPrefix}-${index})`}
                    clipPath={`url(#clip-${idPrefix}-${index})`}
                    opacity={mouseOver && hoverSession?.id === id ? 1 : 0.5}
                />
            </>
        })}
        <defs>{clipPaths}</defs>
        {hoverRects}
        {mouseOver && hoverSession && <foreignObject x={mouseCoords.x - (tooltipWidth / 2)} y={mouseCoords.y - tooltipHeight - 30} width={tooltipWidth} height={tooltipHeight}>
            {tooltip(hoverSession)}
        </foreignObject>}
    </>
}