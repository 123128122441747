import { ReactElement } from "react";
import Classify from "../../components/ui/classify";
import Input from "../../components/ui/input";
import PercentageSlider from "../../components/ui/percentage-slider";
import Radio from "../../components/ui/radio";
import Select from "../../components/ui/select";

export type FormFieldProps = {
    title: string,
    value: string | number | undefined,
    error: string | null | undefined,
    type: string,
    code: string,
    isRequired: boolean,
    onChange: (name: string, value: string | number) => void,
    options: {
        value: string | number,
        code: string,
    }[],
    [prop: string]: any;
}

const FormField = ({
    title,
    value,
    error,
    type,
    code,
    options,
    isRequired = false,
    onChange, ...props
}: FormFieldProps): ReactElement | null => {

    switch (type.toLowerCase()) {
        case 'input':
        case 'text':
            return <Input
                key={code}
                id={code}
                name={code}
                label={title}
                error={error}
                value={value}
                onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                }}
            />
        case 'select':
            return <Select
                key={code}
                id={code}
                name={code}
                required={isRequired}
                label={title}
                error={error}
                value={value}
                options={options.map(({ value, code }) => ({
                    id: code,
                    value,
                    label: value.toString(),
                }))}
                onChange={(code: string, value: string | number) => {
                    onChange(code, value);
                }}
            />
        case 'radio':
            return <Radio
                key={code}
                id={code}
                name={code}
                required={isRequired}
                label={title}
                error={error}
                value={value}
                options={options.map(({ value, code }) => ({
                    value,
                    label: value.toString()
                }))}
                onChange={(name: string, value: string | number) => {
                    onChange(name, value);
                }}
            />
        case 'rate':
            return <Classify
                key={code}
                required={isRequired}
                label={title}   
                name={code}
                from={options[0].value}
                to={options[1].value}
                value={Number(value)}
                divisions={Number(options[1].value)}
                onChange={(name: string, value: string | number) => {
                    onChange(name, value);
                }}
            />
        case 'percentage-slider':
            return <PercentageSlider
                key={code}
                required={isRequired}
                label={title}
                
                name={code}
                value={Number(value)}
                divisions={props.divisions}
                step={props.step}
                onChange={(name: string, value: string | number) => {
                    onChange(name, value);
                }}
                before={props.fromLabel}
                after={props.toLabel}
            />
        default:
            return null;
    }
}

export default FormField;