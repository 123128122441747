import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }
`;

export const Column = styled.div`
    flex: 0.5;
    padding: 0 1rem;

    @media only screen and (max-width: 767px) {
        flex: 1;
        margin-bottom: 1rem;
        padding: 0;
    }
`;

export const Section = {
    Title: styled.span`
        display: flex;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        justify-content: space-between;
        align-items: center;
        height: 3rem;
    `,
    Container: styled.div`
        display: block;
    `,
};