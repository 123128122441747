import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Avatar from "../../../../components/ui/avatar";
import Link from "../../../../components/ui/link";
import Input from "../../../../components/ui/input";
import { useSettings } from "../../../../context/settings";
import { SET_VALUE } from "../../../../context/settings/reducer";
import { useUser } from "../../../../context/user";
import { BadgeContainer, Container, Form, FormRow } from "./user-profile.styles";

type Props = {

};

const UserProfile = (props: Props) => {
    const { instance } = useMsal();
    const { state: user } = useUser();
    const { state: settings, dispatch: settingsDispatch } = useSettings();


    useEffect(() => {
        settingsDispatch({ type: SET_VALUE, payload: { name: 'username', value: user.name, dirty: false } });
        settingsDispatch({ type: SET_VALUE, payload: { name: 'email', value: user.email, dirty: false } });
    }, []);

    const handleOnChange = useCallback((e: any) => {
        const value = e.target.value;
        const name = e.target.name;
        settingsDispatch({ type: SET_VALUE, payload: { name, value } });
    }, []);

    const handleChangePassword = useCallback(() => {
        instance.loginRedirect({
            authority: process.env.REACT_APP_MSAL_PASSWORD_CHANGE_AUTHORITY,
            scopes: [process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPE],
        });
    }, []);

    return <Container>
        <BadgeContainer>
            <Avatar editable={true} size="extraLarge" />
        </BadgeContainer>
        <Form>
            <FormRow>
                <Input name="username" label="Username" value={settings.values['username']} onChange={handleOnChange} />
            </FormRow>
            <FormRow>
                <Input name="email" label="Email" value={settings.values['email']} onChange={handleOnChange} />
            </FormRow>
            <FormRow>
                <Link onClick={handleChangePassword}>
                    <FormattedMessage id="settings:change-password" />
                </Link>
            </FormRow>
        </Form>
    </Container>
}

export default UserProfile;