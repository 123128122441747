import { FormattedMessage } from "react-intl";
import Text from "../../../components/ui/text";
import { Name, ToolTip, ValueKey } from "./chart-tooltip.styles";

const ChartTooltip = ({
  active,
  payload,
  label,
}: {
  active: boolean;
  payload: any;
  label: string;
}) => {
  if (active && payload) {
    const data = payload[0].payload;

    if (!data || !data.info) {
      return (
        <ToolTip>
          <span>{data.value} </span>
          <ValueKey>{data.valueKey}</ValueKey>
          <Name>{data.name}</Name>
        </ToolTip>
      );
    }

    return (
      <ToolTip>
        {data.info.displayMeasurementType === "Minutes" && (
          <>
            <Text variant="extraLarge">{data.value}</Text>
            <Text variant="xsMedium">
              <FormattedMessage id="dashboard:min" defaultMessage={"min"} />
            </Text>
            {data.name && <Name>{data.name}</Name>}
          </>
        )}
      </ToolTip>
    );
  }
  return null;
};

export default ChartTooltip;
