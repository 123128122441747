import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";
import { Bar, BarChart, Cell, Line, LineChart, ResponsiveContainer } from "recharts";
import { useTheme } from "styled-components";
import { CardLoader } from "../../../components/ui/loader";
import Styled from "../../../components/ui/styled";
import { useFeatureToggles } from "../../../context/feature-toggles";
import { Features } from "../../../enums/features";
import { CustomChartType } from "../../../types/custom-charts";
import { TopStatType } from "../../../types/top-stats";
import { formatDuration } from "../../../utils/time";
import { Card, Container, GraphContainer } from "./top-stats.styles";

const HIDDEN_STATS = ["completed-scenarios"];

const parseValue = (value: number, measureType: string, displayType: string) => {
	if (!value) return 0;
	if ((displayType === 'Time' && measureType === 'Milliseconds')) {
		return value === 0 ? '00:00:00' : formatDuration(value);
	}

	return value.toFixed(2).replace(/[.,]00$/, "");
}

type Props = {
	max?: number;
	data: TopStatType[],
	loading?: boolean,
}

const TopStats = ({
	max = -1,
	data,
	loading = true,
}: Props) => {
	const { isFeatureActive } = useFeatureToggles();
	return <>
		{loading && <CardLoader />}
		<Container>{
			(data && data
				.filter((stat: TopStatType, index: number) => max === -1 ? true : index < max)
				.filter((stat: TopStatType) => !isFeatureActive(Features.CompletedScenarios) ? !HIDDEN_STATS.includes(stat.code) : true)
				.map((stat: TopStatType, index: number) => {
					return <Item
						key={`card-${index}-${stat.code}`}
						fluid={index === data.length - 1 && data.length % 2 > 0}
						{...stat} />
				}
				))}
		</Container>
	</>
}

const Item = ({ fluid, ...item }: TopStatType & { fluid: boolean }) =>
	<Card.Container fluid={fluid}>
		<Card.Title color={item.color}>
			{item.icon && <Icon name={item.icon} color={item.color} />}
			<FormattedMessage
				id={`dashboard:${item.code}`}
				defaultMessage={item.description} />
		</Card.Title>
		<Card.Value>
			<Content {...item} />
		</Card.Value>
	</Card.Container>

const Content = ({
	value,
	chart,
	currentMeasurementType,
	displayMeasurementType,
	presentationType = "value",
	unit,
}: TopStatType) => {
	switch (presentationType) {
		case "chart":
			return chart && <>
				<Card.Small>{chart.value?.description}: {parseValue(value, currentMeasurementType, displayMeasurementType)}</Card.Small>
				<ContentChart {...chart} />
			</> || null;
		case "value":
		default:
			return <>
				{parseValue(value, currentMeasurementType, displayMeasurementType)}
				{unit && <Card.Unit>{unit}</Card.Unit>}
			</>
	}
}

const Icon = ({
	name,
	color
}: {
	name: string,
	color?: string
}) => {
	const theme = useTheme();
	const Component = React.lazy(() => import(`./../../../components/icons/${name}`));
	return <ErrorBoundary fallback={<span></span>}>
		<React.Suspense fallback={<span></span>}>
			<Styled marginRight="0.5rem">
				<Component fill={color || theme.colors.gray[100]} />
			</Styled>
		</React.Suspense>
	</ErrorBoundary>
}


const Dot = ({
	value,
	...props
}: {
	cx?: number,
	cy?: number,
	value?: string | number,
	stroke: string,
	fill: string
}) =>
	value && <circle r={3} {...props} /> || null

const ContentChart = ({
	chartType,
	datasets,
	label,
	value
}: CustomChartType) => {
	const theme = useTheme();
	switch (chartType.toLowerCase()) {
		case "line":
			const fixedDataSet = datasets.map((d: any) => ({
				...d,
			}));
			return <GraphContainer>
				<ResponsiveContainer>
					<LineChart data={fixedDataSet}>
						<Line
							type="linear"
							connectNulls={true}
							dataKey={value.code}
							stroke={theme.colors.gray[200]}
							dot={<Dot
								stroke={theme.colors.gray[200]}
								fill={theme.colors.gray[300]}
							/>}
						/>
					</LineChart>
				</ResponsiveContainer>
			</GraphContainer>
		case "bar":
			return <GraphContainer>
				<ResponsiveContainer>
					<BarChart data={datasets}>
						<Bar dataKey={value.code} fill={theme.colors.gray[200]} rx={"1rem"}>
							{datasets.map((entry, index) => (
								<Cell key={`cell-${index}`} radius={2} width={6} />
							))}
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			</GraphContainer>
		default:
			return null
	}
}

export default TopStats;
