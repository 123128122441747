import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { useQuery } from 'react-query';
import { getUser } from '../../api/user';
import { reducer, SET_PROPS, SET_STATUS } from './reducer';

import { User } from "../../types/user";
import { useApplication } from '../application';

export const initialState: User = {
    id: null,
    email: '',
    name: '',
    role: null,
    registrationCompleted: false,
    status: 'IDLE',
    token: null,
    photo: null,
};

const UserContext = createContext<any>({});

type Props = {
    id: string | null,
    children: ReactNode,
}

const UserProvider = ({ children }: Props) => {
    const application = useApplication();
    const [state, dispatch] = useReducer(reducer, initialState);

    const { data, isFetching, isFetched, refetch } = useQuery("get-user", getUser, {
        refetchOnWindowFocus: false,
        enabled: false,
        suspense: false,
        useErrorBoundary: true,
    });

    useEffect(() => {
        if (state.token && state.status === 'IDLE' && !isFetching) {
            dispatch({ type: SET_STATUS, payload: 'PENDING' });
            refetch();
        }
    }, [state]);

    useEffect(() => {
        if (isFetched && data?.data) {
            const { userId, isRegistrationCompleted, email, displayName } = data?.data;
            dispatch({
                type: SET_PROPS, payload: {
                    id: userId,
                    email,
                    name: displayName,
                    registrationCompleted: isRegistrationCompleted,
                }
            });
            dispatch({ type: SET_STATUS, payload: 'RESOLVED' });
            application.setLoaderQueueResolved("USER");

            if (window.vuplex) {
                window.vuplex.postMessage('UNITY_TEST');
                window.vuplex.postMessage({
                    token: window.localStorage.getItem('accessToken'),
                    name: displayName,
                    userId,
                });
            } else {
                window.addEventListener('vuplexready', () => {
                    if (window.vuplex) {
                        window.vuplex.postMessage({
                            token: window.localStorage.getItem('accessToken'),
                            name: displayName,
                            userId,
                        });
                    }

                });
            }
        }
    }, [isFetched, data]);

    return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>
}

function useUser() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}

export { UserProvider, useUser };
