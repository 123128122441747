import { IconProps } from "../../types/icon";

const ArrowDown = ({ width = 14, height = 9, fill = "#F2F2F2" }:IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 14 9`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd" d="M0.459641 0.792893C0.850166 0.402369 1.48333 0.402369 1.87385 0.792893L7.00008 5.91912L12.1263 0.792893C12.5168 0.402369 13.15 0.402369 13.5405 0.792893C13.931 1.18342 13.931 1.81658 13.5405 2.20711L7.70719 8.04044C7.51965 8.22798 7.2653 8.33333 7.00008 8.33333C6.73486 8.33333 6.48051 8.22798 6.29297 8.04044L0.459641 2.20711C0.069117 1.81658 0.069117 1.18342 0.459641 0.792893Z"
            fill={fill} />
    </svg>

export default ArrowDown;