import { useIntl } from "react-intl";
import {
	Bar,
	BarChart,
	Legend, ResponsiveContainer, Tooltip, XAxis
} from "recharts";
import { AttributesProps, ChartPoint, LINES_COLORS } from "./index";
import RenderLegend from "./Legend";
import { ChartWrapper } from "./score.styles";

const ScoreText = ({ point, fill }: { point: ChartPoint; fill: string }) => (
	<text
		fill={fill}
		x={point.x + ((point.width && point.width / 2) || 0)}
		y={point.y - 4}
		textAnchor="middle"
		fontSize="1rem"
	>
		{point.value}
	</text>
);

const BarC = ({ attributes }: AttributesProps) => {
	const intl = useIntl();
	return (
		<ChartWrapper flex={1.5}>
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					width={100}
					height={100}
					cx="50%"
					cy="50%"
					outerRadius="80%"
					data={attributes}
				>

					<Tooltip />
					<XAxis
						dataKey="name"
						fontSize={10}
						tick={{ fill: "#fff" }} />

					<Legend content={RenderLegend} />

					<Bar
						name={intl.formatMessage({ id: "session-detail:score-achieved", defaultMessage: "Score Achieved" })}
						dataKey="scoreAchieved"
						fill={LINES_COLORS.scoreAchieved}
						label={(point: ChartPoint) => (
							<ScoreText point={point} fill={LINES_COLORS.scoreAchieved} />
						)} />
					<Bar
						name={intl.formatMessage({ id: "session-detail:expected-score", defaultMessage: "Expected Score" })}
						dataKey="expectedScore"
						fill={LINES_COLORS.expectedScore}
						label={(point: ChartPoint) => <ScoreText point={point} fill={LINES_COLORS.expectedScore} />} />
				</BarChart>
			</ResponsiveContainer>
		</ChartWrapper>
	);
}

export default BarC;
