import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/ui/button";

const itemHeight = '2.5rem';

export const Container = styled.div`
    position: relative;
    margin-left: 1rem;
`;

export const Toggle = styled(Button)`
    height: ${itemHeight};
    border-radius: 1.25rem;
    border: 1px solid ${({ theme }) => theme.colors.gray[50]};
    width: 10.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

export const PopUp = styled.div<{ width: string | number }>`
    position: absolute;
    top: calc(100% + 0.3rem);
    right: 0;
    width: ${({ width }) => width};
    border: 1px solid ${({ theme }) => theme.colors.gray[100]};
    border-radius: 0.3rem;
    box-shadow: 0 0 8px 2px ${({ theme }) => theme.colors.gray[250]};
    background-color: ${({ theme }) => theme.colors.gray[300]};
`;

export const DateFilterPopUp = styled(PopUp)`
    @media (max-width:768px) {
        right: 0;
        width: 24rem;
        overflow-x: scroll;
    }
`;

export const TraineesPopUp = styled(PopUp)`
    @media (max-width:768px) {
        right: initial;
        left: 0;
    }
`;

export const ListContainer = styled.div`
    height: 14rem;
    overflow-x: hidden;
    border-radius: 0 0 0.3rem 0.3rem;
    &::-webkit-scrollbar {
        width: 0.4rem;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.2rem;
    }
`;

export const Search = styled.div`
    height: ${itemHeight};
    display: flex;
    padding-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    background-color:${({ theme }) => theme.colors.gray[200]};
    border-radius: 0.3rem 0.3rem 0 0;

    & input {
        background-color: transparent;
        border: none;
        color: ${({ theme }) => theme.colors.gray[50]};
        outline: none;
        padding: 0.4rem 1rem;
    }

    path {
        fill: ${({ theme }) => theme.colors.gray[100]};
    }
`;

export const AllTrainees = styled(NavLink) <{ selected: boolean }>`
    height: ${itemHeight};
    display: flex;
    padding:0 1rem;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    text-decoration: none;
    cursor: pointer;
    color: inherit;
    ${({ theme, selected }) => selected && `
        color: ${theme.colors.primary.yellow[400]};
        background-color:${theme.colors.primary.yellow[900]}`};


    &:hover {
        ${({ theme, selected }) => selected && `color:${theme.colors.primary.yellow[300]}`};
        background-color: ${({ theme, selected }) => selected ? theme.colors.primary.yellow[800] : theme.colors.gray[200]};
    }

    path {
        fill: ${({ theme }) => theme.colors.primary.yellow[400]};
    }
`;

export const List = styled.ul`
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    z-index: 99;
`;

type ItemProps = {
    isSearch?: boolean,
    selected?: boolean,
}

export const Item = styled.li<ItemProps>`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1rem;
    height: ${itemHeight};
    font-size: 1rem;
    font-weight: 500;
    ${({ theme, isSearch }) => isSearch && `background-color:${theme.colors.gray[200]}`};
    ${({ theme, selected }) => selected && `
        background-color:${theme.colors.primary.yellow[900]}`};

    .name {
        ${({ theme, selected }) => selected && `color:${theme.colors.primary.yellow[300]}`};
    }

    &:hover {
        background-color: ${({ theme, selected }) => selected ? theme.colors.primary.yellow[800] : theme.colors.gray[200]};
        .name {
            ${({ theme, selected }) => selected && `color:${theme.colors.primary.yellow[300]}`};
        }
    }

    path {
        ${({ theme, selected }) => selected && `fill:${theme.colors.primary.yellow[400]}`};
    }
`;



export const Link = styled(NavLink)`
    text-decoration: none;
    color: inherit;
`;

export const AvatarContainer = styled.div`
    margin-right: 1rem;
`;

export const Name = styled.div`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
`;

export const LoaderContainer = styled.li`
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${({ theme }) => theme.colors.gray[250]};
    }
`;

export const LoadMore = styled.a`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color:  ${({ theme }) => theme.colors.gray[100]};
    font-size: 0.9rem;
`;