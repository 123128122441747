import { ReactNode } from "react";
import { Session, SessionEntry } from "../../../../../types/session";
import { sortAlpha } from "../../../../../utils/sort";
import { DAY_MS, FlowProps } from "../Chart";

type OwnProps = {}

type Props = FlowProps & OwnProps;

export const VerticalFlowChart = ({ data, startDate, settings, onItemClick }: Props) => {

    const { rowHeight, columnWidth, barSize, barGap, padding, xLabelGap, lineColor } = settings;

    const sessions = data.reduce((acc: Session[], { scenarios }: SessionEntry) => { acc.push(...scenarios); return acc; }, [])
    const scenarios = (sessions || []).reduce((acc: string[], { scenario }: any) => { if (acc.indexOf(scenario) < 0) acc.push(scenario); return acc; }, []).sort(sortAlpha);

    const sessionsByDay = data.reduce((acc: any, { id, scenarios }: SessionEntry) => {
        scenarios.forEach((session: Session) => {
            const { from } = session;
            const d = new Date(from)
            const dayTime = d.getTime() + (d.getTimezoneOffset() * 60 * 1000);
            const minTime = startDate.getTime();

            const day = Math.floor((dayTime - minTime) / DAY_MS);

            if (!acc.hasOwnProperty(day)) {
                acc[day] = {}
            }

            if (!acc[day].hasOwnProperty(id)) {
                acc[day][id] = [];
            }

            acc[day][id].push(session);
        });

        return acc;
    }, {});

    const ordererdSessionsByDay = Object.keys(sessionsByDay).reduce((acc: any, day: string) => {
        const dayData = sessionsByDay[day];
        acc[day] = Object.keys(dayData).map((v, i) => dayData[v].map(({ scenario }: Session) => scenario).sort(sortAlpha));
        return acc
    }, {});

    return <>
        {Object.keys(ordererdSessionsByDay).map((key: string) => {
            const day: number = parseInt(key);
            const daySessions: [] = ordererdSessionsByDay[day];

            const clips: ReactNode[] = [];
            const offset = (columnWidth / 2) - (daySessions.length > 1 ? (((daySessions.length - 1) * (barGap + barSize))) : (barSize / 2));

            return <g id={`day-${day}`} transform={`translate(${(columnWidth * day) + padding + xLabelGap} 0)`}>
                <g transform={`translate(${offset} 0)`}>
                    {daySessions.map((values: string[], i: number) => {

                        const allScenarios = scenarios.map((sc, i) => values.includes(sc))
                        clips.push(
                            <clipPath id={`clip-day-${day}-session-${i}`}>
                                {allScenarios.map((exists: boolean, scenarioIndex: number) =>
                                    exists && <rect
                                        fill="white"
                                        rx={barSize / 2}
                                        x={i * (barGap + barSize)}
                                        y={(padding + (scenarioIndex * rowHeight)) - (rowHeight / 2) - (barSize / 2)}
                                        width={barSize}
                                        height={rowHeight + (barSize)}></rect>
                                )}
                            </clipPath>
                        )

                        return <>
                            {allScenarios.map((exists: boolean, scenarioIndex: number) => {
                                return !exists && allScenarios.filter((v, i) => i < scenarioIndex).some((v) => v === true) && !allScenarios.filter((v, i) => i > scenarioIndex).every((v) => v === false) &&
                                    <line
                                        stroke={lineColor}
                                        x1={i * (barGap + barSize) + (barSize / 2)}
                                        y1={(scenarioIndex * rowHeight) + (rowHeight / 2)}
                                        x2={i * (barGap + barSize) + (barSize / 2)}
                                        y2={(scenarioIndex * rowHeight) + (rowHeight * 1.5)} />
                            })}
                            <rect
                                clipPath={`url(#clip-day-${day}-session-${i})`}
                                fill={`url(#gradient-${settings.name})`}
                                x={i * (barGap + barSize)}
                                y={0}
                                width={barSize}
                                height={rowHeight * (scenarios.length - 1) + (padding * 2)} />
                        </>
                    })}
                    {clips}
                </g>
            </g>
        })}
    </>
}