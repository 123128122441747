import { FormattedMessage } from "react-intl";
import Button from "../../../../components/ui/button";
import { Container } from "./footer.styles";

type Props = {
    onClickSave: () => void,
    dirty: boolean
}

const Footer = ({ onClickSave, dirty }: Props) => {
    return <Container>
        <Button onClick={onClickSave} disabled={!dirty} variant="yellow">
            <FormattedMessage id="settings:save-changes" />
        </Button>
    </Container>
}

export default Footer;