import { IconProps } from "../../types/icon";

const Question = ({ width = 16, height = 16, fill = "#FFFFFF" }: IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 16 16`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.6 0.4C15.3333 0.133333 15.0222 0 14.6667 0H1.33333C0.977778 0 0.666667 0.133333 0.4 0.4C0.133333 0.666667 0 0.977778 0 1.33333V14.6667C0 15.0222 0.133333 15.3333 0.4 15.6C0.666667 15.8667 0.977778 16 1.33333 16H14.6667C15.0222 16 15.3333 15.8667 15.6 15.6C15.8667 15.3333 16 15.0222 16 14.6667V1.33333C16 0.977778 15.8667 0.666667 15.6 0.4ZM7.08889 12.3556C7.08889 12.12 7.16889 11.92 7.33333 11.7556C7.49778 11.5911 7.69778 11.5111 7.93333 11.5111C8.16889 11.5111 8.36889 11.5911 8.53333 11.7556C8.69778 11.92 8.77778 12.12 8.77778 12.3556C8.77778 12.5911 8.69778 12.7911 8.53333 12.9556C8.36889 13.12 8.16889 13.2 7.93333 13.2C7.69778 13.2 7.49778 13.12 7.33333 12.9556C7.16889 12.7911 7.08889 12.5911 7.08889 12.3556ZM10.4889 6.47556C10.2978 6.85333 9.96445 7.28 9.48889 7.75556C9.19111 8.05333 8.96889 8.31556 8.81333 8.54222C8.65778 8.77333 8.55556 9 8.51556 9.22222C8.47556 9.41778 8.39556 9.58222 8.26667 9.71111C8.14222 9.84444 7.98667 9.91111 7.80444 9.91111C7.63556 9.91111 7.49778 9.84445 7.37778 9.71556C7.25778 9.58667 7.20444 9.42667 7.20444 9.24444C7.20444 8.94667 7.31111 8.61778 7.52444 8.25778C7.73778 7.89778 8.05333 7.51556 8.46667 7.11111C8.83556 6.76889 9.10222 6.45778 9.26667 6.17778C9.43111 5.89778 9.51111 5.60889 9.51111 5.31111C9.51111 4.91111 9.37333 4.59556 9.10222 4.36889C8.83111 4.14222 8.47111 4.02222 8.02222 4.02222C7.69778 4.02222 7.39556 4.08 7.12444 4.2C6.84889 4.32 6.6 4.48889 6.37778 4.71111C6.24444 4.84444 6.09333 4.95111 5.92444 5.03556C5.75556 5.11556 5.58667 5.12 5.42667 5.04444C5.26222 4.96889 5.16 4.85778 5.11556 4.70222C5.07111 4.54667 5.08444 4.38667 5.16 4.22222C5.32444 3.80889 5.68889 3.45778 6.26222 3.17778C6.83111 2.89778 7.44889 2.75556 8.11556 2.75556C8.92889 2.75556 9.57778 2.97333 10.0622 3.40889C10.5467 3.84444 10.7822 4.44889 10.7822 5.22222C10.7822 5.68 10.6844 6.09778 10.4933 6.48L10.4889 6.47556Z"
            fill={fill} />
    </svg>


export default Question;
