import moment from "moment";

import { useUser } from "../../../../context/user";
import useQueryParams from "../../../../utils/use-query-params";
import MinutesSpent from "../../../shared/minutes-spent";

import { Container } from "./minutes-spent-training.style";

type Props = { from: string; to: string };

export const MinutesSpentTraining = ({ from, to }: Props) => {
  const { state: user } = useUser();
  const params: any = useQueryParams();

  const userId = user.id || params.get("userId");

  return (
    <Container>
      <MinutesSpent
        userId={userId}
        from={moment(from).format("yyyy-MM-DD")}
        to={moment(to).format("yyyy-MM-DD")}
      />
    </Container>
  );
};
