import styled from "styled-components";

export const FormStepContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 6rem;
    box-sizing: border-box;

    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
        padding: 0 1rem;
    }

    @media only screen and (max-width: 767px) {
        padding: 0;
    }
`;

export const FormRow = styled.div`
    margin: 0.5rem 0 1.5rem 0;
    width: 100%;
    float: left;

    &:last-of-type {
        margin-bottom: 0;
    }
`;