import { useQuery } from "react-query";
import { getDashboardTopStats } from "../../../../api/dashboard";
import { useDashboardFilters } from "../../../../context/dashboard-filters";
import { TopStatsResponse } from "../../../../types/top-stats";
import TopStats from "../../../shared/top-stats";

type DashboardTopStatsProps = {
	max: number
};

export const DashboardTopStats = ({ max }: DashboardTopStatsProps) => {
	const { state: { userId, from, to } } = useDashboardFilters();

	const { isLoading, data } =
		useQuery<TopStatsResponse>(
			["getDashboardTopStats", userId, from, to],
			() => getDashboardTopStats({ userId, from, to }),
			{
				suspense: false,
				refetchOnMount: false,
				useErrorBoundary: true,
				enabled: true,
			});

	return <TopStats
		data={!isLoading && data || []}
		loading={isLoading}
		max={max}
	/>
}

export default DashboardTopStats;