import moment, { Moment } from "moment";

type GridProps = {
    isDaily: boolean,
    totalDays: number, 
    padding?: number,
    scenarios: string[],
    interval: number,
    showHours: boolean,
    intervalHours: number,
    rowHeight: number,
    columnWidth: number,
    offsetX: number,
    offsetY: number,
    startDate: Date,
    endDate: Date,
    dateFormat: string,
    lineColor: string,
    showVerticalLines?: boolean,
    showHorizontalLines?: boolean,
    orientation: string,
    scrollLeft: number
}   

export const Grid = ({
    totalDays,
    padding = 20, 
    scenarios, 
    interval, 
    showHours,
    intervalHours,
    rowHeight, 
    columnWidth, 
    offsetX,
    offsetY,
    startDate,
    dateFormat,
    lineColor,
    showVerticalLines = true,
    showHorizontalLines = true,
    orientation,
}: GridProps) => 
    <>
        <g className="grid">
            

            {/*HORIZONTAL LINE*/}
            {showHorizontalLines && scenarios.map((name: string, index: number) => {
                const y = Math.round(index* rowHeight) + padding;
                return <line 
                            key={`hline-${name}-${index}`} 
                            strokeDasharray="3" 
                            strokeWidth="0.8" 
                            x1={`${offsetX + padding}`} 
                            y1={y} 
                            x2={`${offsetX + padding + (columnWidth * totalDays)}`}
                            y2={y}
                            stroke={lineColor} />
            })}

            {/*VERTICAL LINES*/}
            {Array.from({length: totalDays + 1}, (v, i) => i).map((day: number, dayIndex: number) => {
                const x = padding + offsetX + (dayIndex * columnWidth);
                //const now: Moment = moment(startDate).add('days', dayIndex * interval);
                const now: Moment = moment(startDate).add(dayIndex,'days');

                const divisions: number = Math.ceil(24 / intervalHours);
                const hourGap: number = Math.ceil(columnWidth / divisions);

                const label: string = now.format(dateFormat);

                const shouldShow: boolean = dayIndex < totalDays && dayIndex % interval === 0;

                return <>
                    {showHours && orientation !== 'vertical' && Array.from({ length: divisions }, (v, i) => i).map((value: number) => {
                        return  <>
                            {value > 0 && value < divisions && <line key={`h-${dayIndex}-${value}`} strokeDasharray="6" strokeWidth="0.3" stroke={lineColor}  x1={x + (value * hourGap)} x2={x + (value * hourGap)} y1={padding} y2={((scenarios.length - 1) * rowHeight) + padding}></line>}
                            <text key={`ht-${dayIndex}-${value}`} className="x-label" x={x + (value * hourGap) - 18} y={(scenarios.length * rowHeight) + ((offsetY)) + 14}>{moment(now).add(value * intervalHours, 'hours').format(`HH:mm`)}</text>
                            </>
                    })}
                    {showVerticalLines &&  shouldShow && <line key={`h-${dayIndex}`} strokeWidth="1" x1={x} x2={x} y1={padding} y2={((scenarios.length - 1) * rowHeight) + padding} stroke={lineColor} />}
                    {!showHours && shouldShow && <text key={`ht-${dayIndex}`} className="x-label" x={(x - (label.length * 3) + 20) + (orientation === 'vertical' ? (columnWidth / 2) - 26 : 0)} y={(scenarios.length * rowHeight) + ((offsetY)) + 14}>{label}</text>}
                </>
            })}
        </g>
        </>
    
