import { Features } from "../../../enums/features";

export const buildUrl = ( params: {[prop: string]: any}) => {

    const currentParams = new URLSearchParams(window.location.search);

    return `?${[
        ...Object.keys(params).filter((key) => params[key] !== null).map((key: string, index: number ) => {
            let value: any = params[key];

            if(['from','to'].includes(key))
                value = new Date(value).getTime();

            return `${key}=${value}`
        }),
        ...Object.values(Features).filter((param) => currentParams.get(param)).reduce((acc: string[], value: string) => {
            acc.push(`${value}=${currentParams.get(value)}`);
            return acc;
        },[])
    ].join('&')}`;

}