import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { getTopScenariosCompleted } from "../../../api/dashboard";
import BarChart from "../../../components/graphs/bar";
import { FlagIcon } from "../../../components/icons";
import Frame from "../../../components/ui/frame";
import Loader from "../../../components/ui/loader";
import Styled from "../../../components/ui/styled";
import Text from "../../../components/ui/text";
import { useDashboardFilters } from "../../../context/dashboard-filters";
import { useFeatureToggles } from "../../../context/feature-toggles";
import { Features } from "../../../enums/features";
import { ChartContainer, Container, HalfContainer } from "../dashboard.styles";
import { Bullet, Info, Item, List } from "./top-scenarios.styles";


const COLORS = [
  "#F49E37",
  "#FFC887",
  "#FFB55D",
  "#D97D0F",
  "#AA5E03",
].reverse();

const legendContent = (data: any) => (
  <List>
    {data.payload.map(({ payload }: { payload: any }, index: number) => (
      <Item
        key={payload.name}
      >
        <Bullet color={COLORS[index]} />
        <Info>
          <Text variant="medium" fontWeight={700}>{payload.name}</Text>
          <Text>{Math.round((payload.value / 1000) / 60)} <FormattedMessage id="dashboard:minutes" /></Text>
        </Info>
      </Item>
    ))}
  </List>
)

type DataItem = {
  scenarioName: string,
  count: number
}

const TopScenariosCompleted = () => {
  const { state: filters } = useDashboardFilters();
  const { isFeatureActive } = useFeatureToggles();

  const { from, to, userId } = filters;

  const { data, isLoading } = useQuery(["getTopScenarios", userId, from, to], () => getTopScenariosCompleted(filters), {
    suspense: true,
    refetchOnMount: false,
    useErrorBoundary: true,
  });

  const dataset = data?.datasets && data.datasets[0].map(({ scenarioName, count }: DataItem) => ({
    name: scenarioName,
    value: count,
  })) || [];

  const hasData = dataset.length > 0;

  if (!hasData && isFeatureActive(Features.HideEmptyCharts)) {
    return null;
  }

  const max = hasData ? dataset.sort((a: any, b: any) => a.value > b.value)[0].value : 0;

  return <HalfContainer>
    <Frame title={<FormattedMessage id={"dashboard:top-5-completed-scenarios"} />} icon={<FlagIcon />}>
      <Container>
        <ChartContainer empty={!hasData} className="top-completed-scenarios-chart">
          <ErrorBoundary fallback={<span>error</span>}>
            <Suspense fallback={<span>error</span>}>
              {isLoading && <Loader />}
              {hasData &&
                <BarChart
                  data={dataset}
                  domain={[0, max]}
                  ticks={Array.apply(null, Array(max + 1)).map((val, key) => key.toString().padStart(2, '0'))}
                />
                || <Styled color="gray[100]">
                  <FormattedMessage id="dashboard:no-scenarios" />
                </Styled>}
            </Suspense>
          </ErrorBoundary>
        </ChartContainer>
      </Container>
    </Frame>
  </HalfContainer>;
}

export default TopScenariosCompleted;