import { useIntl } from "react-intl";
import { QuestionIcon } from "../../../components/icons";
import Frame from "../../../components/ui/frame";
import { CustomChartType } from "../../../types/custom-charts";
import { ChartContainer, FullContainer } from "../dashboard.styles";
import { NoData } from "./custom-charts";
import QuestionChart from "./question-chart/question-chart";

export default function HorizontalBarSection({ data }: { data: CustomChartType[] }) {
	const intl = useIntl();
	if (!data?.length) return null;

	return (
		<>
			<h2>Air Defence Systems and SAM Stages questions attempts</h2>

			{data.map(({ chartType, code, description, label, value, datasets, legend, ...props }: CustomChartType) => {
				const chartData = datasets || [];
				const hasData = chartData.length > 0;

				return <FullContainer key={`chart-${code}`}>
					<Frame title={intl.formatMessage({ id: `dashboard:${code}`, defaultMessage: description })} icon={<QuestionIcon />}>
						<ChartContainer empty={!hasData}>
							{hasData ? <QuestionChart
								data={chartData}
								interval={props.xAxisInterval}
								value={value}
								legend={legend}
							/> : <NoData />}
						</ChartContainer>
					</Frame>
				</FullContainer>
			})}
		</>
	)
}
