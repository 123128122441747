import moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { getCustomerSettings } from "../../../api/settings";
import { GamepadIcon } from "../../../components/icons";
import { VRIntlProviderComponent } from "../../../components/providers/intl-provider";
import Button from "../../../components/ui/button";
import Loader from "../../../components/ui/loader";
import Styled from "../../../components/ui/styled";
import Text from "../../../components/ui/text";
import { Session } from "../../../types/session";
import { Container, Item } from "./session-list.styles";

type SessionsListProps = {
	data: Session[],
	sessionId: string,
	onItemClick: (params: { session: Session, sessionId: string }) => void,
}

type SessionsListItemProps = {
	image?: string,
	onClick?: () => void
}

const localeFn = (target: string) =>
	import(`./locale/${target.toLowerCase()}.json`);

const SessionsListItem = ({
	from,
	to,
	name,
	image,
	score,
	onClick,
}: Session & SessionsListItemProps) =>
	<Item.Container>
		<Item.Timestamps>
			<Item.Time>
				<Text variant="xsRegular">{moment(from).format("HH:mm")}</Text>
			</Item.Time>
			<Item.Time>
				<Text variant="xsRegular" color="gray.100">{moment(to).format("HH:mm")}</Text>
			</Item.Time>
		</Item.Timestamps>
		<Item.Icon data-icon>
			<GamepadIcon />
		</Item.Icon>
		<Item.ImageContainer>
			{image && <LazyImage url={image} />}
		</Item.ImageContainer>
		<Item.Content>
			<Item.Name><Text variant="medium">{name}</Text></Item.Name>
			<Item.Info>
				<FormattedMessage id={"sessions-list:duration"} /><Duration from={from} to={to} />
				{score && score > 0 ? <>
					<Styled padding={"0 0.3rem"}>•</Styled>
					<FormattedMessage id={"sessions-list:score"} /> {score}
				</> : null}
			</Item.Info>
		</Item.Content>
		<Item.ButtonContainer>
			<Button variant="dark" onClick={onClick}>
				<FormattedMessage id={"sessions-list:see-details"} />
			</Button>
		</Item.ButtonContainer>
	</Item.Container>

const Duration = ({ from, to }: {
	from: string,
	to: string
}) => {
	const diff = moment.duration(moment(to).diff(moment(from)));
	return <Styled marginLeft="0.3rem">{moment.utc(diff.asMilliseconds()).format('HH:mm')}</Styled>;
}

const LazyImage = ({ url }: { url: string }) => {
	const [loaded, setLoaded] = useState<boolean>(false);
	const img = new Image();

	const handleOnLoad = () => setLoaded(true);
	useEffect(() => {
		img.addEventListener('load', handleOnLoad)
		img.src = url;

		return () => {
			img.removeEventListener('load', handleOnLoad);
		}
	}, []);

	return loaded ? <Item.Image style={{ backgroundImage: `url(${url})` }} /> : <Loader size="small" />;
}

const List = ({ data, onItemClick, sessionId }: SessionsListProps) => {
	const options = {
		suspense: false,
		refetchOnMount: false,
		useErrorBoundary: true,
	};
	const { data: d } = useQuery([getCustomerSettings], getCustomerSettings, options);

	return (
		<Container>
			{data.map((session: Session) =>
				<SessionsListItem
					{...session}
					key={session.id || session.scenarioId}
					name={session.name || session.scenario}
					image={d?.scenarioImageUrl || session.image || `${window.location.origin}/assets/default-thumbnail.png`}
					score={session.score}
					onClick={() => {
						onItemClick({
							session,
							sessionId
						})
					}} />
			)}
		</Container>)
}

const SessionsList = (props: SessionsListProps) => (
	<VRIntlProviderComponent
		localeFn={localeFn}
		id="sessions-list"
		fallback={null}
	>
		<List {...props} />
	</VRIntlProviderComponent>
);

export default SessionsList;


