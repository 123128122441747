import styled from "styled-components";
import { StatusType } from "../../../types/shared";

export const Form = styled.form`
  margin-top: 1.2rem;
`;

type FormRowProps = {
  align?: "flex-start" | "center" | "flex-end";
};

export const FormRow = styled.div<FormRowProps>`
  margin-top: 0.8rem;
  display: flex;
  justify-content: ${({ align }) => align || "flex-start"};
`;

export const LoaderContainer = styled.div`
  display: flex;
  margin-left: 1rem;

  & svg path {
    fill: ${({ theme }) => theme.colors.gray[400]};
  }
`;

export const Message = styled.p<{ status: StatusType }>`
  margin-top: 1rem;
  color: ${({ theme, status }) => theme.colors[status]};
`;
