export const SET_SURVEY = 'SET_SURVEY';
export const SET_VALUE = 'SET_VALUE';
export const SET_VALUES = 'SET_VALUES';
export const SET_DIRTY = 'SET_DIRTY';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';

type Payload = {
    type: string,
    payload: any,
}

export const reducer = (state: any, { type, payload }: Payload) => {
    switch (type) {
        case SET_VALUE:
            return {
                ...state,
                values: {
                    ...state.values,
                    [payload.name]: payload.value,
                },
                dirty: 'dirty' in payload ? payload.dirty : true,
            }
        case SET_VALUES:
            return {
                ...state,
                values: {
                    ...payload,
                }
            }
        case SET_DIRTY:
            return {
                ...state,
                dirty: payload
            }
        case SET_SURVEY:
            return {
                ...state,
                survey: {
                    ...payload
                }
            }
        case SET_EDIT_MODE: {
            return {
                ...state,
                editMode: payload,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }
    }
}