import styled from "styled-components";
import { PillVariant, PillSize } from ".";



const Sizes: {
    [prop: string]: string
} = {
    small: `
      line-height: 1.75rem;
      border-radius: 1.25rem;
      font-size: 0.75rem;
      padding: 0 1rem;
      font-weight: 500;
    `,
    medium: `
      line-height: 2.5rem;
      border-radius: 1.25rem;
      font-size: 1rem;
      padding: 0 1.5rem;
      font-weight: 500;
    `,
    large: `
  
    `,
}

type ContainerProps = {
    variant: PillVariant,
    size: PillSize
}

export const Container = styled.span<ContainerProps>`
    ${({ size }) => Sizes[size]};
    background-color: ${({ theme, variant }) => theme.colors.primary[variant][800]};
    border: 1px solid ${({ theme, variant }) => theme.colors.primary[variant][400]};
    color: ${({ theme, variant }) => theme.colors.primary[variant]['text']};
    display: flex;
    algin-items: center;
    justify-content: center;
`;