import { IconProps } from "../../types/icon";

const Eye = ({ width = 16, height = 4, fill = "#FFFFFF" }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0440918 6.70631C1.23398 2.83309 4.78216 0 8.99999 0C13.2178 0 16.766 2.83313 17.9559 6.70637C18.0147 6.89773 18.0147 7.10234 17.9559 7.2937C16.766 11.1669 13.2178 14 9.00001 14C4.78216 14 1.23396 11.1669 0.0440897 7.29363C-0.0146973 7.10227 -0.0146966 6.89767 0.0440918 6.70631ZM9 5C7.89543 5 7 5.89543 7 7C7 8.10457 7.89543 9 9 9C10.1046 9 11 8.10457 11 7C11 5.89543 10.1046 5 9 5ZM5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7Z"
      fill={fill}
    />
  </svg>
);

export default Eye;
