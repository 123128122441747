import styled from "styled-components";
export const Container = styled.div`
    @media (max-width:1024px) {
        margin-top: 1rem;
        margin-right: -3rem;
    }
`;
export const IconContainer = styled.span`
    margin-right: 0.5rem;
    display: inline-flex;
`;