import moment from "moment";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { getSessionData } from "../../../../api/dashboard";
import { ClockIcon } from "../../../../components/icons";
import Frame from "../../../../components/ui/frame";
import Loader from "../../../../components/ui/loader";
import Message from "../../../../components/ui/message";
import { useDashboardFilters } from "../../../../context/dashboard-filters";
import { useFeatureToggles } from "../../../../context/feature-toggles";
import { useUser } from "../../../../context/user";
import { Features } from "../../../../enums/features";
import { Pages } from "../../../../enums/pages";
import { Roles } from "../../../../enums/user";
import { SessionEntry } from "../../../../types/session";
import useQueryParams from "../../../../utils/use-query-params";
import { ChartContainer, FullContainer } from "../../dashboard.styles";
import { ChartSession } from "./Chart";
import { ChartTooltip, Container, Wrapper } from "./session-chart.styles";

const settingsByTime: {
  [prop: string]: any;
} = {
  1: {
    showHours: true,
    intervalHours: 4,
    orientation: "horizontal",
    columnWidth: 1000,
    interval: 1,
    xLabelGap: 320,
  },
  7: {
    interval: 1,
    orientation: "horizontal",
    columnWidth: 400,
    xLabelGap: 400,
  },
  30: {
    orientation: "vertical",
    columnWidth: 100,
    barSize: 5,
    xLabelGap: 320,
    showVerticalLines: false,
  },
};

const defaultSettings = {
  backgroundColor: "#1A1A1A",
  fontFamily: "Roboto, Arial, Helvetica, monospace",
  textSize: 10,
  tooltip: (entry: SessionEntry) => (
    <ChartTooltip.Container>
      <ChartTooltip.Title>{`Session ${entry.name}`}</ChartTooltip.Title>
      {`${moment(entry.scenarios[0].from).format("MMM Do YY, h:mm:ss a")}`}
    </ChartTooltip.Container>
  ),
  tooltipWidth: 220,
  tooltipHeight: 74,
};

type Props = {
  loading?: boolean,
  data: any,
  from: Date,
  to: Date,
  datePreset: string
};

const SessionChart = ({ data, from, to, datePreset, loading = false, }: Props) => {
  const { state: user } = useUser();
  const queryParams: any = useQueryParams();
  const params: any = useParams();
  const history = useHistory();

  const hasNoSelection = from === null && to === null && datePreset === null;

  const diffInDays = hasNoSelection
    ? 7
    : Math.ceil(
      (to.getTime() - from.getTime()) / (1000 * 3600 * 24)
    );

  const settingsKey = Object.keys(settingsByTime)
    .filter((key: string) => parseInt(key) <= diffInDays)
    .pop();
  const allSettings: any = (settingsKey && settingsByTime[settingsKey]) || {};

  const hasData = data.length > 0 || !data;

  const onClickHandler = useCallback(({ id, scenarios }: SessionEntry) => {
    history.push(
      `${Pages.SessionsList.replace(":sessionId", String(id))}?userId=${params.userId
      }&date=${scenarios[0].from
      }&dashboardFrom=${queryParams.get("from")
      }&dashboardTo=${queryParams.get("to")}`
    );
  }, []);

  const onDayClickHandler = useCallback((date: Date) => {
    history.push(
      `${Pages.SessionsList}?userId=${user.role === Roles.Trainee ? user.id : params.userId
      }&date=${moment(date).format("YYYY-MM-DD")
      }&dashboardFrom=${queryParams.get("from")
      }&dashboardTo=${queryParams.get("to")}`
    );
  }, []);

  if (loading) {
    return <Loader />
  }

  return !hasData ? (
    <ChartContainer empty={!hasData}>
      <Message>
        <FormattedMessage id="no-data" />
      </Message>
    </ChartContainer>
  ) : (
    <Wrapper>
      <Container>
        <ChartSession
          onItemClick={onClickHandler}
          onDayClick={onDayClickHandler}
          data={data}
          settings={{ ...defaultSettings, ...allSettings }}
        />
      </Container>
    </Wrapper>
  );
};

const GlobalSessionTimeline = () => {
  const intl = useIntl();
  const { isFeatureActive } = useFeatureToggles();
  const { state: filters } = useDashboardFilters();
  const { state: user } = useUser();
  const params: any = useParams();
  const id = user.role === Roles.Trainee ? user.id : params?.userId;

  const { isLoading, data } = useQuery(
    ["getSessionData", { ...filters, userId: id }],
    () => getSessionData({ ...filters, userId: id }
    )
  );

  if (data && data.length === 0 && isFeatureActive(Features.HideEmptyCharts)) return null;

  return <FullContainer>
    <Frame
      transparent
      title={intl.formatMessage({ id: "dashboard:sessions-timeline" })}
      icon={<ClockIcon />}>
      <SessionChart
        {...filters}
        loading={isLoading}
        data={data} />
    </Frame>
  </FullContainer>


}

export default GlobalSessionTimeline;
