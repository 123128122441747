import { IconProps } from "../../types/icon";

const UserIcon = ({ width = 14, height = 14, fill = "#F2F2F2" }:IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 14 14`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3339 3.74154C10.3339 5.5832 8.84227 7.07487 7.00061 7.07487C5.15894 7.07487 3.66727 5.5832 3.66727 3.74154C3.66727 1.89987 5.15894 0.408203 7.00061 0.408203C8.84227 0.408203 10.3339 1.89987 10.3339 3.74154ZM0.333984 12.0749C0.333984 9.85821 4.77565 8.74154 7.00065 8.74154C9.22565 8.74154 13.6673 9.85821 13.6673 12.0749V13.7415H0.333984V12.0749Z"
            fill={fill} />
    </svg>

export default UserIcon;