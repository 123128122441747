import { Division, Divisions, Input, Label, Wrapper } from "./classify.styles";

type Props = {
    label: string,
    name: string,
    value?: number,
    required?: boolean,
    from: number | string,
    to: number | string,
    step?: number,
    divisions?: number,
    onChange: (name: string, value: number) => void,
}

const Classify = ({
    label,
    name,
    value,
    required = false,
    from,
    to,
    step = 1,
    divisions,
    onChange
}: Props) => {

    return <Wrapper>
        <Label>{label}</Label>
        {divisions && divisions > 1 && <Divisions>
            {Array.from(
                { length: divisions },
                (value, index) => index
            ).map((index) => <Division key={`name-${index}`}>{index + 1}</Division>)}
        </Divisions>}
        <Input
            step={step}
            min={from}
            max={to}
            value={value}
            onChange={(e) => {
                onChange && onChange(name, Number(e.target.value));
            }}
            type="range"
        />
    </Wrapper>
}

export default Classify;