import { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import _ from "lodash";
import { getWelcomeSurvey } from "../../../api/user";
import { useIntl } from "react-intl";
import { useUser } from "../../../context/user";
import { Roles } from "../../../enums/user";

export const context = createContext();

export const useWelcomeSurveyData = () => useContext(context);

function reducer(state, action) {
    switch (action.type) {
        case "NEXT":
            if (state.currentStep < state.totalSteps - 1) {
                return { ...state, currentStep: state.currentStep + 1 };
            }
            return state;
        case "PASS_WELCOME":
            return { ...state, isMobileWelcomePage: false };

        case "PREVIOUS":
            if (state.currentStep >= 1) {
                return { ...state, currentStep: state.currentStep - 1 };
            }
            return state;
        case "PENDING":
            return {
                ...state,
                status: "PENDING",
            }
        case "COMPLETED":
            return {
                ...state,
                status: "COMPLETED",
            }

        case "SET_FIELDS":
            return {
                ...state,
                fields: action.payload
            }
        case "SET_TOTAL_STEPS":
            return {
                ...state,
                totalSteps: action.payload
            }
        case "SET_FIELD_VALUE":
            return {
                ...state,
                values: { ...state.values, [action.payload.name]: action.payload.value }
            }
        case "SET_ERRORS":
            return {
                ...state,
                errors: action.payload
            }
        case "SET_SUBMIT_ERROR":
            return {
                ...state,
                error: action.payload
            }
        case "SET_FIRST_STEP_ERROR":
            return {
                ...state,
                firstStepInfo: {
                    ...state.firstStepInfo,
                    error: action.payload
                }
            }
        case "UPDATE_DISPLAY_NAME":
            return {
                ...state,
                firstStepInfo: {
                    ...state.firstStepInfo,
                    displayName: action.payload
                }
            }
        case "SET_SKIP_SURVEY":
            return {
                ...state,
                skipSurvey: true
            }
    }
}

export const WelcomeSurveyProvider = ({ children }) => {
    const { state: user } = useUser();
    const isInstructor = user.type === Roles.Instructor;

    const [state, dispatch] = useReducer(reducer, {
        currentStep: 0,
        totalSteps: 0,
        status: 'WAITING',
        fields: null,
        values: {},
        errors: {},
        firstStepInfo: {
            displayName: user.name,
            error: null
        },
        error: "",
        skipSurvey: user.role === Roles.Instructor,
    });

    useEffect(async () => {
        const response = await getWelcomeSurvey();
        const length = response.sections?.length || 0;

        dispatch({ type: 'SET_TOTAL_STEPS', payload: isInstructor ? 1 : length + 1 });
        dispatch({ type: 'SET_FIELDS', payload: response });
        if (length === 0) {
            dispatch({ type: 'SET_SKIP_SURVEY' });
        }
    }, []);

    return (
        <context.Provider
            value={{
                state: {
                    ...state,
                    hasPrevious: state.currentStep >= 1,
                    hasNext: state.currentStep < state.totalSteps - 1,
                },
                dispatch,
            }}
        >
            {children}
        </context.Provider>
    );
};