
import { ReactNode } from "react";
import { Content, Header, IconWrapper, Section, TextContainer } from "./frame.styles";

type Props = {
  children: ReactNode,
  title: string | ReactNode,
  icon: ReactNode,
  transparent?: boolean,
  radius?: number | string
}

const Frame = ({ children, title, icon: Icon, transparent = false, radius = 0 }: Props) => {
  return (
    <Section>
      {title &&
        <Header>
          {Icon && <IconWrapper>{Icon}</IconWrapper>}
          <TextContainer>
            {title}
          </TextContainer>
        </Header>}
      <Content transparent={transparent} radius={radius}>
        {children}
      </Content>
    </Section>
  );
};

export default Frame;
