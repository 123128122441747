import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    @media (max-width: 767px){
        display: block;
        height: auto;
    }
`;

export const LeftColumn = styled.div`
    flex: 0.7;
    height: 28rem;
    min-width: 0;
    @media (max-width: 767px){
        height: 14rem;
        flex: 1;
        margin-bottom: 2rem;
    }
`;

export const RightColumn = styled.div`
    display: flex;
    flex: 0.3;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px){
        flex: 1;
    }
`;