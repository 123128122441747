import { Suspense } from "react";
import { IntlProvider, useIntl } from "react-intl";
import { useQuery } from "react-query";

import getSupportedLocale from "../../../utils/i18n/getSupportedLocale";

const locale = getSupportedLocale();

const VRIntlProvider = ({ children }) => {
  const { data: messages } = useQuery(
    ["translations", locale],
    ({ queryKey: [, target] }) =>
      import(`../../../locale/${target.toLocaleLowerCase()}.json`),
    {
      suspense: true,
      keepPreviousData: true,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return (
    <IntlProvider messages={messages} locale={locale}>
      {children}
    </IntlProvider>
  );
};

const VRIntlProviderSubSet = ({ children, id, localeFn }) => {
  const { data: newMessages } = useQuery(
    ["translations", id, locale],
    ({ queryKey: [, , target] }) => localeFn(target),
    {
      suspense: true,
      keepPreviousData: true,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  const { messages } = useIntl();

  return (
    <IntlProvider messages={{ ...messages, ...newMessages }} locale={locale} defaultLocale="en">
      {children}
    </IntlProvider>
  );
};

export const VRIntlProviderComponent = ({ fallback, ...props }) => (
  <Suspense fallback={fallback || null}>
    <VRIntlProviderSubSet {...props} />
  </Suspense>
);

const VRIntlSuspender = ({ fallback, ...props }) => (
  <Suspense fallback={fallback || null}>
    <VRIntlProvider {...props} />
  </Suspense>
);
export default VRIntlSuspender;
