import styled from "styled-components";

export const Container = styled.div`
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    height: 100%;

    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
        width: 100%;
        padding: 0 1rem;
        box-sizing: border-box;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }
`;

export const AvatarContainer = styled.div`
    margin-bottom: 2rem;
`;

export const Footer = styled.div`
    margin-top: 4rem;
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;text-align: center;
`;
