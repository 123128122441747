import styled from "styled-components";

export const Container = styled.div`
	background-color: ${({ theme }) => theme.colors.primary.dark["800"]};
	border-radius: 0.5rem;
	margin-bottom: 1rem;
`;


export const Item = {
	Container: styled.div`
		display: flex;
		border-radius: 0.5rem;
		padding: 1rem;
		overflow: hidden;

		&:first-of-type [data-icon]:before {
			top: 1rem;
		}

		&:last-of-type [data-icon]:before {
			height: 1rem;
			top: -1rem;
		}
	`,
	Content: styled.div`
		padding-left: 1rem;
		flex: 1;
	`,
	Timestamps: styled.div`
		display: flex;
		flex-direction: column;
		padding-right: 1rem;
	`,
	Time: styled.time`
		margin-bottom: -0.3rem;
	`,
	Icon: styled.div`
		width: 1.75rem;
		height: 1.75rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${({ theme }) => theme.colors.gray["200"]};
		border-radius: 50%;
		position: relative;
		margin: 0 1rem;

		&:before {
			content: "";
			position: absolute;
			width:0;
			height: 100rem;
			border-right: 1px solid  ${({ theme }) => theme.colors.gray["200"]};
		}

		& svg {
			z-index: 100;
		}

	`,
	Name: styled.h3`
		margin: 0 0 1rem 0;
	`,
	ImageContainer: styled.div`
		width: 5rem;
		height: 3.75rem;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
	Image: styled.div`
		width: 100%;
		height: 100%;
		background-size: cover;
	`,
	Info: styled.div`
		display: flex;
		color: ${({ theme }) => theme.colors.gray[100]};
		font-size: 0.875rem;
	`,
	ButtonContainer: styled.div``
}