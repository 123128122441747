import { IconProps } from "../../types/icon";

const Refresh = ({ width = 14, height = 16, fill = "#F2F2F2" }: IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox={`0 0 14 16`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.23948 5.72259C7.56492 6.04802 8.09255 6.04802 8.41799 5.72259C8.74343 5.39715 8.74343 4.86951 8.41799 4.54408L7.56744 3.69353C10.1541 3.97593 12.167 6.16761 12.167 8.82942C12.167 11.6829 9.8538 13.9961 7.00033 13.9961C4.14685 13.9961 1.83366 11.6829 1.83366 8.82942C1.83366 8.36919 1.46056 7.99609 1.00033 7.99609C0.540088 7.99609 0.166992 8.36919 0.166992 8.82942C0.166992 12.6034 3.22638 15.6628 7.00033 15.6628C10.7743 15.6628 13.8337 12.6034 13.8337 8.82942C13.8337 5.34306 11.2228 2.4665 7.84959 2.04835L8.41799 1.47995C8.74343 1.15451 8.74343 0.626872 8.41799 0.301435C8.09255 -0.0240014 7.56492 -0.0240014 7.23948 0.301435L5.11816 2.42276C4.79272 2.74819 4.79272 3.27583 5.11816 3.60127L7.23948 5.72259Z"
            fill={fill} />
    </svg>

export default Refresh;