import { ReactNode } from "react";
import styled from "styled-components";

const Component = styled.a<{
}>`
    color: ${({ theme }) => theme.colors.primary.yellow[400]};
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
`;

const Link = ({ disabled = false, children, variant, onClick, ...props }: {
    children: ReactNode,
    onClick?: (e: any) => void | undefined,
    [prop: string]: any
}) => <Component
    onClick={onClick}
    {...props}>
        {children}
    </Component>;

export default Link;