import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { CalendarIcon, ClockIcon, StarIcon, TrendingIcon } from "../../components/icons";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Frame from "../../components/ui/frame";
import Page, { PageLoader } from "../../components/ui/page";
import { useUser } from "../../context/user";
import { Pages } from "../../enums/pages";
import { Roles } from "../../enums/user";
import useQueryParams from "../../utils/use-query-params";
import { Highlights } from "./components/highlights";
import LatestActivity from "./components/latest-activity";
import { MinutesSpentTraining } from "./components/minutes-spent-training";
import TopStats from "./components/top-stats";
import { Container } from "./sessions-overview.style";

const localeFn = (target: string) =>
  import(`./locale/${target.toLowerCase()}.json`);

type Props = {};

const SessionsOverview = (props: Props) => {
  const { state: user } = useUser();
  const intl = useIntl();
  const history = useHistory();
  const params: any = useQueryParams();
  const name = params.get("name");
  const from = params.get("from"); // A new request must be done with the integration with the BE to get this data
  const to = params.get("to"); // A new request must be done with the integration with the BE to get this data

  const handleOnClickBack = () => {
    history.push(
      `${user.role === Roles.Instructor
        ? (Pages.DashboardTrainee.replace(":userId", params.get("userId")))
        : Pages.Dashboard}`);
  };

  const title = name || intl.formatMessage({ id: "sessions-overview:title" });

  return (
    <Page
      title={title}
      hasBackLink={true}
      backLabel={intl.formatMessage({ id: "sessions-overview:back-label" })}
      onBackClick={handleOnClickBack}
    >
      <Container>
        <Frame
          transparent={true}
          radius="0.6rem"
          title={intl.formatMessage({
            id: "sessions-overview:highlights",
          })}
          icon={<StarIcon />}
        >
          <Highlights />
        </Frame>

        <Frame
          transparent={true}
          title={intl.formatMessage({ id: "top-stats" })}
          icon={<TrendingIcon />}
        >
          <TopStats />
        </Frame>

        <Frame
          radius="0.6rem"
          title={intl.formatMessage({
            id: "sessions-overview:latest-activity",
          })}
          icon={<CalendarIcon />}
        >
          <LatestActivity />
        </Frame>

        <Frame
          transparent={true}
          radius="0.6rem"
          title={intl.formatMessage({
            id: "sessions-overview:minutes-spent-training",
          })}
          icon={<ClockIcon />}
        >
          <MinutesSpentTraining from={from} to={to} />
        </Frame>
      </Container>
    </Page>
  );
};

const SessionsOverviewPage = (props: Props) => (
  <VRIntlProviderComponent
    localeFn={localeFn}
    id="sessions-overview"
    fallback={<PageLoader />}
  >
    <SessionsOverview {...props} />
  </VRIntlProviderComponent>
);

export default SessionsOverviewPage;
