import client from "../../client";

export const sendContactForm = async (fields) => {
    const { data } = await client.post(`v1/support/contact-support`, {
        contactSupportDto: {
            ...fields,
            date: new Date().toISOString(),
        }
    });
    return data;
}