import axios from "axios";

const baseURL = 'https://app-vrai-heat-dev.azurewebsites.net/';
//const baseURL = 'https://hc-neu-dev-dash-analytics-consumer-api.azurewebsites.net/';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 40000,
});

instance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${window.localStorage.getItem('accessToken')}`;
    return config;
});

export default instance;
