import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DashboardFilter } from '../../enums/dashboard';
import { Roles } from '../../enums/user';
import { useQuery } from '../../utils/use-query-params';
import { useUser } from '../user';
import { reducer, SET_FILTERS } from './reducer';


export type DashboardFiltersType = {
    from: string | Date,
    to: string | Date,
    userId: string,
    datePreset: string
}

export const initialState = {
    from: null,
    to: null,
    userId: null,
    datePreset: null,
};

const DashboardFiltersContext = createContext<any>({});

type Props = {
    id?: string | null,
    children: ReactNode,
}

const PARAM_FILTERS = [DashboardFilter.UserId];
const QUERY_FILTERS = [DashboardFilter.From, DashboardFilter.To];


const DashboardFiltersProvider = ({ children, id = null }: Props) => {
    const { state: user } = useUser();
    const [paramsInitialized, setParamsInitialized] = useState(false);
    const [queryInitialized, setQueryInitialized] = useState(false);
    let params: any = useParams();
    let query: URLSearchParams = useQuery();
    const from = query.get('from');
    const to = query.get('to');

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        from: from && new Date(parseInt(from)) || null,
        to: to && new Date(parseInt(to)) || null,
        userId: user.role === Roles.Trainee ? user.id : null,
        datePreset: null,
    });

    useEffect(() => {
        setQueryInitialized(true);
        dispatch({
            type: SET_FILTERS,
            payload: QUERY_FILTERS.reduce((acc: any, key) => {
                const param: any = query.get(key);

                if (param) {
                    switch (key) {
                        case DashboardFilter.From:
                        case DashboardFilter.To:
                            acc[key] = new Date(parseInt(param));
                            break;
                        default:
                            acc[key] = param;
                    }
                }

                return acc;
            }, {})
        });
    }, [query]);

    useEffect(() => {
        setParamsInitialized(true);
        dispatch({
            type: SET_FILTERS,
            payload: PARAM_FILTERS.reduce((acc: any, key) => {
                if (key === 'userId' && user.role !== Roles.Instructor) {
                    return acc;
                }
                acc[key] = params[key] || null;
                return acc;
            }, {})
        });
    }, [params]);

    return <DashboardFiltersContext.Provider value={{ state, dispatch }}>
        {(paramsInitialized && queryInitialized) && children}
    </DashboardFiltersContext.Provider>
}

function useDashboardFilters() {
    const context = useContext(DashboardFiltersContext);
    if (context === undefined) {
        throw new Error('useDashboardFilters must be used within a DashboardFiltersProvider');
    }
    return context;
}

export { DashboardFiltersProvider, useDashboardFilters };

