import { IconProps } from "../../types/icon";

const GogglesIcon = ({ width = 16, height = 12, fill = "#F2F2F2" }: IconProps) =>
        <svg 
        width={width}
        height={height}
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path 
             fillRule="evenodd"
             clipRule="evenodd"
             d="M0 3.39609C0 2.07061 1.07452 0.996094 2.4 0.996094H13.6C14.9255 0.996094 16 2.07061 16 3.39609V8.59609C16 9.92158 14.9255 10.9961 13.6 10.9961H13.2677C12.4745 10.9961 11.7382 10.5985 11.2121 10.0048C10.4379 9.13106 9.20102 7.99609 8 7.99609C6.79898 7.99609 5.56212 9.13106 4.78788 10.0048C4.26181 10.5985 3.52549 10.9961 2.73227 10.9961H2.4C1.07452 10.9961 0 9.92158 0 8.59609V3.39609ZM14.0001 4.9961C14.0001 6.10067 13.1047 6.9961 12.0001 6.9961C10.8955 6.9961 10.0001 6.10067 10.0001 4.9961C10.0001 3.89153 10.8955 2.9961 12.0001 2.9961C13.1047 2.9961 14.0001 3.89153 14.0001 4.9961ZM4.00008 6.9961C5.10465 6.9961 6.00008 6.10067 6.00008 4.9961C6.00008 3.89153 5.10465 2.9961 4.00008 2.9961C2.89551 2.9961 2.00008 3.89153 2.00008 4.9961C2.00008 6.10067 2.89551 6.9961 4.00008 6.9961Z"
             fill={fill} />
        </svg>
        
export default GogglesIcon;