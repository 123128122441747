import styled from "styled-components";


const ContainerWidth = `16.5rem`;

export const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.gray[500]};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left: ${ContainerWidth};
    width: calc(100% - ${ContainerWidth});
    height: 5.5rem;
    padding: 0 1rem 0 1rem;
    box-sizing: border-box;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[100]};
    z-index: 599;

    @media only screen and (max-width: 1024px) {
        left: 0;
        width: 100%;
    }
`;