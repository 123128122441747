import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { PIE_COLORS } from "../../../pages/dashboard/charts/shared";
import PieLegend from "../../../pages/dashboard/charts/shared/pie-legend";
import { Container, LeftColumn, RightColumn } from "./pie.styles";

type Props = {
  data: { name: string, value: number, info?: any }[],
  colors: string[],
}

const VRPieChart = ({ data = [], colors = [] }: Props) => {

  const legendData = data.map(({ name, value, info }, i) => ({
    name,
    value,
    color: PIE_COLORS[i],
    info
  }));

  return <Container>
    <LeftColumn>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius="50%"
            outerRadius="80%"
            fill="#8884d8"
            stroke="0"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </LeftColumn>
    <RightColumn>
      <PieLegend data={legendData} />
    </RightColumn>
  </Container>

};

export default VRPieChart;
