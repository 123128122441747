import { IconProps } from "../../types/icon";

const PlayerPause = ({
  width = 20,
  height = 20,
  fill = "#ffffff",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 20 20`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 15.2857V5L8.87755 10.1429L3 15.2857Z" fill={fill} />
    <rect
      x="11.8572"
      y="4.85742"
      width="1.71429"
      height="10.2857"
      fill={fill}
    />
    <rect
      x="15.2856"
      y="4.85742"
      width="1.71429"
      height="10.2857"
      fill={fill}
    />
  </svg>
);

export default PlayerPause;
