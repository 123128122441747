import styled from "styled-components";

type LegendIconProps = {
  type: string;
  color: string;
};

const line = `
  height: 0.3125rem;
`;

const circle = `
  height: 1rem;
  border-radius: 50%;
`;

export const LegendStyle = {
  Wrapper: styled.div`
    min-width: 10rem;
    position: absolute;
    top: 5rem;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 0 1.25rem;
    gap: 1rem;

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    @media only screen and (max-width: 1550px) {
      & {
        position: static;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  `,

  Item: styled.p`
    display: flex;
    align-items: center;
    gap: 0.5rem;
  `,

  Icon: styled.div<LegendIconProps>`
    width: 1rem;
    background: ${({ color }) => color};
    ${({ type }) => (type === "line" ? line : circle)}
  `,
};
