import moment from "moment";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";

import ChartTooltip from "../chart-tooltip/chart-tooltip";

import { ChartContainer, FullContainer } from "../../dashboard/dashboard.styles";

import { getTimeSpent } from "../../../api/dashboard";
import AreaChart from "../../../components/graphs/area";
import { ClockIcon } from "../../../components/icons";
import { VRIntlProviderComponent } from "../../../components/providers/intl-provider";
import Frame from "../../../components/ui/frame";
import Loader from "../../../components/ui/loader";
import Message from "../../../components/ui/message";
import { useFeatureToggles } from "../../../context/feature-toggles";
import { Features } from "../../../enums/features";
import useMediaQuery from "../../../utils/hooks/use-media-query";
import { Container } from "../../dashboard/dashboard.styles";

const localeFn = (target: string) =>
  import(`./locale/${target.toLowerCase()}.json`);

type MinutesSpentProps = {
  userId?: string;
  from: String;
  to: String;
}

const MinutesSpentWrapper = ({
  data,
  interval = 7,
  loading,
}: {
  data: {
    name: string,
    value: string | number,
    info: string
  }[],
  interval?: number,
  loading: boolean
}) => {
  const intl = useIntl();
  const hasData = data.length > 0;
  return <ChartContainer empty={!hasData}>
    {loading && <Loader />}
    {(hasData && (
      <AreaChart
        tooltipContent={ChartTooltip}
        interval={interval}
        data={data}
        xAxisLabel={intl.formatMessage({
          id: "minutes-spent:date",
          defaultMessage: "Date",
        })}
      />
    )) || (
        <Message>
          <FormattedMessage id="no-data" />
        </Message>
      )}
  </ChartContainer>
};

const MinutesSpent = ({ userId, from, to }: MinutesSpentProps) => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const { isFeatureActive } = useFeatureToggles();

  const { data, isLoading } = useQuery(
    ["getTimeSpent", userId, from, to],
    () => getTimeSpent({ userId, from, to }),
    {
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
    }
  );

  const dataset =
    (data &&
      data.datasets &&
      data.datasets.map(
        ({
          day,
          totalMilliseconds,
        }: {
          day: string;
          totalMilliseconds: number;
        }) => ({
          name: isMobile
            ? moment(day).format("D/MM")
            : moment(day).format("Do MMM YY"),
          value: Math.round(totalMilliseconds / 1000 / 60),
          info: data.value,
        })
      )) ||
    [];

  const hasData = dataset.length > 0;

  if (!hasData && isFeatureActive(Features.HideEmptyCharts)) {
    return null;
  }

  return <VRIntlProviderComponent
    localeFn={localeFn}
    id="minutes-spent"
    fallback={null}
  >
    <ErrorBoundary fallback={null}>
      <Suspense fallback={null}>
        <FullContainer>
          <Frame title={<FormattedMessage id={"dashboard:minutes-spent"} />} icon={<ClockIcon />}>
            <Container>
              <MinutesSpentWrapper data={dataset} interval={data.xAxisInterval} loading={isLoading} />
            </Container>
          </Frame>
        </FullContainer>
      </Suspense>
    </ErrorBoundary>
  </VRIntlProviderComponent>
}


export default MinutesSpent;
