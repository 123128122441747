import moment from "moment";

export const formatDuration = (ms: number) => {
  const duration = moment.duration(ms);
  if (duration.asHours() > 1) {
    return (
      Math.floor(duration.asHours()) +
      moment.utc(duration.asMilliseconds()).format(":mm:ss")
    );
  } else {
    const hours = Math.floor(duration.asHours());
    const mins = Math.floor(duration.asMinutes()) - hours * 60;
    const sec = Math.floor(duration.asSeconds()) - hours * 60 * 60 - mins * 60;

    return [hours, mins, sec].map(pad).join(":");
  }
};

export function pad(number: number): string {
  return number.toString().padStart(2, "0");
}

export const MsToHours = (ms: number): number => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  return hours;
};
