import moment from "moment";
import { ReactNode } from "react";
import { useQuery } from "react-query";
import { Bar, BarChart, CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "styled-components";
import { getCustomCharts } from "../../../../api/sessions";
import { AircraftIcon, ClockIcon, EyeIcon, HeartIcon } from "../../../../components/icons";
import Loader from "../../../../components/ui/loader";
import Styled from "../../../../components/ui/styled";
import Text from "../../../../components/ui/text";
import { CustomChartDataset, CustomChartType } from "../../../../types/custom-charts";
import { pad } from "../../../../utils/time";
import useQueryParams from "../../../../utils/use-query-params";
import { CustomTooltip } from "../../../dashboard/charts/question-chart/question-chart.styles";
import { LoaderContainer, Section } from "../../session-detail.styles";
import { ChartContainer, TooltipContent } from "./custom-charts.styles";
import { displayLabel, displayUnit, ellipsisText, formatXTickText, formatYTickText, getChartTicks } from "./utils";

const LINES_COLORS = ["#8C2361", "#c691b0", "#FF647C"];

const XTick = (props: any) =>
  <text
    x={props.x}
    y={props.y}
    dy={props.dy}
    fill={props.fill}
    textAnchor={"middle"}>
    {formatXTickText(props.label, props.payload.value)}
  </text>

const HorizontalBarYTick = ({ width, ...props }: any) =>
  <text
    x={props.x}
    y={props.y + 5}
    dy={props.dy}
    dx={props.dx}
    fill={props.fill}
    textAnchor={"end"} >
    {ellipsisText(props.payload.value, width)}
  </text >

const ChartTooltip = ({ payload, label, data }: any) =>
  !payload || payload.length === 0 ? null :
    <TooltipContent.Container>
      <TooltipContent.Label>
        {displayLabel(data.label, label)}
      </TooltipContent.Label>
      {payload
        .filter((item: any) => item.dataKey !== 'BaseLine')
        .map((item: any) =>
          <TooltipContent.Value key={item.dataKey}>
            {Math.round(item.value)}{item.dataKey === data.value.code && displayUnit(data.value)}
          </TooltipContent.Value>
        )}
    </TooltipContent.Container>

const ChartTooltipHorizontal = ({ active, payload, value }: any) => {

  if (!active) return null;
  const data = payload[0];
  let v = data.value;

  if (value?.displayMeasurementType === 'Minutes') {
    const duration = moment.duration(v * 60, 'seconds');

    const time = [
      duration.get('minutes'),
      duration.get('seconds'),
    ]

    if (value?.currentMeasurementType === 'Milliseconds') {
      time.push(duration.get('milliseconds'))
    }

    v = time.map(t => pad(Math.round(t))).join(':')
  }


  return <CustomTooltip.Container>
    <Text variant="xsRegular">
      {data.name}
    </Text><br />
    <Text variant="smallRegular">{v}</Text>
  </CustomTooltip.Container>
}


const Chart = ({ data, from, to, offset }: { data: CustomChartType, from: Date, to: Date, offset: number }) => {
  const theme = useTheme();
  const { label, value } = data;
  const fromTime = from.getTime();
  const toTime = to.getTime();

  switch (data.chartType) {
    case "HorizontalBar":
      const fixedData = data.datasets.map((entry, index) => ({
        ...entry,
        [value.code]: (() => {
          if (value.displayMeasurementType === 'Minutes')
            return entry[value.code] / 1000 / 60;

          return entry[value.code]
        })()
      }));

      const leftGap: number = 110;

      return <ResponsiveContainer width="100%" height={fixedData.length * 90}>
        <BarChart
          data={fixedData}
          layout="vertical"
          barSize={8}
          margin={{ top: 0, right: 0, bottom: 0, left: leftGap }}>
          <CartesianGrid
            horizontal={false}
            strokeDasharray="10 10"
            stroke={theme.colors.gray[200]}
          />
          <XAxis
            type="number"
            axisLine={false}
            dataKey={value.code}
            dy={10}
            tickSize={0}
            tick={false}
            domain={[0, 'dataMax']}
          />
          <YAxis
            type="category"
            axisLine={false}
            dataKey={label.code}
            fill={theme.colors.gray[50]}
            textAnchor="end"
            interval={0}
            tickSize={0}
            dx={-10}
            tick={<HorizontalBarYTick width={leftGap + 10} fill={theme.colors.white} />}
          />
          <Tooltip
            isAnimationActive={false}
            separator={": "}
            cursor={false}
            content={<ChartTooltipHorizontal value={value} />}
          />
          <Bar dataKey={value.code} fill={theme.colors.primary.yellow[400]} />
        </BarChart>
      </ResponsiveContainer>


    case "Area":
      const fixedDataSet =
        data.datasets
          .filter((item: CustomChartDataset) => Object.keys(item).length > 1 && Object.keys(item).includes('CapturedDate'))
          .map((item: CustomChartDataset) => ({
            ...item,
            CapturedDate: item.CapturedDate && new Date(item.CapturedDate).getTime() || 0,
            ...(data.baseLine ? { BaseLine: Number(data.baseLine) } : {})
          }));

      const keys = Object.keys(fixedDataSet[0])
        .filter((key: string) => key !== "CapturedDate")
        .sort((key: string) => key === 'BaseLine' ? -1 : 1);

      return <ResponsiveContainer className="custom-chart">
        <LineChart
          width={1000}
          data={fixedDataSet}
          margin={{ top: 10, right: 0, bottom: 40, left: offset }}
        >
          <CartesianGrid stroke="transparent" fill={`rgba(56, 56, 56, 0.25)`} />
          <XAxis
            axisLine={false}
            dy={20}
            dataKey={label.code}
            tickLine={{ stroke: theme.colors.gray[200] }}
            tickSize={15}
            tick={<XTick label={label} fill={theme.colors.gray[50]} />}
            ticks={getChartTicks(fromTime, toTime)}
            type="number"
            domain={[fromTime, toTime]}>
            <Label value={label.description} position="bottom" offset={25} fill={theme.colors.gray[50]} />
          </XAxis>
          <YAxis
            className="y-axis"
            axisLine={false}
            textAnchor="end"
            mirror={true}
            dx={-45}
            type="number"
            tickLine={{ stroke: theme.colors.gray[200] }}
            tickSize={15}
            tick={{ fill: theme.colors.gray[50] }}
            tickFormatter={formatYTickText}
            domain={[0, value.maxDisplayValue || 'dataMax']}>
            <Label
              textBreakAll={false}
              width={offset * 0.5}
              value={value.description}
              position="insideLeft"
              offset={-(offset * 0.5) - 30}
              fill={theme.colors.gray[50]}
            />
          </YAxis>
          <Tooltip content={<ChartTooltip startTime={fromTime} data={{ label, value }} />} />
          {keys.map((key: string, index: number) =>
            <Line
              key={key}
              type="monotone"
              stroke={LINES_COLORS[index]}
              dataKey={key}
              dot={false}
              connectNulls={true} />)}
        </LineChart>
      </ResponsiveContainer >
    default:
      return null;
  }
};

type ChartSectionType = {
  children: ReactNode;
  title: string;
  code: string;
};

const Icon = ({ code }: { code: string }) => {
  let icon: ReactNode = null;
  switch (code) {
    case "heart-rate":
      icon = <HeartIcon width={22} height={22} />;
      break;
    case "designated-altitude":
    case "variance-from-designed-altitude":
      icon = <AircraftIcon width={22} height={22} />;
      break;
    case "pupil-dilation":
      icon = <EyeIcon width={22} height={22} />;
      break;
    case "time-spent-viewing-each-vehicle":
      icon = <ClockIcon width={18} height={18} />
      break;
    default:
      icon = null;
  }

  return icon &&
    <Styled marginRight={"1rem"} display="flex" alignItems="center">
      {icon}
    </Styled>
};

const ChartSection = ({ children, title, code }: ChartSectionType) => (
  <Section.Container>
    <Section.Header>
      <Icon code={code} />
      {title}
    </Section.Header>
    <Section.Body hasBackground={true}>{children}</Section.Body>
  </Section.Container>
);

type Props = {
  offset: number
}

const CustomCharts = ({ offset }: Props) => {
  const params: any = useQueryParams();
  let userId = params.get("userId");
  let from: string = params.get("from");
  let to: string = params.get("to");
  let sessionId: string = params.get("sessionId");
  let simulationInstanceId: string = params.get("simulationInstanceId");
  let scenarioInstanceId: string = params.get("scenarioInstanceId");

  const { data, isLoading } = useQuery(
    ["getCustomCharts", userId, from, to, sessionId, simulationInstanceId, scenarioInstanceId],
    () =>
      getCustomCharts({ from, to, sessionId, userId, simulationInstanceId, scenarioInstanceId }),
    {
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
    }
  );

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return <>
    {data && data.map((chartData: CustomChartType, index: number) =>
      chartData.datasets.length > 0 &&
      <ChartSection key={chartData.code} title={chartData.description} code={chartData.code}>
        <ChartContainer>
          <Chart from={new Date(from)} to={new Date(to)} data={chartData} key={index} offset={offset} />
        </ChartContainer>
      </ChartSection>)}
  </>
};

export default CustomCharts;
