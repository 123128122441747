import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    margin-top: 1rem;

    @media only screen and (max-width: 1023px) {
        flex-direction: column;
    }
`;

export const BadgeContainer = styled.div`
    display: flex;
    justify-content: center;
    
    @media only screen and (max-width: 1023px) {
        margin-bottom: 2rem;
    }
`;

export const Form = styled.div`
    padding-left: 2rem;
    flex-grow: 1;

    @media only screen and (max-width: 1023px) {
        padding-left: 0;
    }
`;

export const FormRow = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`;